<template>
    <!--Profile container-->
    <div class="profile">
        <!--Heading and QR Code modal-->
        <div class="hero-heading">
            <div class="hero-title">
                <router-link :to="{ path: '/app' }" v-if="user && user.role === 'client'">
                    <h2>Home</h2>
                </router-link>
                <div v-if="user && user.role === 'client'">
                    <h2>|</h2>
                </div>
                <h2>Profile</h2>
            </div>
            <div v-if="user && user.role == 'stylist'" data-toggle="modal" data-target=".bd-example-modal"
                class="qr-code-btn">
                <div class="qr-btn-ring">
                    <img v-if="generalOptions.app_id == 1" class="qr-code-ring" width="40"
                        src="../../assets/images/Property1RMS.png" alt="Ring for RMS" />
                    <img v-if="generalOptions.app_id == 2" class="qr-code-ring" width="40"
                        src="../../assets/images/RMB.png" alt="Ring for RMB" />
                    <img v-if="generalOptions.app_id == 3" class="qr-code-ring" width="40"
                        src="../../assets/images/RMP.png" alt="Ring for RMB" />
                    <img v-if="generalOptions.app_id == 4" class="qr-code-ring" width="40"
                        src="../../assets/images/Property1RMS.png" alt="Ring for RMS" />
                    <img v-if="generalOptions.app_id == 5" class="qr-code-ring" width="40"
                        src="../../assets/images/RMP.png" alt="Ring for RMB" />
                </div>
                <div>
                    <img ref="qrcode" class="profile-qr-code" width="30" :src="getProfileQRLink()" />
                </div>
            </div>
            <div class="modal fade bd-example-modal" tabindex="-1" role="dialog" id="qr-code-modal"
                aria-labelledby="mySmallModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content qr-code-wrapper">
                        <div class="qr-code-title">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <img width="15" src="../../assets/images/close.svg" alt="Close" />
                            </button>
                            <h4 class="modal-title">QR Code</h4>
                        </div>
                        <div class="qr-content-wrapp section-wrap">
                            <div class="qr-content">
                                <div class="qr-code-text">
                                    <p>
                                        This QR code is
                                        <strong>unique</strong> to your account.
                                    </p>
                                    <p>
                                        By sharing, downloading, or printing it on your business cards, others can
                                        easily connect with you, by simply scanning the code
                                    </p>
                                </div>
                                <div class="qr-image">
                                    <div class="qr-img">
                                        <div class="qr-img-inner">
                                            <img v-if="generalOptions.app_id == 1" class="qr-code-ring" width="170"
                                                src="../../assets/images/Property1RMS.png" alt="Ring for RMS" />
                                            <img v-if="generalOptions.app_id == 2" class="qr-code-ring" width="170"
                                                src="../../assets/images/RMB.png" alt="Ring for RMB" />
                                            <img v-if="generalOptions.app_id == 3" class="qr-code-ring" width="170"
                                                src="../../assets/images/RMP.png" alt="Ring for RMP" />
                                            <img v-if="generalOptions.app_id == 4" class="qr-code-ring" width="170"
                                                src="../../assets/images/Property1RMS.png" alt="Ring for RMS" />
                                            <img v-if="generalOptions.app_id == 5" class="qr-code-ring" width="170"
                                                src="../../assets/images/RMP.png" alt="Ring for RMP" />
                                        </div>
                                        <div class="qr-img-inner">
                                            <span v-on:click="copyToClipboard(getProfileQRLink())"
                                                href="javascript:void(0)" class="main-text-link" data-dismiss="modal"
                                                aria-label="Close"><img ref="qrcode" id="profile-qr-code-img"
                                                    class="profile-qr-code" width="130"
                                                    :src="getProfileQRLink()" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="qr-btns">
                                <div class="qr-download">
                                    <a :href="getProfileQRLink() + '&download=1'" class="main-text-link text-style">
                                        Download
                                    </a>
                                </div>
                                <div class="qr-inner">
                                    <a v-on:click="copyToClipboard(getProfileQRLink())" href="javascript:void(0)"
                                        class="main-text-link text-style" data-dismiss="modal" aria-label="Close">Copy
                                        QR Code</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-wrapper section-wrap">
            <!--Profile Hero-->
            <section id="profile-hero" class="section-wrap">
                <div class="main-container">
                    <div class="hero-container">
                        <!--Hero Content-->
                        <div class="hero-wrapp" v-if="user">
                            <div class="hero-content hero-img">
                                <div v-if="user && !user.avatar" @click="changeAvatar" class="hero-initials-wrapper">
                                    <div class="profile-initials">
                                        <div>
                                            {{ getInitial(user.first_name) }}
                                        </div>
                                        <div v-if="user.last_name">
                                            {{ getInitial(user.last_name) }}
                                        </div>
                                    </div>

                                    <div class="profile-image-wrap">
                                        <img v-if="generalOptions.app_id == 1" class="qr-code-ring"
                                            src="../../assets/images/Property1RMS.png" alt="Ring for RMS" />
                                        <img v-if="generalOptions.app_id == 2" class="qr-code-ring"
                                            src="../../assets/images/RMB.png" alt="Ring for RMS" />
                                        <img v-if="generalOptions.app_id == 3" class="qr-code-ring"
                                            src="../../assets/images/RMP.png" alt="Ring for RMS" />
                                        <img v-if="generalOptions.app_id == 4" class="qr-code-ring"
                                            src="../../assets/images/Property1RMS.png" alt="Ring for RMS" />
                                        <img v-if="generalOptions.app_id == 5" class="qr-code-ring" width="130"
                                            src="../../assets/images/RMP.png" alt="Ring for RMS" />
                                    </div>
                                </div>
                                <div v-if="user && user.avatar" class="user-avatar" @click="changeAvatar">
                                    <img :src="user.avatar" />
                                </div>
                                <div class="profile-photo-text">
                                    <p v-if="!user.avatar" @click="changeAvatar" class="main-text-link"
                                        href="javascript:void(0)">
                                        Add profile photo
                                    </p>
                                    <p v-if="user.avatar" @click="changeAvatar" class="main-text-link"
                                        href="javascript:void(0)">
                                        Change profile photo
                                    </p>
                                </div>
                                <div v-if="changeProfileImage">
                                    <div class="image-upload-overlay"></div>
                                    <div class="image-upload-overlay-content">
                                        <div class="change-photo-heading">
                                            <h5 v-if="!user.avatar">Add Profile Photo</h5>
                                            <h5 v-if="user.avatar">Change Profile Photo</h5>
                                        </div>
                                        <input class="profile-image-input-file d-none" type="file"
                                            ref="profileImageInputFile" name="img[]" accept="image/*"
                                            @change="profileImageChange" />
                                        <div class="modal-upload">
                                            <input type="text"
                                                class="selectInput selectInputExtraSmall selectInputSmall" disabled
                                                placeholder="Upload File" v-model="profileImageUpload.fileName" />
                                            <div class="input-group-append">
                                                <button v-on:click="openFileBrowserProfile" type="button"
                                                    class="browse primary-btn extra-small mobile">
                                                    Browse...
                                                </button>
                                            </div>
                                        </div>
                                        <div class="profile-image-upload-preview d-flex align-items-center"
                                            v-if="profileImageUpload.preview">
                                            <div>
                                                <vue-cropper ref="cropper" :aspect-ratio="1 / 1"
                                                    :src="profileImageUpload.preview" preview=".preview" />
                                            </div>
                                            <section class="preview-area">
                                                <div class="preview cropped-image">
                                                    <div class="crop-placeholder" />
                                                </div>
                                            </section>
                                        </div>
                                        <div v-if="profileImageUpload.preview" class="modal-btns-actions">
                                            <a href="javascript:void(0)" role="button" title="Zoom In"
                                                @click.prevent="zoom(0.2)">
                                                <i class="fa fa-search-plus" aria-hidden="true"></i>
                                            </a>
                                            <a href="javascript:void(0)" role="button" title="Zoom Out"
                                                @click.prevent="zoom(-0.2)">
                                                <i class="fa fa-search-minus" aria-hidden="true"></i>
                                            </a>

                                            <a href="javascript:void(0)" role="button" title="Rotate Left"
                                                @click.prevent="rotate(-90)">
                                                <i class="fa fa-undo" aria-hidden="true"></i>
                                            </a>
                                            <a href="javascript:void(0)" role="button" title="Rotate Right"
                                                @click.prevent="rotate(90)">
                                                <i class="fa fa-repeat" aria-hidden="true"></i>
                                            </a>
                                            <a ref="flipX" href="javascript:void(0)" role="button" title="Flip X"
                                                @click.prevent="flipX">
                                                <i class="fa fa-arrows-h" aria-hidden="true"></i>
                                            </a>
                                            <a ref="flipY" href="javascript:void(0)" role="button" title="Flip Y"
                                                @click.prevent="flipY">
                                                <i class="fa fa-arrows-v" aria-hidden="true"></i>
                                            </a>
                                            <a href="javascript:void(0)" role="button" title="Refresh"
                                                @click.prevent="reset">
                                                <i class="fa fa-refresh" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                        <div class="modal-btns">
                                            <button v-if="profileImageUpload.preview"
                                                v-on:click="saveProfileImageUpload" type="button"
                                                class="primary-btn extra-small mobile"
                                                :class="!enableProfilePicSave ? 'action-disabled' : ''">
                                                Save
                                            </button>
                                            <button v-on:click="cancelProfileImageUpload" type="button"
                                                class="secondary-btn small mobile">
                                                Cancel
                                            </button>
                                        </div>
                                        <div class="delete-profile-image-wrap"
                                            v-if="!profileImageUpload.preview && user.avatar">
                                            <span @click="removeProfileImage()" class="main-text-link">
                                                Delete the current profile picture
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="hero-content" v-if="user && user.role == 'stylist'">
                                <div class="hero-inner-text" v-if="user && user.role == 'stylist'">
                                    <p>Profile Views</p>
                                    <p>{{ profile.profile_views }}</p>
                                </div>
                            </div>
                            <div class="hero-content" v-if="user && user.role == 'stylist'">
                                <div class="hero-inner-text" v-if="user && user.role == 'stylist'">
                                    <p>Profile Likes</p>
                                    <p>{{ profile.profile_likes }}</p>
                                </div>
                            </div>
                            <div class="hero-content" v-if="user && user.role == 'stylist'">
                                <div class="hero-inner-text" v-if="
                                        user && user.role == 'stylist' && portfolioImages && portfolioImages.length > 0
                                    ">
                                    <p>Pictures</p>
                                    <p>
                                        {{ portfolioImages ? portfolioImages.length : 0 }}
                                    </p>
                                </div>
                            </div>
                            <div class="hero-content" v-if="user && user.role == 'stylist'">
                                <div class="hero-inner-text" v-if="
                                        user && user.role == 'stylist' && portfolioImages && portfolioImages.length > 0
                                    ">
                                    <p>Pictures Likes</p>
                                    <p>{{ profile.picture_likes }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--Portfolio Link-->
            <section v-if="user && user.role == 'stylist'">
                <div class="portfolio-salon-wrap">
                    <div class="portfolio-link-container section-wrap">
                        <div v-if="user && user.role == 'stylist'" class="portfolio-link-copy-link">
                            <h5>Portfolio Link</h5>
                            <h6 @click="copyToClipboard(profile.portfolio_url)">
                                <img width="15" src="../../assets/images/link.png" alt="link icon" /> copy link
                            </h6>
                        </div>
                        <div v-if="user && user.role == 'stylist'">
                            <div v-if="!edit['alias']" class="portfolio-link-wrapp">
                                <div>
                                    <a v-if="
                                            profile.profile_template == 0 ||
                                            profile.profile_template == 1 ||
                                            profile.profile_template == 2 ||
                                            profile.profile_template == 3
                                        " class="main-text-link text-style" target="_blank"
                                        :href="profile.portfolio_url">
                                        {{ profile.portfolio_url }}</a>
                                    <a v-if="
                                            profile.profile_template == 10 ||
                                            profile.profile_template == 12 ||
                                            profile.profile_template == 13
                                        " class="main-text-link text-style" target="_blank"
                                        :href="profile.portfolio_url">
                                        {{ modernProfileLink(profile.portfolio_url) }}</a>
                                </div>
                                <div class="btn-edit-wrap">
                                    <div v-on:click="editField('alias')" class="primary-btn extra-small mobile"
                                        aria-hidden="true">
                                        Edit
                                    </div>
                                </div>
                            </div>
                            <div v-if="edit['alias']" class="input-wrapper">
                                <div class="main-text-link text-style portfolio-link">
                                    {{ profile.portfolio_url.replace(profile.alias, "") }}
                                </div>
                                <div class="input-btns">
                                    <div v-if="edit['alias']" class="input-container">
                                        <input v-model.trim="editValues.alias" type="text" name="alias" class="
                                                text-field text-field-small text-field-extra-small
                                                mobile
                                                main-text-link
                                                text-style
                                                input-link
                                            " />
                                    </div>
                                    <div class="btns-wrap">
                                        <div v-if="edit['alias']" class="btn-wrap">
                                            <div v-on:click="submitLinkAndSalonName()"
                                                class="primary-btn extra-small mobile">
                                                Save
                                            </div>
                                        </div>
                                        <div v-if="edit['alias']" class="btn-wrap">
                                            <div v-on:click="cancelField('alias')" class="secondary-btn small mobile">
                                                Cancel
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--Profile Template-->
            <section v-if="user && user.role == 'stylist'">
                <div class="portfolio-salon-wrap">
                    <div class="portfolio-link-container template-profile-wrap section-wrap">
                        <div>
                            <h5>Pick your favorite portfolio template</h5>
                        </div>
                        <fieldset>
                            <div class="templates-option-wrap" v-if="profile && profile.portfolio_url">
                                <div class="classic-templates">
                                    <!--Classic-->
                                    <div class="template-option text-style">
                                        <input type="radio" id="classic" name="template" :value="
                                                editValues.profile_template == 0 || editValues.profile_template == 1
                                                    ? 0
                                                    : 0
                                            " :checked="
                                                editValues.profile_template == 0 || editValues.profile_template == 1
                                                    ? 0
                                                    : 0
                                            " v-model="editValues.profile_template" @change="savePortfolioTemplate" />

                                        <label for="classic">Classic</label>
                                        <span>|</span>
                                        <a :href="profile.portfolio_url + '?profile_template=1'" target="_blank"
                                            class="main-text-link">Click to preview</a>
                                    </div>
                                    <!--Classic Variation 2-->
                                    <div class="template-option text-style">
                                        <input type="radio" id="classic-two" name="template" value="2"
                                            :checked="editValues.profile_template == 2"
                                            v-model="editValues.profile_template" @change="savePortfolioTemplate" />
                                        <label for="classic-two">Classic Variation 2</label>
                                        <span>|</span>
                                        <a :href="profile.portfolio_url + '?profile_template=2'" target="_blank"
                                            class="main-text-link">Click to preview</a>
                                    </div>
                                    <!--Classic Variation 3-->
                                    <div class="template-option text-style">
                                        <input type="radio" id="classic-three" name="template" value="3"
                                            :checked="editValues.profile_template == 3"
                                            v-model="editValues.profile_template" @change="savePortfolioTemplate" />
                                        <label for="classic-three">Classic Variation 3</label>
                                        <span>|</span>
                                        <a :href="profile.portfolio_url + '?profile_template=3'" target="_blank"
                                            class="main-text-link">Click to preview</a>
                                    </div>
                                </div>
                                <div class="modern-templates">
                                    <!--Modern-->
                                    <div class="template-option text-style">
                                        <input type="radio" id="template-modern" name="template" value="10"
                                            :checked="editValues.profile_template == 10"
                                            v-model="editValues.profile_template" @change="savePortfolioTemplate" />
                                        <label for="template-modern">Modern</label>
                                        <span>|</span>
                                        <a :href="modernPortfolioLink(profile.portfolio_url, 10)" target="_blank"
                                            class="main-text-link">Click to preview</a>
                                    </div>
                                    <!--Modern Variation 2-->
                                    <div class="template-option text-style">
                                        <input type="radio" id="template-modern-two" name="template" value="12"
                                            :checked="editValues.profile_template == 12"
                                            v-model="editValues.profile_template" @change="savePortfolioTemplate" />
                                        <label for="template-modern-two">Modern Variation 2</label>
                                        <span>|</span>
                                        <a :href="modernPortfolioLink(profile.portfolio_url, 12)" target="_blank"
                                            class="main-text-link">Click to preview</a>
                                    </div>
                                    <!--Modern Variation 3-->
                                    <div class="template-option text-style">
                                        <input type="radio" id="template-modern-two" name="template" value="13"
                                            :checked="editValues.profile_template == 13"
                                            v-model="editValues.profile_template" @change="savePortfolioTemplate" />
                                        <label for="template-modern-two">Modern Variation 3</label>
                                        <span>|</span>
                                        <a :href="modernPortfolioLink(profile.portfolio_url, 13)" target="_blank"
                                            class="main-text-link">Click to preview</a>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <!-- <div class="save-template">
                            <div @click="savePortfolioTemplate" class="primary-btn extra-small mobile btn-edit-wrap">
                                Save
                            </div>
                        </div> -->
                    </div>
                </div>
            </section>
            <!--Salon Name-->
            <section v-if="user && user.role == 'stylist'">
                <div class="portfolio-salon-wrap">
                    <div class="portfolio-link-container section-wrap">
                        <div v-if="user && user.role == 'stylist'">
                            <!-- <h5>Shop Name</h5> -->
                            <h5>
                                <span v-if="generalOptions.app_id == 1">Salon Name</span>
                                <span v-if="generalOptions.app_id == 2">Barbershop Name</span>
                            </h5>
                        </div>
                        <div v-if="user && user.role == 'stylist'">
                            <div v-if="!edit['business_name']" class="portfolio-link-wrapps">
                                <div v-if="profile.business_name === ''" class="text-style">
                                    <span>Please enter your salon name</span>
                                </div>
                                <div v-else class="text-style">
                                    {{ profile.business_name }}
                                </div>
                                <div v-if="!edit['business_name']" class="btn-edit-wrap">
                                    <div v-on:click="editField('business_name')" class="primary-btn extra-small mobile"
                                        aria-hidden="true">
                                        Edit
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="edit['business_name']" class="input-wrapper">
                            <div class="input-btns">
                                <div v-if="edit['business_name']" class="input-container">
                                    <input v-model.trim="editValues.business_name" type="text" name="business_name"
                                        class="text-field text-field-small text-field-extra-small mobile text-style"
                                        placeholder="Please enter your salon name" />
                                </div>
                                <div class="btns-wrap">
                                    <div v-if="edit['business_name']" class="btn-wrap">
                                        <div v-on:click="submitLinkAndSalonName()"
                                            class="primary-btn extra-small mobile">
                                            Save
                                        </div>
                                    </div>
                                    <div v-if="edit['business_name']" class="btn-wrap">
                                        <div v-on:click="cancelField('business_name')"
                                            class="secondary-btn small mobile">
                                            Cancel
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--Timezone Language Date Time-->
            <section id="language">
                <!--Select Inputs Container-->
                <div v-if="user && user.role == 'stylist'" class="section-wrap">
                    <div class="profile-select-container">
                        <!-- Timezone-->
                        <select-component title="Timezone" :user="user" :inputValue="notification_settings.timezone"
                            :list="timezonesList" item="timezone" label="selectTimezone"
                            @save="saveSetting"></select-component>
                        <!--Language-->
                        <select-component title="Language" :user="user" item="language" label="Language"
                            :inputValue="notification_settings.language" :list="languageList"
                            @save="saveSetting"></select-component>
                        <!-- Date Time Format-->
                        <select-component title="Date Time Format" :user="user" item="datetime_format"
                            label="dateTimeFormat" :inputValue="notification_settings.datetime_format"
                            :list="datetime_formats" @save="saveSetting"></select-component>
                    </div>
                </div>
            </section>
            <!--Registration-->
            <section id="registration">
                <div class="first-form-container section-wrap">
                    <div class="form-heading">
                        <h4>Registration</h4>
                    </div>
                    <div class="form-container">
                        <form @submit.prevent="submitRegistration" class="form-wrapp section-wrap no-shadow">
                            <div class="wrapper">
                                <div class="wrapp">
                                    <div class="form-inputs">
                                        <label for="reg-name">Name</label>
                                        <div class="input-wrapper">
                                            <input v-if="user" id="reg-name"
                                                class="text-field text-field-small text-field-extra-small mobile"
                                                type="text" v-model.trim="editUserValues.name"
                                                placeholder="Please enter name" />
                                        </div>
                                    </div>
                                    <div class="form-inputs">
                                        <label for="reg-zip">Zip Code</label>
                                        <div class="input-wrapper">
                                            <input v-if="user" id="reg-zip"
                                                class="text-field text-field-small text-field-extra-small mobile"
                                                type="text" v-model.trim="editUserValues.zip" name="zip"
                                                placeholder="Please enter zip code" />
                                        </div>
                                    </div>
                                </div>
                                <div class="wrapp">
                                    <div class="form-inputs">
                                        <label for="reg-email">Email</label>
                                        <div class="input-wrapper">
                                            <input v-if="user" id="reg-email"
                                                class="text-field text-field-small text-field-extra-small mobile"
                                                type="email" v-model.trim="editUserValues.email" name="email"
                                                placeholder="Please enter email" />
                                        </div>
                                    </div>

                                    <div class="form-inputs" v-if="user && user.role == 'stylist'">
                                        <p>
                                            {{ $ml.get("accounts.profession") }}
                                        </p>

                                        <div class="reg-profession">
                                            <div class="input-wrapper">
                                                <select class="selectInput selectInputExtraSmall selectInputSmall"
                                                    v-model="selectedProfessionId">
                                                    <option v-for="(profession, index) in professions"
                                                        v-bind:key="index" :value="profession.id">
                                                        {{ profession.profession }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sub-btn">
                                <div class="sub" v-if="professions.length >= 1" href="javascript:void(0)">
                                    <button class="form-btn primary-btn extra-small mobile" type="submit">Save</button>
                                </div>
                            </div>
                        </form>
                        <div v-if="user" class="section-wrap no-shadow">
                            <div class="phone-num-wrapp text-style">
                                <div class="phone-inner-num">
                                    <div>
                                        <p>Phone Number</p>
                                    </div>
                                    <div class="input-wrapper">{{ user.phone_prefix }} {{ user.phone }}</div>
                                </div>
                                <div class="phone-container">
                                    <div>
                                        <p>Change Phone Number</p>
                                    </div>
                                    <div class="phone-wrapp" v-if="phoneState === 0 || phoneState === 1">
                                        <div class="phone-select-wrap">
                                            <div class="phone-select input-wrapper">
                                                <select class="selectInput selectInputExtraSmall selectInputSmall"
                                                    v-model="editValues.country_id"
                                                    v-on:change="changeSelectedCountry(editValues.country_id)">
                                                    <option v-for="(country, index) in countries" v-bind:key="index"
                                                        :value="country.id">
                                                        {{ country.name }}({{ country.dial_code }})
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="input-wrapper hide-arrows">
                                                <input v-model.trim="phone" type="number" name="phone"
                                                    class="text-field text-field-small text-field-extra-small mobile"
                                                    placeholder="New Cell Number" />
                                            </div>
                                        </div>
                                        <div class="input-wrapper phone-btns">
                                            <div :class="!phone || phoneProgress ? 'action-disabled' : ''"
                                                v-on:click="changePhone(true, 1)" class="primary-btn extra-small mobile"
                                                href="javascript:void(0)">
                                                Save
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="phoneState === 2">
                                        <div class="phone-verify-container input-wrapper">
                                            <div class="">
                                                <input v-model.trim="verificationCode" type="text"
                                                    name="verificationCode"
                                                    class="text-field text-field-small text-field-extra-small mobile"
                                                    :placeholder="$ml.get('accounts.verificationCode')" />
                                            </div>
                                            <div class="btn-verify">
                                                <div :class="!verificationCode || phoneProgress ? 'action-disabled' : ''"
                                                    v-on:click="changePhone(true, 2)"
                                                    class="primary-btn extra-small mobile" href="javascript:void(0)">
                                                    Verify
                                                </div>
                                                <div v-on:click="changePhone(false, 1)"
                                                    class="secondary-btn small mobile" href="javascript:void(0)">
                                                    Cancel
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--Salon Address-->
            <section id="salon-address" v-if="user && user.role == 'stylist'">
                <div class="first-form-container section-wrap" ref="businessPhoneTarget">
                    <div class="form-heading">
                        <h4>
                            <span v-if="generalOptions.app_id === 1 || generalOptions.app_id === 5">Salon Address</span>
                            <span v-if="generalOptions.app_id === 2">Barbershop Address</span>
                        </h4>
                        <p class="salon-paragraph">This address is where clients will receive services.</p>
                    </div>
                    <div class="form-container">
                        <form @submit.prevent="submitSalonAddress" class="form-wrapp">
                            <div class="wrapper">
                                <div class="wrapp">
                                    <div class="form-inputs">
                                        <label for="salon-addr">Address</label>
                                        <div class="input-wrapper">
                                            <input id="salon-addr" v-model.trim="editValues.address"
                                                class="text-field text-field-small text-field-extra-small mobile"
                                                type="text" name="address" placeholder="Please enter address" />
                                        </div>
                                    </div>
                                    <div class="form-inputs">
                                        <label for="city">City</label>
                                        <div class="input-wrapper">
                                            <input id="city" v-model.trim="editValues.city"
                                                class="text-field text-field-small text-field-extra-small mobile"
                                                type="text" name="city" placeholder="Please enter city" />
                                        </div>
                                    </div>
                                    <div class="form-inputs">
                                        <label for="state">State</label>
                                        <div class="input-wrapper">
                                            <input id="state" v-model.trim="editValues.state"
                                                class="text-field text-field-small text-field-extra-small mobile"
                                                type="text" name="state" placeholder="Please enter state" />
                                        </div>
                                    </div>
                                    <div class="form-inputs">
                                        <label for="zip">Zip Code</label>
                                        <div class="input-wrapper">
                                            <input id="zip" v-model.trim="editValues.zip"
                                                class="text-field text-field-small text-field-extra-small mobile"
                                                type="text" name="zip" placeholder="Please enter zip code" />
                                        </div>
                                    </div>
                                </div>
                                <div class="wrapp">
                                    <div class="form-inputs">
                                        <p>Country</p>
                                        <div class="phone-select input-wrapper">
                                            <select class="selectInput selectInputExtraSmall selectInputSmall"
                                                v-model.trim="editValues.country_id"
                                                v-on:change="changeSelectedCountry(editValues.country_id)">
                                                <option v-for="(country, index) in countries" v-bind:key="index"
                                                    :value="country.id">
                                                    {{ country.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-inputs phone-error-container" >
                                        <label for="phone">Booking Phone Number</label>
                                        <div class="input-wrapper ">
                                            <span class="phone-error-message">
                                                {{ phoneError }}
                                            </span>
                                            <input id="phone" v-model.trim="editValues.business_phone"
                                                @keyup="validatePhoneNumber(editValues.business_phone)"
                                                :class="{ 'new-client-error': phoneError }"
                                                class="text-field text-field-small text-field-extra-small mobile"
                                                type="text" name="business_phone"
                                                placeholder="Please enter phone number" />
                                        </div>
                                    </div>
                                    <div class="form-inputs">
                                        <label for="business_email">Business Email</label>
                                        <div class="input-wrapper">
                                            <input id="business_email" v-model.trim="editValues.business_email"
                                                class="text-field text-field-small text-field-extra-small mobile"
                                                type="text" name="business_email" placeholder="Please enter email" />
                                        </div>
                                    </div>
                                    <div class="form-inputs" v-if="user && user.role == 'stylist'">
                                        <div>
                                            <p>
                                                {{ $ml.get("services.currency") }}
                                            </p>
                                        </div>
                                        <div class="">
                                            <div class="input-wrapper">
                                                <select v-model.trim="editValues.currency"
                                                    class="selectInput selectInputExtraSmall selectInputSmall"
                                                    v-on:click="changeCurrency(currency)">
                                                    <option v-for="(currency, index) in currencies"
                                                        :key="`currency-${index}`">
                                                        {{ currency }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 class="amentities">Amenities</h4>
                                <div class="form-inputs">
                                    <div class="input-wrapper">
                                        <div class="amenities-wrapp">
                                            <label for="plus-tv">TV</label>
                                            <select v-model="editValues.plus_tv"
                                                class="selectInput selectInputExtraSmall" id="plus-tv">
                                                <option :value="1">{{ $ml.get("general.yes") }}</option>
                                                <option :value="0">{{ $ml.get("general.no") }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="input-wrapper">
                                        <div class="amenities-wrapp">
                                            <label for="plus-free-parking">Free Parking</label>
                                            <select v-model="editValues.plus_free_parking"
                                                class="selectInput selectInputExtraSmall" id="plus-free-parking">
                                                <option :value="1">{{ $ml.get("general.yes") }}</option>
                                                <option :value="0">{{ $ml.get("general.no") }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="input-wrapper">
                                        <div class="amenities-wrapp">
                                            <label for="plus-cc-accepted">Credit card accepted</label>
                                            <select v-model="editValues.plus_cc_accepted"
                                                class="selectInput selectInputExtraSmall" id="plus-cc-accepted">
                                                <option :value="1">{{ $ml.get("general.yes") }}</option>
                                                <option :value="0">{{ $ml.get("general.no") }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-inputs">
                                    <div class="input-wrapper">
                                        <div class="amenities-wrapp">
                                            <label for="plus-wifi">Wi-Fi</label>
                                            <select v-model="editValues.plus_wifi"
                                                class="selectInput selectInputExtraSmall" id="plus-wifi">
                                                <option :value="1">{{ $ml.get("general.yes") }}</option>
                                                <option :value="0">{{ $ml.get("general.no") }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="input-wrapper">
                                        <div class="amenities-wrapp">
                                            <label for="plus-for-disabilities">For Disabilities</label>
                                            <select v-model="editValues.plus_for_disabilities"
                                                class="selectInput selectInputExtraSmall" id="plus-for-disabilities">
                                                <option :value="1">{{ $ml.get("general.yes") }}</option>
                                                <option :value="0">{{ $ml.get("general.no") }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-inputs">
                                    <p>Toggle address</p>
                                    <div class="input-wrapper">
                                        <div class="toggle-wrapp">
                                            <div class="">
                                                <label class="switch">
                                                    <input type="checkbox" id="check" v-model.trim="public_address" />
                                                    <label for="check" class="slider round"></label>
                                                </label>
                                            </div>
                                            <div class="toggle-text">
                                                <div class="text-style">
                                                    This address is visible on your
                                                    <a :href="profile.portfolio_url" class="main-text-link"
                                                        target="_blank">portfolio</a>
                                                    page.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sub-btn">
                                <div class="grid-element"></div>
                                <div class="sub" v-if="professions.length >= 1" href="javascript:void(0)">
                                    <button class="form-btn primary-btn extra-small mobile" type="submit">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <!--Password-->
            <section id="password">
                <div v-if="user" class="section-wrap pass">
                    <h4>Password</h4>
                    <!--Enter newPass-->
                    <div :class="passwordState === 0" class="inputs-pass-wrap">
                        <div class="inputs-pass">
                            <div class="input-container input-wrapper">
                                <input v-model.trim="password" type="password" name="new_password"
                                    class="text-field text-field-small text-field-extra-small mobile text-style"
                                    placeholder="New password*" />
                            </div>
                            <div class="input-container input-wrapper">
                                <input v-model.trim="passCheck" type="password" name="new_password"
                                    class="text-field text-field-small text-field-extra-small mobile text-style"
                                    placeholder="Confirm password*" />
                            </div>
                        </div>

                        <div class="pass-btns input-wrapper">
                            <div class="save-btn" :class="
                                    password === '' || password === null || passCheck === '' || passCheck === null
                                        ? 'action-disabled'
                                        : ''
                                ">
                                <div v-on:click="checkPasswordReset" class="primary-btn extra-small mobile"
                                    href="javascript:void(0)">
                                    Save
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pass-alert-text" v-if="password !== passCheck">
                        *New password and Confirm password should match!
                    </div>
                </div>
            </section>
            <section id="about-us">
                <!--Bio(About Us)-->
                <div v-if="user && user.role == 'stylist'" class="bio-container section-wrap">
                    <div class="bio-heading">
                        <div class="">
                            <h4>{{ $ml.get("portfolio.bio") }}</h4>
                        </div>
                        <div :class="editField('bio')" class="text-style">
                            <div v-on:click="submitBio()" class="primary-btn extra-small mobile pointer">Save</div>
                        </div>
                    </div>
                    <div v-if="!edit['bio']" v-html="profile.bio"></div>
                    <div v-if="edit['bio']" class="bio-editor">
                        <vue-editor v-model.trim="editValues.bio" :editorToolbar="customToolbar"
                            placeholder="In this section, you can provide information about yourself, policies, and special services that will be visible on your portfolio's public link page."></vue-editor>
                    </div>
                </div>
            </section>
            <!--Portfolio Pictures Container-->
            <section id="portfolio-pictures">
                <div v-if="user && user.role == 'stylist'" class="portfolio-container section-wrap">
                    <!--Title-->
                    <div class="title-link-wrap">
                        <h4>{{ $ml.get("portfolio.pictures") }}</h4>
                        <router-link :to="{ path: '/app/portfolio-pictures' }">
                            <div class="profile-photo-text" @click="scrollToTop()">
                                <p class="main-text-link">Manage Pictures</p>
                            </div>
                        </router-link>
                    </div>
                    <!--Portfolio Images Container-->
                    <div class="container-wrapper" v-if="portfolioImages.length > 0">
                        <div class="img-inner-container" v-for="(image, index) in portfolioImages" :key="index"
                            v-bind:class="{ 'no-padding-bottom': !image.image }">
                            <div class="img-inner">
                                <img v-if="image.image" @click.prevent="portfolioImgPreview(image)"
                                    class="portfolio-images pointer" :data-img-id="image.id" :src="image.image"
                                    data-toggle="modal" data-target="#image-modal" title="Open Image" />
                            </div>
                            <div class="image-wrap">
                                <div class="image-action-tags" title="Image Tag">
                                    <div class="image-inner-wrap" v-if="image.tag1">
                                        <div class="image-tags-wrap">{{ image.tag1 }}</div>
                                    </div>
                                    <div class="image-inner-wrap" v-if="image.tag2">
                                        <div class="image-tags-wrap">{{ image.tag2 }}</div>
                                    </div>
                                    <div class="image-inner-wrap" v-if="image.tag3">
                                        <div class="image-tags-wrap">{{ image.tag3 }}</div>
                                    </div>
                                </div>
                                <div class="image-action-likes" title="Likes">
                                    <div class="image-inner-wrap" v-if="image.image && image.likes > 0">
                                        <div class="heart-icon"><i class="fa fa-heart" aria-hidden="true"></i></div>
                                        <div class="image-likes">{{ formatLikes(image.likes) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="image-modal" tabindex="-1" role="dialog" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-body modal-position-wrapper">
                                    <button type="button" class="close position-close" data-dismiss="modal"
                                        aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <div class="modal-content-big-img">
                                        <img id="inner-modal-preview-image" alt="Preview Image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Modal-->
                    <div v-if="portfolioImageUpload.show">
                        <div class="image-upload-overlay"></div>
                        <div class="image-upload-overlay-content">
                            <div>
                                <h4 v-if="portfolioImageUpload.index">Change Picture</h4>
                                <h4 v-if="!portfolioImageUpload.index">Add Portfolio Picture</h4>
                            </div>
                            <input class="portfolio-image-input-file" type="file" ref="portfolioImageInputFile"
                                name="img[]" accept="image/*" v-on:change="portfolioImageChange($event)" />
                            <div class="modal-upload">
                                <input type="text" class="selectInput selectInputExtraSmall selectInputSmall" disabled
                                    placeholder="Upload File" v-model="portfolioImageUpload.fileName" />
                                <div class="input-group-append">
                                    <button v-on:click="openFileBrowser" type="button"
                                        class="browse primary-btn primary-btn extra-small mobile">
                                        Browse...
                                    </button>
                                </div>
                            </div>
                            <div class="portfolio-image-upload-preview" v-if="portfolioImageUpload.preview">
                                <div ref="portfolioImagePreviewContainer" class="preview-image-container">
                                    <img :src="portfolioImageUpload.preview" class="portfolio-images"
                                        ref="portfolioImagePreview" />
                                </div>
                            </div>
                            <div v-if="portfolioImageUpload.preview" class="image-preview-controls">
                                <div class="image-actions">
                                    <div v-on:click="rotateImage('left')" class="image-rotate rotate-left"
                                        title="Rotate Left">
                                        <img src="../../assets/images/rotate-left.svg" class="image-left" />
                                    </div>
                                </div>
                                <div class="image-actions">
                                    <div v-on:click="rotateImage('right')" class="image-rotate rotate-right"
                                        title="Rotate Right">
                                        <img src="../../assets/images/rotate-right.svg"
                                            class="fa fa-solid fa-rotate-right" />
                                    </div>
                                </div>
                            </div>
                            <div class="modal-btns">
                                <button v-on:click="savePortfolioImageUpload" type="button"
                                    class="primary-btn primary-btn extra-small mobile">
                                    {{ this.savingPortfolioImageUpload ? "Saving..." : "Save" }}
                                </button>
                                <button v-on:click="cancelPortfolioImageUpload" type="button"
                                    class="secondary-btn small mobile">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--Delete Account -->
            <section id="delete-account">
                <div class="deleted-account-container section-wrap">
                    <div class="delete-account-heading">
                        <h4>Delete account</h4>
                    </div>
                    <div class="delete-account-content">
                        <div class="delete-account-text">
                            <h5>Are you sure you want to delete your account?</h5>
                            <p>
                                After submitting this form, you have 14 days to contact us to restore it before it's
                                permanently deleted.
                            </p>
                        </div>
                        <div class="check-input-delete-account-btn-container">
                            <div class="delete-account-checkbox-input">
                                <input type="checkbox" id="agree" @click="checkDeleteAgreement" />
                                <label for="agree">I understand that deleting is permanent and can't be undone.</label>
                            </div>
                            <div class="delete-account-btns">
                                <div class="delete-account-btn-width">
                                    <button class="primary-btn extra-small mobile"
                                        :class="deleteAccountAgree ? 'action-disabled' : 'delete-btn'"
                                        @click="deleteAccountSwal">
                                        Delete account
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import $ from "jquery";
import "bootstrap";
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";
import store from "../../services/vuex.js";
import eventBus from "../../services/event_bus.js";
import messages from "../../services/messages.js";
import Vue from "vue";
import { VueEditor } from "vue2-editor";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import SelectComponent from "./profile_select.vue";
import ClientNotifications from "./client_notifications.vue";

export default {
    name: "Profile",
    props: ["sections"],
    data: function () {
        return {
            user: null,
            profile: {},
            passwordState: 0,
            phoneState: 0,
            phoneNotification: null,
            password: null,
            passCheck: null,
            phone: null,
            resetCode: null,
            resetCodeInitials: null,
            phoneProgress: false,
            verificationCode: null,
            portfolioImages: [],
            allImages: [],
            totalImages: 24,
            savingPortfolioImageUpload: false,
            portfolioImageUpload: {
                show: false,
                index: 0,
                preview: null,
                fileName: null,
                file: null,
                rotate: 0,
                width: 0,
                height: 0,
            },
            edit: {
                address: false,
                state: false,
                city: false,
                zip: false,
                bio: false,
                country_id: false,
                business_name: false,
                business_phone: false,
                business_email: false,
                currency: false,
                alias: false,
            },
            editValues: {
                address: null,
                state: null,
                city: null,
                zip: null,
                bio: null,
                country_id: null,
                business_name: null,
                business_phone: null,
                business_email: null,
                currency: null,
                alias: null,
                plus_cc_accepted: null,
                plus_for_disabilities: null,
                plus_free_parking: null,
                plus_tv: null,
                plus_wifi: null,
                profile_template: 0,
            },

            editUser: {
                name: false,
                first_name: false,
                last_name: false,
                email: false,
                zip: false,
            },
            editUserValues: {
                name: null,
                first_name: null,
                last_name: null,
                email: null,
                zip: null,
            },
            selectedCurrency: "USD",
            currency: "USD",
            currencies: ["USD", "Exy", "RUP"],
            countries: null,
            prefixes: null,
            selectedCountry: null,
            selectedCountryShow: null,
            professions: [],
            selectedProfessionId: 0,
            selectedProfession: { id: 0 },
            selectedProfessionShow: null,
            userProfession: null,
            customToolbar: [
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                    { list: "ordered" },
                    { list: "bullet" },
                ],
            ],
            changeProfileImage: false,
            profileImageUpload: {
                preview: null,
                fileName: null,
                file: null,
                croppedImage: null,
            },
            enableProfilePicSave: true,
            stylistProfile: {},
            timezonesList: {},
            languageList: {
                el: "Greek",
                en: "English",
                es: "Spanish",
                it: "Italian",
                pt: "Portuguese",
            },
            datetime_formats: {},
            notification_settings: {
                timezone: "",
                language: "",
                datetime_format: "",
            },
            public_address: 0,
            deleteAccountAgree: true,
            phoneError: "", // To store any validation errors
        };
    },
    mounted: function () {
        this.user = auth.user;

        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });

        this.getStylistProfile();
        this.populateEditUserValues();
    },
    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        translation: {
            get: function () {
                return store.state.translation;
            },
            set: function (value) {
                store.commit("setTranslation", value);
            },
        },
    },
    methods: {
        checkDeleteAgreement() {
            this.deleteAccountAgree = !this.deleteAccountAgree;
        },

        deleteAccountSwal() {
            document.querySelector("body").classList.add("rms-app");

            if (this.generalOptions.app_id === 2) {
                document.querySelector("body").classList.add("rmb-app");
            } else if (this.generalOptions.app_id === 5) {
                document.querySelector("body").classList.add("rml-app");
            }

            Vue.swal({
                title: this.$ml.get("portfolio.confirmQuestion"),
                text: "After submitting this form, you have 14 days to contact us to restore it before it's permanently deleted!",
                confirmButtonColor: "#00cdc1",
                cancelButtonColor: "#07b2c0",
                confirmButtonText: "Delete Account",
                cancelButtonText: "Nevermind",
                type: "error",
                customClass: {
                    confirmButton: "delete-account",
                    icon: "del-account-error",
                    title: "delete-account-color",
                },
                showCancelButton: true,
            }).then((result) => {
                if (result.value) {
                    this.deleteAccount();
                }
            });
        },

        deleteAccount() {
            let data = {
                active: 0,
            };

            if (this.user.role === "client") {
                data.client_id = this.user.id;
            } else {
                data.stylist_id = this.user.id;
            }

            this.$http.post(api.actions.save_user_profile_active, data).then(
                function (response) {
                    if (response.data.error == false) {
                        auth.logout();
                        this.$router.push("/app/login");
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },

        changeCurrency: function (index) {
            this.selectedCurrency = this.currencies[index];
        },

        onAdminDetailsLoad: function () {
            this.getProfile();
            this.getPortfolioImages();
            this.public_address = this.adminDetails.public_address;
        },
        getProfile: function () {
            this.$http.get(api.actions.get_profile_info).then(
                function (response) {
                    if (typeof response.data.user_profile !== "undefined") {
                        this.profile = response.data.user_profile;
                        this.profile.country_id = this.profile.country_id.toString();
                        this.adminDetails.profile.country_id = this.profile.country_id;

                        for (var key in this.editValues) {
                            this.editValues[key] = this.profile[key];
                        }

                        this.getCountries();
                        this.getProfessions();
                        this.getUserProfession();
                    }
                }.bind(this)
            );
        },
        getPortfolioImages: function () {
            this.$http.get(api.actions.get_portfolio_images).then(
                function (response) {
                    if (typeof response.data.images !== "undefined") {
                        this.portfolioImages = [];

                        response.data.images.forEach((image) => {
                            this.portfolioImages.push({
                                image: image.image,
                                pic_order: image.pic_order,
                                id: image.id,
                                likes: image.likes,
                                tag1: image.tag1,
                                tag2: image.tag2,
                                tag3: image.tag3,
                            });
                        });

                        this.portfolioImages = this.portfolioImages.reverse();
                    }
                }.bind(this)
            );
        },
        portfolioImgPreview(img) {
            const modalImg = document.querySelector(".modal-content-big-img img");
            modalImg.src = img.image;
            modalImg.setAttribute("data-image-id", img.id);
        },
        openFileBrowser: function () {
            this.$refs.portfolioImageInputFile.click();
        },
        openFileBrowserOverlay: function (index) {
            this.portfolioImageUpload.index = index;
            this.portfolioImageUpload.show = true;
        },
        portfolioImageChange: function (e) {
            let file = e.target.files[0];

            // check how big is the image
            if (file != "") {
                const fileSize = file.size;
                const fileMb = fileSize / 1024 ** 2;

                if (fileMb >= 4) {
                    messages.errorMessage(messages.ERROR_MESSAGES.IMAGE_TO_BIG);

                    return;
                }
            }

            this.portfolioImageUpload.file = file;
            this.portfolioImageUpload.fileName = file.name;

            let self = this;
            var reader = new FileReader();
            reader.onload = function (e) {
                // get loaded data and render thumbnail.
                self.portfolioImageUpload.preview = e.target.result;

                // get the image dimensions
                setTimeout(() => {
                    var element = $(self.$refs.portfolioImagePreview);
                    self.portfolioImageUpload.width = element.width();
                    self.portfolioImageUpload.height = element.width();
                    self.portfolioImageUpload.rotate = 0;
                }, 200);
            };
            // read the image file as a data URL.
            reader.readAsDataURL(file);
        },
        cancelPortfolioImageUpload: function () {
            this.portfolioImageUpload = {
                index: 0,
                show: false,
                fileName: null,
                preview: null,
                file: null,
            };
        },
        savePortfolioImageUpload: function () {
            //do not send request if no file is available
            if (!this.portfolioImageUpload.file || this.savingPortfolioImageUpload) {
                return;
            }

            this.savingPortfolioImageUpload = true;

            //make request to match reset code and change password
            let formData = new FormData();
            formData.append("image", this.portfolioImageUpload.file);
            formData.append("id", this.portfolioImageUpload.index);
            formData.append("rotate", this.portfolioImageUpload.rotate);
            this.$http.post(api.actions.save_portfolio_image, formData).then(
                function (response) {
                    this.savingPortfolioImageUpload = false;

                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.PORTFOLIO_IMAGE_CHANGED);
                        this.cancelPortfolioImageUpload();
                        this.getPortfolioImages();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        editField: function (field) {
            this.edit[field] = true;
        },
        cancelField: function (field) {
            //remove input field
            this.edit[field] = false;
            if (field === "country_id") {
                this.changeSelectedCountry(this.profile.country_id);
            }
        },

        submitLinkAndSalonName() {
            this.saveField("alias");
            this.saveField("business_name");
            messages.successMessage(messages.GLOBAL_MESSAGES.USER_DATA_SAVED_MESSAGE);
        },

        submitRegistration() {
            if (this.user.role === "client") {
                this.saveUserNameField("name");
                this.saveUserField("email");
                this.saveUserField("zip");
                this.saveField("country_id");
                messages.successMessage(messages.GLOBAL_MESSAGES.USER_DATA_SAVED_MESSAGE);
            }

            if (this.user.role === "stylist") {
                this.saveProfession();
                this.saveUserNameField("name");
                this.saveUserField("email");
                this.saveUserField("zip");
                this.saveField("country_id");
                messages.successMessage(messages.GLOBAL_MESSAGES.USER_DATA_SAVED_MESSAGE);
            }
        },

        submitSalonAddress() {
            if (!this.validatePhoneNumber(this.editValues.business_phone)) {
                this.scrollToElement();
                return;
            }

            this.saveFields();
            this.saveAddressSettings();
            messages.successMessage(messages.GLOBAL_MESSAGES.USER_DATA_SAVED_MESSAGE);
        },

        submitBio() {
            this.saveField("bio");
            messages.successMessage(messages.GLOBAL_MESSAGES.USER_DATA_SAVED_MESSAGE);
        },

        saveFields() {
            this.$http.post(api.actions.save_profile_info, this.editValues).then(
                function (response) {
                    if (response.data.error == false) {
                        this.getProfile();
                        this.getStylistProfile();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },

        saveField: function (field) {
            //if value is same as before then just cancel
            if (this.editValues[field].trim() === this.profile[field].trim()) {
                this.editValues[field] = this.profile[field];
                this.cancelField(field);
                return;
            }

            //make request to save the new profession
            let data = {
                [field]: this.editValues[field].trim(),
            };

            this.$http.post(api.actions.save_profile_info, data).then(
                function (response) {
                    if (response.data.error == false) {
                        this.profile[field] = this.editValues[field].trim();
                        //cancel the field, to hide input
                        this.cancelField(field);
                        this.getProfile();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        savePortfolioTemplate() {
            //if value is same as before then just cancel
            if (this.editValues.profile_template === this.adminDetails.profile.profile_template) {
                return;
            }

            //make request to save the new profession
            let data = {
                profile_template:
                    parseInt(this.editValues.profile_template) == 0 ? "" : this.editValues.profile_template,
            };

            this.$http.post(api.actions.save_profile_info, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.PORTFOLIO_TEMPLATE_CHANGED);
                        this.getProfile();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        onDeletePortfolioImage: function (image) {
            let customClass = "rms-app";
            document.querySelector("body").classList.add("rms-app");

            if (this.generalOptions.app_id === 2) {
                customClass = "rmb-app";
                document.querySelector("body").classList.add("rmb-app");
            } else if (this.generalOptions.app_id === 5) {
                customClass = "rml-app";
                document.querySelector("body").classList.add("rml-app");
            }

            Vue.swal({
                title: this.$ml.get("portfolio.confirmQuestion"),
                text: "You won't be able to revert this!",
                confirmButtonColor: "#00cdc1",
                cancelButtonColor: "#07b2c0",
                customClass: customClass,
                type: "warning",
                showCancelButton: true,
                confirmButtonText: this.$ml.get("portfolio.confirmYes"),
                cancelButtonText: this.$ml.get("portfolio.confirmNo"),
            }).then((result) => {
                if (result.value) {
                    this.deletePortfolioImage(image);
                }
            });
        },
        deletePortfolioImage: function (image) {
            let data = {
                id: image.id,
            };
            this.$http.post(api.actions.delete_portfolio_image, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.PORTFOLIO_IMAGE_DELETED);
                        this.getPortfolioImages();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getCountries: function () {
            this.$http.get(api.actions.get_countries).then(
                function (response) {
                    if (typeof response.data.countries != "undefined") {
                        this.countries = response.data.countries;
                        this.prefixes = this.countries.map(function (el) {
                            return el.dial_code.replace(" ", "");
                        });
                        this.prefixes = _.sortBy(_.uniqBy(this.prefixes));
                        this.changeSelectedCountry(this.profile.country_id);
                    }

                    if (typeof response.data.countries != "undefined") {
                        response.data.countries.forEach((country) => {
                            this.currencies.push(country.currency);
                        });
                        this.currencies = _.sortBy(_.uniqBy(this.currencies));
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        changeSelectedCountry: function (countryId) {
            countryId = parseInt(countryId);
            this.editValues.country_id = countryId;
            if (countryId === 0) {
                this.selectedCountryShow = "No Country Selected";
                return;
            }
            this.selectedCountry = this.countries.find((country) => country.id === countryId);
            this.selectedCountryShow = this.selectedCountry["name"];
            this.editValues.country_id = this.selectedCountry.id.toString();
        },
        changeAvatar() {
            this.changeProfileImage = true;
        },
        profileImageChange: function (e) {
            const file = e.target.files[0];

            if (file.type.indexOf("image/") === -1) {
                return;
            }
            this.profileImageUpload.file = file;
            this.profileImageUpload.fileName = file.name;
            this.resizeImage(file);
        },
        cancelProfileImageUpload: function () {
            this.profileImageUpload = {
                fileName: null,
                preview: null,
                file: null,
                croppedImage: null,
            };
            this.changeProfileImage = false;
        },
        saveProfileImageUpload: function () {
            //do not send request if no file is available
            if (!this.profileImageUpload.file) {
                return;
            }
            this.enableProfilePicSave = false;
            this.profileImageUpload.croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
            this.user.avatar = this.$refs.cropper.getCroppedCanvas().toDataURL();
            //make request to match reset code and change password
            let data = {
                image: this.profileImageUpload.croppedImage,
                dataImage: true,
                fileName: this.profileImageUpload.fileName,
            };
            this.$http.post(api.actions.save_profile_image, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.PROFILE_IMAGE_CHANGED);
                        this.cancelProfileImageUpload();
                        auth.getProfile(true);
                        this.enableProfilePicSave = true;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },

        openFileBrowserProfile: function () {
            this.$refs.profileImageInputFile.click();
        },
        flipX() {
            const dom = this.$refs.flipX;
            let scale = dom.getAttribute("data-scale");
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleX(scale);
            dom.setAttribute("data-scale", scale);
        },
        flipY() {
            const dom = this.$refs.flipY;
            let scale = dom.getAttribute("data-scale");
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleY(scale);
            dom.setAttribute("data-scale", scale);
        },
        reset() {
            this.$refs.cropper.reset();
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        },
        zoom(percent) {
            this.$refs.cropper.relativeZoom(percent);
        },
        resizeImage: function (file) {
            // Ensure it's an image
            if (!file.type.match(/image.*/)) {
                return;
            }

            // Load the image
            var reader = new FileReader();
            reader.onload = (readerEvent) => {
                var image = new Image();
                image.onload = () => {
                    // Resize the image
                    var canvas = document.createElement("canvas"),
                        max_size = 600, // TODO : pull max size from a site config
                        width = image.width,
                        height = image.height;
                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(image, 0, 0, width, height);
                    let dataUrl = canvas.toDataURL();
                    this.uploadResizedImage(dataUrl);
                };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        },
        uploadResizedImage: function (dataUrl) {
            if (this.profileImageUpload.preview != null) {
                // get loaded data and render thumbnail.
                this.profileImageUpload.preview = dataUrl;
                // rebuild cropperjs with the updated source
                this.$refs.cropper.replace(dataUrl);
            } else {
                // get loaded data and render thumbnail.
                this.profileImageUpload.preview = dataUrl;
            }
        },
        getInitial: function (name) {
            if (!name) {
                return;
            }
            return name[0].toUpperCase();
        },

        getProfileQRLink: function () {
            if (!this.user) {
                return;
            }
            let url =
                api.actions.get_profile_qr + "/" + this.user.id + "?app_id=" + this.generalOptions.app_id + "&size=150";
            url = url.replace("/web/", "/");

            return url;
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text);
            const el = document.createElement("textarea");
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            messages.successMessage(messages.GLOBAL_MESSAGES.COPIED_TO_CLIPBOARD);
        },
        getStylistProfile: function () {
            this.$http.get(api.actions.get_profile).then(
                function (response) {
                    if (typeof response.data.user != "undefined") {
                        this.stylistProfile = response.data.user;
                        this.getTimezonesList();
                        this.getClientsNotifications();
                    }
                }.bind(this)
            );
        },
        getTimezonesList: function () {
            this.$http.get(api.actions.get_timezoneslist + this.stylistProfile.details.profile.country_code).then(
                function (response) {
                    if (typeof response.data.timezones != "undefined") {
                        this.timezonesList = response.data.timezones;

                        if (!this.timezonesList[this.notification_settings.timezone]) {
                            this.notification_settings.timezone = "0";
                        }
                    }
                }.bind(this)
            );
        },
        getClientsNotifications: function () {
            this.$http.get(api.actions.get_clientnotifications).then(
                function (response) {
                    if (typeof response.data.notification_settings != "undefined") {
                        this.notification_settings = response.data.notification_settings;

                        if (!this.timezonesList[this.notification_settings.timezone]) {
                            this.notification_settings.timezone = "0";
                        }

                        if (this.user.role != "client") this.datetime_formats = response.data.datetime_formats;
                    }
                }.bind(this)
            );
        },
        saveSetting(res) {
            this.notification_settings[res.field] = res.value;
            this.saveClientsNotifications();
        },
        saveClientsNotifications: function () {
            this.$http.post(api.actions.save_clientnotifications, this.notification_settings).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                        this.getClientsNotifications();
                    }
                }.bind(this)
            );
        },
        getUserProfession: function () {
            this.$http.get(api.actions.get_user_profession + "/" + this.user.id).then(
                function (response) {
                    if (typeof response.data.user_profession != "undefined" && response.data.user_profession) {
                        this.selectedProfession = response.data.user_profession;
                        this.selectedProfessionShow = this.selectedProfession.profession;
                        this.userProfession = this.selectedProfessionShow;
                        if (response.data.user_profession) {
                            this.selectedProfessionId = response.data.user_profession.id;
                        }
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getProfessions: function () {
            this.$http.get(api.actions.get_professions + "/" + this.generalOptions.app_id).then(
                function (response) {
                    if (typeof response.data.professions != "undefined") {
                        this.professions = response.data.professions;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        checkPasswordReset() {
            if (this.password !== "" && this.passCheck !== "") {
                if (this.password === this.passCheck) {
                    this.passwordState = 1;
                    return this.resetPassword(true);
                }
            }
        },

        resetPassword: function (confirm) {
            let data = {};
            switch (this.passwordState) {
                case 0:
                    this.passwordState = 1;
                    break;
                case 1:
                    if (!confirm) {
                        this.passwordState = 0;
                        return;
                    }
                    //if no password entered the just return
                    if (!this.password || this.password.length < 5) {
                        return;
                    }
                    //make request to send the reset code
                    data = {
                        password: this.password,
                        user_id: this.user.id,
                    };

                    //change password directly
                    this.$http.post(api.actions.verify_reset_code_change_password, data).then(
                        function (response) {
                            if (response.data.error == false) {
                                messages.successMessage(messages.GLOBAL_MESSAGES.PASSWORD_CHANGED);
                                this.passwordState = 0;
                                this.password = "";
                                this.passCheck = "";
                            }
                        }.bind(this),
                        function () {
                            return false;
                        }
                    );
                    break;
            }
        },
        changePhone: function (confirm, phoneState) {
            let data = {};
            this.phoneState = phoneState;
            switch (this.phoneState) {
                case 0:
                    this.phoneState = 1;
                    break;
                case 1:
                    //if no phone the just return
                    if (!confirm || !this.phone) {
                        this.phoneState = 1;
                        return;
                    }
                    //make request to send the change phone email
                    data = {
                        prefix: this.selectedCountry.dial_code,
                        phone: this.phone,
                        user_id: this.user.id,
                    };
                    this.phoneProgress = true;
                    messages.successMessage(messages.GLOBAL_MESSAGES.PHONE_SEND_CODE_MESSAGE_WAIT);
                    this.$http.post(api.actions.send_verification_code, data).then(
                        function (response) {
                            this.phoneProgress = false;
                            if (response.data.error == false) {
                                this.phoneState = 2;
                                messages.successMessage(messages.GLOBAL_MESSAGES.PHONE_SEND_CODE_MESSAGE);
                            } else {
                                this.phoneState = 1;
                            }
                        }.bind(this),
                        function () {
                            return false;
                        }
                    );
                    break;
                case 2:
                    //if no phone or verification code the just return
                    if (!confirm || !this.verificationCode || !this.phone) {
                        this.phoneState = 0;
                        return;
                    }
                    //make request to send the change phone email
                    data = {
                        prefix: this.selectedCountry.dial_code,
                        phone: this.phone,
                        verificationCode: this.verificationCode,
                        user_id: this.user.id,
                    };
                    this.phoneProgress = true;
                    this.$http.post(api.actions.change_phone_number, data).then(
                        function (response) {
                            this.phoneProgress = false;
                            if (response.data.error == false) {
                                this.user.phone_prefix = this.selectedCountry.dial_code;
                                this.user.phone = this.phone;
                                let user = localStorage.getItem("user");
                                if (user) {
                                    user = JSON.parse(user);
                                    user.phone_prefix = this.selectedCountry.dial_code;
                                    user.phone = this.phone;
                                    localStorage.setItem("user", JSON.stringify(user));
                                }
                                this.phoneState = 0;
                                messages.successMessage(messages.GLOBAL_MESSAGES.PHONE_CHANGE_MESSAGE);
                            }
                        }.bind(this),
                        function () {
                            return false;
                        }
                    );
                    break;
            }
        },

        saveProfession: function () {
            //make request to save the new profession
            let data = {
                user_id: this.user.id,
                profession_id: this.selectedProfessionId,
            };
            this.$http.post(api.actions.save_user_profession, data).then(
                function (response) {
                    if (response.data.error == false) {
                        // messages.successMessage(
                        //   messages.GLOBAL_MESSAGES.PROFESSION_CHANGE_MESSAGE
                        // );
                        this.userProfession = this.selectedProfession.profession;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        populateEditUserValues: function () {
            for (var key in this.editUserValues) {
                this.editUserValues[key] = this.user[key];
            }

            this.editUserValues.name = this.user.first_name + " " + this.user.last_name;
        },
        editUserField: function (field) {
            this.editUser[field] = true;
        },
        cancelUserField: function (field) {
            //remove input field
            this.editUser[field] = false;
        },
        saveUserNameField() {
            let name = this.editUserValues.name.split(" ");
            this.editUserValues.first_name = name[0];
            delete name[0];
            this.editUserValues.last_name = name.join(" ");
            this.saveUserField("first_name");
            this.saveUserField("last_name");
            this.cancelUserField("name");
        },

        saveUserField: function (field) {
            //if value is same as before then just cancel
            if (this.editUserValues[field].trim() === this.user[field].trim()) {
                this.editUserValues[field] = this.user[field].trim();
                this.cancelField(field);
                return;
            }
            //make request to save the new profession
            let data = {
                [field]: this.editUserValues[field].trim(),
            };
            this.$http.post(api.actions.save_user_accounts, data).then(
                function (response) {
                    if (response.data.error == false) {
                        // messages.successMessage(
                        //   messages.GLOBAL_MESSAGES.USER_DATA_SAVED_MESSAGE
                        // );
                        //update local user
                        this.user[field] = this.editUserValues[field].trim();
                        //update the storage user
                        let storageUser = JSON.parse(localStorage.getItem("user"));
                        storageUser[field] = this.editUserValues[field].trim();
                        localStorage.setItem("user", JSON.stringify(storageUser));
                        //cancel the field, to hide input
                        this.cancelUserField(field);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },

        saveAddressSettings() {
            let data = {
                public_address: this.public_address,
            };
            this.$http.post(api.actions.save_user_accounts, data).then(
                function (response) {
                    if (response.data.error == false) {
                        this.getProfile();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        rotateImage(direction) {
            var rotateDegrees = parseInt(this.portfolioImageUpload.rotate);

            if (direction == "left") {
                rotateDegrees = (rotateDegrees - 90) % 360;
            } else {
                rotateDegrees = (rotateDegrees + 90) % 360;
            }

            this.portfolioImageUpload.rotate = rotateDegrees;
            var element = $(this.$refs.portfolioImagePreview);
            var container = $(this.$refs.portfolioImagePreviewContainer);

            container.css({
                width: this.portfolioImageUpload.width,
                height: this.portfolioImageUpload.height,
                position: "relative",
            });

            element.css({
                transform: "translateX(-50%) translateY(-50%) rotate(" + rotateDegrees + "deg)",
                top: "50%",
                left: "50%",
                position: "absolute",
            });
        },
        rotateLeftModalImage() {
            const modalImage = document.querySelector("#inner-modal-preview-image");
            const imageId = modalImage.getAttribute("data-image-id");

            this.rotateExistingImage(imageId, -90);
        },
        rotateRightModalImage() {
            const modalImage = document.querySelector("#inner-modal-preview-image");
            const imageId = modalImage.getAttribute("data-image-id");

            this.rotateExistingImage(imageId, 90);
        },
        rotateExistingImage(id, rotate) {
            let data = {
                id: id,
                rotate: rotate,
            };
            this.$http.post(api.actions.rotate_portfolio_image, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                        this.getPortfolioImages();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        removeProfileImage() {
            this.$http.post(api.actions.delete_profile_image, {}).then(
                function () {
                    this.user.avatar = "";
                    localStorage.setItem("user", JSON.stringify(this.user));
                    messages.successMessage(messages.GLOBAL_MESSAGES.DELETE_PROFILE_PICTURE);
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        scrollToTop() {
            const duration = 800; // Scroll duration in milliseconds
            const start = window.scrollY;
            const startTime = "now" in window.performance ? performance.now() : new Date().getTime();

            const scrollToTop = (timestamp) => {
                const elapsed = timestamp - startTime;
                const progress = Math.min(elapsed / duration, 1);
                const easeInOutCubic = (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);
                window.scrollTo(0, start + (0 - start) * easeInOutCubic(progress));

                if (elapsed < duration) {
                    requestAnimationFrame(scrollToTop);
                }
            };

            requestAnimationFrame(scrollToTop);
        },
        formatLikes(image_likes) {
            if (image_likes >= 1000) {
                return (image_likes / 1000).toFixed(1) + "k";
            } else {
                return image_likes.toString();
            }
        },
        modernProfileLink(profileLink) {
            let modernProfile = profileLink.replace("/stylist-profile/", "/profile/");
            return modernProfile;
        },
        modernPortfolioLink(profileLink, variation) {
            let modernProfile = profileLink.replace("/stylist-profile/", "/profile/");
            let variationModernProfile = `${modernProfile}?profile_template=${variation}`;

            return variationModernProfile;
        },
        validatePhoneNumber(phoneNumber) {
            const phoneNumberRegex = /^[0-9]*$/;

            if (!phoneNumberRegex.test(phoneNumber)) {
                this.phoneError = "Please enter only numbers";
                return false;
            } else {
                this.phoneError = "";
                return true;
            }
        },
        scrollToElement() {
            // Scroll to the element inside the modal
            this.$refs.businessPhoneTarget.scrollIntoView({ behavior: 'smooth' });
        },
    },
    components: {
        VueCropper,
        VueEditor,
        SelectComponent,
        ClientNotifications,
    },
};
</script>
