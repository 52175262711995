<template>
    <div class="admin-clients text-left">
        <div class="heading-container">
            <div class="heading-title">
                <h3>
                    {{ $ml.get("clients.title") }}
                </h3>
            </div>
        </div>
        <div class="clients-main-container">
            <div class="section-wrap">
                <div class="header-buttons-container" v-if="!showClientsEditForm">
                    <div class="notifications-btn">
                        <router-link class="primary-btn extra-small mobile"
                            :to="{ path: '/app/work-settings/notifications' }"><i class="fa fa-bell"></i>
                            {{ $ml.get("notifications.notificationsSettings") }}</router-link>
                    </div>
                    <div class="clients-btns">
                        <div>
                            <button class="primary-btn extra-small mobile" v-on:click="exportClients()">
                                {{ $ml.get("clients.exportClients") }}
                            </button>
                        </div>
                        <div>
                            <button class="primary-btn extra-small mobile" v-on:click="manageImportClientsDialog(true)">
                                {{ $ml.get("clients.importClients") }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="search-stylists-and-add-client-container" v-if="!showClientsEditForm">
                    <div class="clients-search-container">
                        <div class="client-search-icon">
                            <span class="fa fa-search text-field-feedback"></span>
                        </div>
                        <input placeholder="Search name or email" type="text" id="client-search"
                            class="text-field text-extra-small mobile" v-model="search"
                            v-on:keyup="debouncedSearch()" />
                        <div v-if="search" class="reset-search">
                            <i class="fa fa-times pointer" v-on:click="resetSearch()"></i>
                        </div>
                    </div>
                    <div class="search-btn">
                        <span v-on:click="
                            page = 1;
                        getClients();
                        " class="primary-btn extra-small mobile">{{ $ml.get("general.search") }}</span>
                    </div>
                    <div class="select-stylist-container"
                        v-if="adminDetails.group_owner == 1 && adminDetails.group_active == 1">
                        <div class="label-select">
                            <label class="stylist-label" for="stylist-select"
                                :class="generalOptions.app_id === 5 ? 'loctician' : ''">Select
                                {{
                                    generalOptions.app_id === 1
                                        ? "Stylist"
                                        : generalOptions.app_id === 2
                                            ? "Barber"
                                            : generalOptions.app_id === 5
                                                ? "Loctician"
                                                : ""
                                }}</label>
                            <select id="stylist-select" v-model="for_stylist_id" v-on:change="onStylistChange"
                                class="selectInput selectInputSmall">
                                <option value="0">{{ adminDetails.group_name }}</option>
                                <option v-for="(member, index) in groupMembers" v-bind:key="index"
                                    v-bind:value="member.user.id" v-bind:disabled="member.active != 1">
                                    {{ member.user.first_name }} {{ member.user.last_name }}
                                </option>
                            </select>
                        </div>
                        <div v-if="hasUnmergedClients" v-on:click="mergeClientsSwal" class="main-text-link">
                            {{ $ml.get("notifications.merge_all_clients") }}
                        </div>
                    </div>
                </div>
                <div class="import-clients-message" v-if="showMessage && !showClientsEditForm">
                    {{ twentyFourHoursMessage }}
                </div>
                <div class="letters-container" v-if="!showClientsEditForm">
                    <span class="letter-btn" v-on:click="setFirstLetter('')">
                        {{ $ml.get("clients.all_first_letters") }}
                    </span>
                    <span class="letter-btn" v-for="(firstLetterFilter, index) in firstLetterFilters" :key="index"
                        v-on:click="setFirstLetter(firstLetterFilter)"
                        v-bind:class="{ 'text-primary': firstLetterFilter === selectedFirstLetter }">
                        {{ firstLetterFilter }}
                    </span>
                </div>
                <div class="add-new-client-container" v-if="showClientsEditForm">
                    <div class="add-new-client-header">
                        <div v-on:click="closeClientModal">
                            <button class="primary-btn extra-small mobile">
                                <i class="fa fa-chevron-left"></i>&nbsp;Back
                            </button>
                        </div>
                        <div>
                            <h5>
                                <span v-if="!editClientObj.client_id">{{ $ml.get("clients.addNew") }}</span>
                                <span v-if="editClientObj.client_id">{{ $ml.get("clients.edit") }} {{
                                    editClientObj.first_name }}
                                    {{ editClientObj.last_name }}</span>
                            </h5>
                        </div>
                    </div>
                    <div class="client-is-shared" v-if="editClientObj.shared && !adminDetails.group_owner">This client is shared with you.</div>
                    <div class="add-new-client-content"
                        :class="editClientObj.shared && !adminDetails.group_owner ? 'disabled-button': ''">
                        <!-- Add new client image or initials container -->
                        <div class="add-new-client-avatar">
                            <div class="client-avatar">
                                <div class="user-avatar-wrap"
                                    v-if="editClientObj && !editClientObj.avatar && !profileImageUpload.preview"
                                    v-on:click="changeAvatar">
                                    <div>{{ getInitial(editClientObj.first_name) }}</div>
                                    <div v-if="editClientObj.last_name">
                                        {{ getInitial(editClientObj.last_name) }}
                                    </div>
                                </div>
                                <div v-if="profileImageUpload.preview" class="user-avatar-wrap"
                                    v-on:click="changeAvatar">
                                    <img :src="profileImageUpload.preview" alt="prev avatar" />
                                </div>
                                <div v-if="editClientObj && editClientObj.avatar && !profileImageUpload.preview"
                                    v-on:click="changeAvatar" class="user-avatar-wrap">
                                    <img :src="editClientObj.avatar" />
                                </div>

                                <div class="main-text-link" v-on:click="changeAvatar">
                                    <div v-if="editClientObj.avatar == null"><span>Add photo</span></div>
                                    <div v-if="editClientObj.avatar != null"><span>Change photo</span></div>
                                </div>
                            </div>

                            <div v-if="editClientObj" class="text-center mt-2"></div>
                            <div v-if="changeProfileImage" class="client-profile-image-container">
                                <div class="image-upload-overlay"></div>
                                <div class="image-upload-overlay-content">
                                    <input class="profile-image-input-file" type="file" ref="profileImageInputFile"
                                        name="img[]" accept="image/*" @change="profileImageChange" />
                                    <div class="client-input-group">
                                        <input type="text" class="text-field" disabled placeholder="Upload File"
                                            v-model="profileImageUpload.fileName" />
                                        <div class="input-group-append">
                                            <button v-on:click="openProfileImageInputFileBrowser" type="button"
                                                class="primary-btn extra-small mobile">
                                                Browse...
                                            </button>
                                        </div>
                                    </div>
                                    <div class="profile-image-upload-preview" v-if="profileImageUpload.preview">
                                        <div class="image-upload-preview">
                                            <vue-cropper ref="cropper" :aspect-ratio="1 / 1"
                                                :src="profileImageUpload.preview" preview=".preview" />
                                        </div>
                                        <section class="preview-area d-block d-flex justify-content-end">
                                            <div class="preview cropped-image">
                                                <div class="crop-placeholder" />
                                            </div>
                                        </section>
                                    </div>
                                    <div v-if="profileImageUpload.preview" class="modal-btns-actions">
                                        <a href="javascript:void(0)" role="button" title="Zoom In"
                                            @click.prevent="zoom(0.2)">
                                            <i class="fa fa-search-plus" aria-hidden="true"></i>
                                        </a>
                                        <a href="javascript:void(0)" role="button" title="Zoom Out"
                                            @click.prevent="zoom(-0.2)">
                                            <i class="fa fa-search-minus" aria-hidden="true"></i>
                                        </a>

                                        <a href="javascript:void(0)" role="button" title="Rotate Left"
                                            @click.prevent="rotate(-90)">
                                            <i class="fa fa-undo" aria-hidden="true"></i>
                                        </a>
                                        <a href="javascript:void(0)" role="button" title="Rotate Right"
                                            @click.prevent="rotate(90)">
                                            <i class="fa fa-repeat" aria-hidden="true"></i>
                                        </a>
                                        <a ref="flipX" href="javascript:void(0)" role="button" title="Flip X"
                                            @click.prevent="flipX">
                                            <i class="fa fa-arrows-h" aria-hidden="true"></i>
                                        </a>
                                        <a ref="flipY" href="javascript:void(0)" role="button" title="Flip Y"
                                            @click.prevent="flipY">
                                            <i class="fa fa-arrows-v" aria-hidden="true"></i>
                                        </a>
                                        <a href="javascript:void(0)" role="button" title="Refresh"
                                            @click.prevent="reset">
                                            <i class="fa fa-refresh" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                    <div class="modal-btns">
                                        <button v-if="profileImageUpload.preview" v-on:click="saveProfileImageUpload"
                                            type="button" class="primary-btn extra-small mobile">
                                            Save
                                        </button>
                                        <button v-on:click="cancelProfileImageUpload" type="button"
                                            class="secondary-btn small mobile">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Add new client inputs container -->
                        <div class="new-client-inputs-wrap">
                            <div>
                                <div class="input-container">
                                    <label for="firstName">{{ $ml.get("general.firstName") }} *</label>
                                    <div>
                                        <input maxlength="15" id="firstName" placeholder="Please enter first name"
                                            v-if="editClientObj.ebs || editClientObj.id == 0" class="text-field"
                                            :class="{ 'new-client-error': editClientObjErrors.first_name }"
                                            v-model="editClientObj.first_name" @input="clearError('first_name')" />
                                        <span v-if="!editClientObj.ebs && editClientObj.id != 0">
                                            {{ editClientObj.first_name }}
                                        </span>
                                    </div>
                                </div>
                                <div class="input-container">
                                    <label for="lastName">{{ $ml.get("general.lastName") }} *</label>
                                    <div>
                                        <input maxlength="20" id="lastName" placeholder="Please enter last name"
                                            v-if="editClientObj.ebs || editClientObj.id == 0" class="text-field"
                                            :class="{ 'new-client-error': editClientObjErrors.last_name }"
                                            v-model="editClientObj.last_name" @input="clearError('last_name')" />
                                        <span v-if="!editClientObj.ebs && editClientObj.id != 0">
                                            {{ editClientObj.last_name }}
                                        </span>
                                    </div>
                                </div>
                                <div class="input-container">
                                    <label for="clientEmail">{{ $ml.get("general.email") }}</label>
                                    <div>
                                        <input maxlength="30" id="clientEmail" placeholder="Please enter email"
                                            type="email" v-if="editClientObj.ebs || editClientObj.id == 0"
                                            class="text-field" v-model="editClientObj.email" />
                                        <span v-if="!editClientObj.ebs && editClientObj.id != 0">
                                            {{ editClientObj.email }}
                                        </span>
                                    </div>
                                </div>
                                <div class="input-container ">
                                    <label for="selectPhone">{{ $ml.get("general.phone") }}</label>
                                    <br>
                                    <span class="phone-err-message" v-if="editClientObj.phone.length > 0">
                                        {{ phoneError }}
                                    </span>
                                    <div class="select-input-wrap">
                                        <div class="dropdown">
                                            <select class="selectInput" v-model="editClientObj.phone_prefix">
                                                <option :value="0" disabled>Prefix</option>
                                                <option v-for="(country, index) in countries" v-bind:key="index"
                                                    :value="country.dial_code">
                                                    {{ country.name }} {{ country.dial_code }}
                                                </option>
                                            </select>
                                        </div>

                                        <input id="selectPhone" v-if="editClientObj.ebs || editClientObj.id == 0"
                                            placeholder="Please enter phone number" class="text-field"
                                            :class="{ 'new-client-error': phoneError }" v-model="editClientObj.phone"
                                            type="tel" min="0" max="11"
                                            @keyup="validatePhoneNumber(editClientObj.phone)"
                                            @input="limitPhoneNumber, clearError('phone')" />
                                        <span v-if="
                                            !editClientObj.ebs &&
                                            editClientObj.id != 0 &&
                                            editClientObj.phone != defaultPhone
                                        ">
                                            {{ editClientObj.phone }}
                                        </span>
                                    </div>
                                </div>
                                <div class="input-container" v-if="
                                    adminDetails.group_active && adminDetails.group_owner && groupMembers.length > 1
                                ">
                                    <label class="share-client-title">{{ $ml.get("clients.shareClientWith") }}</label>

                                    <div class="share-client-with">
                                        <div class="share-stylist-member" v-for="(member, index) in groupMembers"
                                            v-bind:key="index" v-if="member.active != 0">
                                            <label class="shared-stylist-name" :for="'stylist-input-' + member.user.id"
                                                :class="{ 'disabled-button': adminDetails.user.id == member.user.id }">{{
                                                    member.user.first_name }} {{ member.user.last_name }}</label>
                                            <div>
                                                <label class="switch">
                                                    <input type="checkbox" v-bind:id="'stylist-input-' + member.user.id"
                                                        :value="member.user.id" v-model="editClientStylists"
                                                        @change="toggleOnMembers(member.user.id, $event)" :disabled="member.active == 0 || adminDetails.user.id == member.user.id
                                                            " />
                                                    <span class="slider round" :class="{
                                                        'disabled-button': adminDetails.user.id == member.user.id,
                                                    }"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="input-container" v-if="
                                editClientObj &&
                                ((for_stylist_id != 0 && adminDetails.group_id && this.adminDetails.group_owner) ||
                                    (for_stylist_id == 0 && !adminDetails.group_id)) &&
                                editClientObj.id != 0
                            ">
                                <div class="row-stylist-member" @click="editClientObj.toggleBlock = 1"
                                    v-if="editClientObj.toggleBlock == 0">
                                    <div class="client-block">{{ $ml.get("clients.block") }}</div>
                                    <div class="h5 client-block"><i class="fa fa-unlock"></i></div>
                                </div>
                                <div class="row-stylist-member" @click="editClientObj.toggleBlock = 0"
                                    v-if="editClientObj.toggleBlock == 1">
                                    <div class="client-block">{{ $ml.get("clients.unblock") }}</div>
                                    <div class="h5 client-block"><i class="fa fa-lock"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="appt-last-appt-date"
                    v-if="(editClientObj.client_id || showClientsEditForm) && editClientObj.id > 0">
                    <div v-if="editClientObj.appointments_total == 0">
                        <div>{{ editClientObj.appointments_total }} {{ $ml.get("clients.appointment") }}</div>
                    </div>
                    <div class="inner-appt-last-appt-date"
                        v-if="editClientObj.appointments_total != 0 && editClientObj.future_appointments_total == 0">
                        <div>{{ editClientObj.appointments_total }} {{ $ml.get("clients.appointment") }}</div>
                        <div>
                            {{ $ml.get("clients.last_appointment_on") }}
                            {{ editClientObj.last_appointment_date | monthDateFormat }}
                        </div>
                    </div>
                    <div class="inner-appt-last-appt-date"
                        v-if="editClientObj.appointments_total != 0 && editClientObj.future_appointments_total > 0">
                        <div>{{ editClientObj.appointments_total }} {{ $ml.get("clients.appointment") }}</div>
                        <div>
                            {{ editClientObj.future_appointments_total }}
                            {{ $ml.get("clients.upcoming_appointment_on") }}
                            {{ editClientObj.recurringApptLine }}{{ editClientObj.futureAppointmentsLine }}
                        </div>
                    </div>
                </div>
                <div class="save-new-client-row" v-if="showClientsEditForm">
                    <div class="save-new-client-btns-wrap">
                        <div v-if="editClientObj.id" class="book-client-btn">
                            <button class="primary-btn extra-small mobile" v-on:click="bookClient">
                                Book
                                <span v-if="editClientObj.first_name">
                                    {{ editClientObj.first_name }}
                                </span>
                            </button>
                        </div>
                        <div else></div>
                        <div class="save-cancel-btns">
                            <div>
                                <button class="primary-btn extra-small mobile"
                                    :class="editClientObj.shared && !adminDetails.group_owner ? 'disabled-button' : ''"
                                    v-on:click="
                                        saveClient();
                                    ">
                                    {{ $ml.get("general.save") }}
                                </button>
                            </div>
                            <div>
                                <button class="secondary-btn small mobile" v-on:click="closeClientModal">
                                    {{ $ml.get("general.cancel") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!showClientsEditForm">
                    <div class="clients-filter-add-new-btn">
                        <ul v-if="totalClients > 0" class="clients-filter-nav">
                            <!--Active clients-->
                            <li v-on:click="setListingFilter('active')"
                                :class="{ 'link active': listingFilter == 'active' }">
                                {{ $ml.get("clients.active") }} ({{ activeClients }})
                            </li>
                            <!--Blocked clients-->
                            <li v-on:click="setListingFilter('blocked')" :class="{
                                'link active': listingFilter == 'blocked',
                                'blocked-clients-color': blockedClients > 0,
                            }">
                                {{ $ml.get("clients.blocked") }} ({{ blockedClients }})
                            </li>
                            <!--With appt clients-->
                            <li @click="setListingFilter('with_appointments')"
                                :class="{ 'link active': listingFilter == 'with_appointments' }">
                                {{ $ml.get("clients.with_appt") }} ({{ withApptClients }})
                            </li>
                            <!--With recurring clients-->
                            <li @click="setListingFilter('with_recurring')"
                                :class="{ 'link active': listingFilter == 'with_recurring' }">
                                {{ $ml.get("clients.with_recurring") }} ({{ withRecurringClients }})
                            </li>
                            <!--Unbooked clients-->
                            <li @click="setListingFilter('without_appointments')"
                                :class="{ 'link active': listingFilter == 'without_appointments' }">
                                {{ $ml.get("clients.unbooked") }} ({{ withoutApptClients }})
                            </li>
                            <li v-if="
                                adminDetails.group_owner == 1 && adminDetails.group_active == 1 && hasMergedClients
                            " @click="unmergeClientsSwal()" class="blocked-clients-color">
                                {{ $ml.get("clients.undoMergeClients") }}
                            </li>

                            <!-- <div v-if="hasMergedClients" v-on:click="unmergeAllGroupClients" class="main-text-link">
                                {{ $ml.get("notifications.unmerge_all_clients") }}
                            </div> -->
                        </ul>
                        <div class="add-new-client">
                            <button class="primary-btn extra-small mobile" v-on:click="onNewClient">
                                <i class="fa fa-plus"></i> {{ $ml.get("clients.addNew") }}
                            </button>
                        </div>
                    </div>

                    <div class="clients-wrapper">
                        <table>
                            <tbody>
                                <tr class="client-row-wrap" v-for="(client, i) in pagedClients" v-bind:key="i">
                                    <td v-if="client.is_new" class="new-client-mark">new</td>
                                    <td v-else></td>
                                    <td>{{ client.first_name }} {{ client.last_name }}</td>
                                    <td v-if="client.email" class="clientEmail">
                                        {{ getClientEmail(client.email) }}
                                    </td>
                                    <td class="client-phone" v-if="client.phone.startsWith('0000000000')">N/A</td>

                                    <td class="client-phone" v-else-if="client.phone.length > 10"
                                        v-tooltip="client.phone">
                                        {{ client.phone_prefix }}
                                        {{ client.phone.substr(0, 10) + "..." }}
                                    </td>
                                    <td class="client-phone" v-else>
                                        {{ client.phone_prefix }}
                                        {{ client.phone }}
                                    </td>
                                    <td class="client-row-btns">
                                        <div class="row-btns">
                                            <span class="primary-btn extra-small mobile" @click="
                                                bookAppointmentWithClient(
                                                    client.id,
                                                    client.first_name,
                                                    client.last_name
                                                )
                                                " title="Book client">Book</span>
                                        </div>
                                        <div class="row-btns">
                                            <span class="primary-btn extra-small mobile" v-on:click="
                                                editClient(client);
                                            getClientHistory(client.id);
                                            " title="View client">View</span>
                                        </div>
                                        <div class="row-btns">
                                            <span class="primary-btn extra-small mobile"
                                                v-on:click="blockClient(client)" title="Block client">
                                                <i class="fa" v-bind:class="{
                                                    'fa-lock':
                                                        client.blocked_stylist_ids.indexOf(for_stylist_id) === -1,
                                                    'fa-unlock':
                                                        client.blocked_stylist_ids.indexOf(for_stylist_id) !== -1 ||
                                                        listBlockedClients === 1,
                                                }" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <div class="row-btns">
                                            <span class="primary-btn extra-small mobile"
                                                v-on:click="confirmDeleteClient(client)" title="Delete client"><i
                                                    class="fa fa-times" aria-hidden="true"></i></span>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="pagedClients == 0 && loadedClients">
                                    <td>
                                        <div v-if="search" class="search-empty">
                                            {{ $ml.get("clients.searchNotFourdMessage") }}
                                        </div>
                                        <div v-if="!search" class="search-empty">
                                            {{ $ml.get("clients.noClientsMessage") }}
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="loadingClients && !loadedClients">
                                    <td class="loader-wrap">
                                        <i class="fa fa-3x fa-spinner fa-spin"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="import-clients-row">
                        <div>
                            <button class="primary-btn extra-small mobile" v-if="loadMore" v-on:click="
                                page++;
                            getClients();
                            ">
                                Load More
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="!showClientsEditForm && onlyBlocked">
                    <div class="clients-wrapper">
                        <div class="pl-3 pl-md-0 py-2 py-md-0 row client" v-for="(client, i) in pagedClients"
                            v-bind:key="i">
                            <div class="pl-0 pl-md-3 col-6 grid-col">
                                {{ client.first_name }} {{ client.last_name }}
                            </div>
                            <div class="col-6 d-flex justify-content-center align-items-center">
                                <div class="pr-0 pr-md-2 text-right text-md-left">
                                    <span class="button-edit" v-on:click="manageBlockClient(client, 0)">Unblock</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <button class="btn btn-primary" v-if="loadMore" v-on:click="getClients()">Load More</button>
                    </div>
                </div>
                <div v-if="importClientFileUpload.show">
                    <div class="import-client-upload-overlay"></div>
                    <div class="import-client-upload-overlay-content">
                        <h4 class="clients-import-heading">{{ $ml.get("clients.import_clients") }}</h4>
                        <p class="import-clients-text">
                            {{ $ml.get("clients.import_clients_text") }}
                        </p>
                        <p class="import-clients-text">
                            Please upload a <span>CSV</span>, <span>XLSX</span>, or <span>XLS</span> file format for the
                            best results. Click
                            <a v-if="generalOptions.app_id === 1" href="/files/exemple.csv" download
                                class="main-text-link">here</a>
                            <a v-if="generalOptions.app_id === 2" href="/files/exemple2.csv" download
                                class="main-text-link">here</a>
                            <a v-if="generalOptions.app_id === 5" href="/files/exemple5.csv" download
                                class="main-text-link">here</a>
                            to download an example.
                        </p>
                        <input class="import-client-input-file" type="file" ref="importClientFileInput" name="img[]"
                            v-on:change="importClientFileChange($event)" />
                        <div class="client-import-group">
                            <input type="text" class="text-field" disabled placeholder="Upload File"
                                v-model="importClientFileUpload.fileName" />
                            <div class="input-group-append browse-container">
                                <button v-on:click="openFileBrowser" type="button"
                                    class="primary-btn extra-small mobile">
                                    Browse...
                                </button>
                            </div>
                        </div>
                        <div v-if="importClientFileUpload.error" class="import-error-message">
                            {{ importClientFileUpload.error }}
                        </div>
                        <div class="modal-btns">
                            <button v-on:click="saveImportClientFileUpload" type="button"
                                class="primary-btn extra-small mobile">
                                Save
                            </button>
                            <button v-on:click="cancelImportClientFileUpload" type="button"
                                class="secondary-btn small mobile">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showClientsEditForm && editClientObj.id > 0">
                <div class="section-wrap" v-if="clientHistory.length > 0">
                    <div class="on-client-edit-title">
                        <span class="section-headline">{{ $ml.get("clients.appointments_history") }}</span>
                    </div>
                    <div v-for="(client, index) in clientHistoryItems" :key="`historyItem-${index}`"
                        class="appt-on-edit-row">
                        <div>{{ getFormattedDate(client.date) }} ({{ client.hour }})</div>
                        <div>
                            <button type="button" class="primary-btn extra-small extra-small mobile" data-toggle="modal"
                                data-target="#appointment-history-detail-modal" @click="currentClientHistory = client">
                                View Appointment
                            </button>
                        </div>
                    </div>
                    <!-- Modal -->
                    <div class="modal fade" id="appointment-history-detail-modal" tabindex="-1" role="dialog"
                        aria-labelledby="appointment-history-detail-modalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="appointment-history-detail-modalLabel">
                                        Appointment Details History
                                    </h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="details-wrapp">
                                        <div class="title">Appointment Details</div>
                                        <div class="appt-payment-wrap">
                                            <div class="history-details">
                                                <span>Name:</span>
                                                {{ currentClientHistory.first_name }}
                                                {{ currentClientHistory.last_name }}
                                            </div>
                                            <div class="history-details">
                                                <span>Email:</span>
                                                {{ currentClientHistory.email }}
                                            </div>
                                            <div class="history-details"
                                                v-if="currentClientHistory.phone != defaultPhone">
                                                <span>Phone:</span>
                                                {{ currentClientHistory.phone }}
                                            </div>
                                            <div class="history-details">
                                                <span>Date:</span>
                                                {{ currentClientHistory.date }} ({{ currentClientHistory.hour }})
                                            </div>
                                            <div class="history-details" v-if="currentClientHistory.services != ''">
                                                <span>Services:</span>
                                                {{ currentClientHistory.services.join(", ") }}
                                            </div>
                                            <div class="history-details">
                                                <span>Status:</span>
                                                {{ currentClientHistory.status }}
                                            </div>
                                            <div class="history-details" v-if="currentClientHistory.total_price > 0">
                                                <span>Total Price:</span>
                                                {{ currentClientHistory.total_price }}
                                                {{ currentClientHistory.currency }}
                                            </div>
                                            <div class="history-details">
                                                <span>Total Duration:</span>
                                                {{ currentClientHistory.total_duration }} minutes
                                            </div>
                                            <div class="history-details no-border">
                                                <span>Notes:</span>
                                                {{ currentClientHistory.notes }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" @click="openAppointmentDetails(currentClientHistory.id)"
                                        class="primary-btn extra-small mobile">
                                        Open Appointment
                                    </button>
                                    <button type="button" class="primary-btn extra-small mobile" data-dismiss="modal">
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="client-load-more" :class="{ 'space-between': hasMoreItems }">
                        <button class="primary-btn extra-small mobile" v-if="hasMoreItems"
                            @click="clientHistoryLoadMore">
                            Load More
                        </button>
                        <div class="go-top secondary-btn small" @click="scrollToTop" v-if="clientHistoryOffset > 9">
                            Scroll top <i class="fa fa-long-arrow-up" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showClientsEditForm && editClientObj.id > 0">
                <div class="section-wrap" v-if="clientPaymentsHistory.length > 0">
                    <div class="on-client-edit-title">
                        <span class="section-headline">{{ $ml.get("clients.payments_history") }}</span>
                    </div>
                    <div v-for="(clientPayment, index) in clientPaymentHistoryItems" :key="index"
                        class="appt-on-edit-row">
                        <div>{{ clientPayment.date }}</div>
                        <div>{{ clientPayment.amount }} - {{ clientPayment.payment_type }}</div>
                        <!-- Button trigger modal Payment History Details -->
                        <div>
                            <button type="button" class="primary-btn extra-small mobile" data-toggle="modal"
                                data-target="#exampleModal" @click="currentClientPaymentObj = clientPayment">
                                View Payment
                            </button>
                        </div>
                    </div>
                    <!-- Modal -->
                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Payment Details History</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="details-wrapp">
                                        <div class="title">Payment Details</div>
                                        <div class="appt-payment-wrap">
                                            <div class="history-details">
                                                <span>Name:</span>
                                                {{ currentClientPaymentObj.name }}
                                            </div>
                                            <div class="history-details">
                                                <span>Date:</span>
                                                {{ currentClientPaymentObj.date }}
                                            </div>
                                            <div class="history-details">
                                                <span>Payment type:</span>
                                                {{ currentClientPaymentObj.payment_type }}
                                            </div>
                                            <div class="history-details">
                                                <span>Status:</span>
                                                {{ currentClientPaymentObj.status }}
                                            </div>
                                            <div class="history-details" v-if="currentClientPaymentObj.payment_type != 'cash'">
                                                <span>Processor:</span>
                                                {{ currentClientPaymentObj.processor }}
                                            </div>
                                            <div class="history-details no-border">
                                                <span>Amount:</span>
                                                {{ currentClientPaymentObj.amount }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="primary-btn extra-small mobile" data-dismiss="modal">
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="client-load-more" :class="{ 'space-between': hasPaymentMoreItems }">
                        <button class="primary-btn extra-small mobile" v-if="hasPaymentMoreItems"
                            @click="clientPaymentHistoryLoadMore">
                            Load More
                        </button>
                        <div class="go-top secondary-btn small" @click="scrollToTop"
                            v-if="clientPaymentHistoryOffset > 9">
                            Scroll top <i class="fa fa-long-arrow-up" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import auth from "./../../services/auth.js";
import api from "./../../services/api_endpoints.js";
import store from "./../../services/vuex.js";
import messages from "./../../services/messages.js";
//import Paginate from 'vuejs-paginate';
import moment from "moment";
import Vue from "vue";
import eventBus from "./../../services/event_bus.js";
import $ from "jquery";

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
    name: "AdminClients",
    data: function () {
        return {
            user: null,
            loaded: false,
            for_stylist_id: 0,
            clients: [],
            pagedClients: [],
            currentPage: 1,
            loadingClients: false,
            loadedClients: false,
            search: "",
            stylistProfile: {},
            work_settings: {},
            editClientObj: {
                id: 0,
                client_id: 0,
                first_name: "",
                last_name: "",
                email: "",
                phone: "",

                appointments_total: 0,
                future_appointments_total: 0,
                futureAppointmentsLine: "",
                last_appointment_date: "",
                recurringApptLine: "",

                avatar: null,
                blocked: null,
                blocked_stylist_ids: [],
                ebs: 0,
                toggleBlock: 0,
            },
            editClientStylists: [],
            defaultPhone: "0000000000",
            page: 1,
            listBlockedClients: 0,
            hasBlockedClients: false,
            loadMore: false,
            firstLetterFilters: [],
            selectedFirstLetter: "",
            getFirstLetterFilters: true,
            importClientFileUpload: {
                show: false,
                fileName: null,
                file: null,
                error: null,
            },
            changeProfileImage: false,
            profileImageUpload: {
                preview: null,
                fileName: null,
                file: null,
                croppedImage: null,
            },
            enableProfilePicSave: true,
            hasUnmergedClients: false,
            hasMergedClients: false,
            onlyBlocked: false,
            totalClients: 0,
            activeClients: 0,
            blockedClients: 0,
            withApptClients: 0,
            withRecurringClients: 0,
            withoutApptClients: 0,
            prefixes: [],
            countries: [],
            twentyFourHoursMessage:
                "We are currently processing your client's file. Please note that this process may take up to 24 hours to complete. We will notify you as soon as the import is done. Thank you for your patience.",
            showMessage: false,

            clientHistory: [],
            currentClientHistory: { services: [] },
            clientHistoryLimit: 10,
            clientHistoryOffset: 0,
            clientHistoryItems: [],

            clientPaymentsHistory: [],
            currentClientPaymentObj: {},
            clientPaymentHistoryLimit: 10,
            clientPaymentHistoryOffset: 0,
            clientPaymentHistoryItems: [],
            listingFilter: "active",
            debounceTimer: null,
            editClientObjErrors: {
                first_name: null,
                last_name: null,
                phone: null,
            },
            phoneError: "",
        };
    },
    mounted: function () {
        this.user = auth.user;

        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });

        this.showClientsEditForm = false;
        this.resetClientObj();
        this.getCountries();
        this.checkTimeMessage();
    },
    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
    },
    computed: {
        hasMoreItems() {
            const keys = Object.keys(this.clientHistory);
            return this.clientHistoryOffset + this.clientHistoryLimit < keys.length;
        },
        hasPaymentMoreItems() {
            const keys = Object.keys(this.clientPaymentsHistory);
            return this.clientPaymentHistoryOffset + this.clientPaymentHistoryLimit < keys.length;
        },
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        groupMembers: {
            get: function () {
                return store.state.stylists;
            },
            set: function (value) {
                store.commit("setStylists", value);
            },
        },
        showClientsEditForm: {
            get: function () {
                return store.state.showClientsEditForm;
            },
            set: function (value) {
                store.commit("setShowClientsEditForm", value);
            },
        },
        selectedClient: {
            get: function () {
                return store.state.selectedClient;
            },
            set: function (value) {
                store.commit("setSelectedClient", value);
            },
        },
    },
    methods: {
        bookAppointmentWithClient(id, firstName, lastName) {
            sessionStorage.setItem("bookClientId", id);
            sessionStorage.setItem("bookClientFirstName", firstName);
            sessionStorage.setItem("bookClientLastName", lastName);

            this.$router.push("/app");
        },
        openAppointmentDetails(id) {
            $("#appointment-history-detail-modal").modal("hide");
            sessionStorage.setItem("openEventId", id);
            this.$router.push("/app");
        },
        displayedItems() {
            const keys = Object.keys(this.clientHistory);
            return keys
                .slice(this.clientHistoryOffset, this.clientHistoryOffset + this.clientHistoryLimit)
                .reduce((obj, key) => {
                    obj[key] = this.clientHistory[key];
                    return obj;
                }, {});
        },

        clientHistoryLoadMore() {
            this.clientHistoryOffset += this.clientHistoryLimit;
            this.clientHistoryItems = _.merge(this.clientHistoryItems, this.displayedItems());
        },
        displayedPaymentItems() {
            const keys = Object.keys(this.clientPaymentsHistory);
            return keys
                .slice(
                    this.clientPaymentHistoryOffset,
                    this.clientPaymentHistoryOffset + this.clientPaymentHistoryLimit
                )
                .reduce((obj, key) => {
                    obj[key] = this.clientPaymentsHistory[key];
                    return obj;
                }, {});
        },
        clientPaymentHistoryLoadMore() {
            this.clientPaymentHistoryOffset += this.clientPaymentHistoryLimit;
            this.clientPaymentHistoryItems = _.merge(this.clientPaymentHistoryItems, this.displayedPaymentItems());
        },
        getClientHistory(client_id) {
            this.$http.post(api.actions.get_client_appointments_history, { client_id: client_id, limit: 50 }).then(
                function (response) {
                    if (response.data && response.data.history) {
                        this.clientHistory = response.data.history;
                        this.clientHistoryItems = this.displayedItems();
                    }

                    if (response.data && response.data.payment_details) {
                        this.clientPaymentsHistory = response.data.payment_details.orders;
                        this.clientPaymentHistoryItems = this.displayedPaymentItems();
                    }
                }.bind(this)
            );
        },
        getStylistProfile: function () {
            this.$http.get(api.actions.get_profile).then(
                function (response) {
                    if (typeof response.data.user != "undefined") {
                        this.stylistProfile = response.data.user;
                    }
                }.bind(this)
            );
        },
        onAdminDetailsLoad: function () {
            if (this.adminDetails.group_id && this.adminDetails.group_owner) {
                this.getGroupsMembers(this.adminDetails.group_id);
            } else {
                this.getClients();
            }
            this.getStylistProfile();
            this.getClientsNumber();
        },
        getGroupsMembers: function (group_id) {
            this.serviceCategories = [];
            this.services = {};

            this.$http.get(api.actions.get_stylist_group_members + group_id).then(
                function (response) {
                    if (typeof response.data.members != "undefined") {
                        this.groupMembers = response.data.members;
                        this.getClients();
                        this.checkUnmergedClients();
                        this.checkMergedClients();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        onStylistChange: function () {
            this.currentPage = 1;
            this.page = 1;
            this.getClients();
            this.getClientsNumber();
        },
        getClients: function (pages = [], page = 1, currentPage = 1) {
            if (pages.length > 0) {
                this.page = page;
            }

            let data = {
                search: this.search,
                group_id: 0,
                page: this.page,
                firstLetter: null,
                getFirstLetterFilters: false,
            };

            if (this.search) {
                this.selectedFirstLetter = "";
            }

            if (this.selectedFirstLetter !== "") {
                data["firstLetter"] = this.selectedFirstLetter;
            }

            if (this.getFirstLetterFilters) {
                data["getFirstLetterFilters"] = true;
                this.getFirstLetterFilters = false;
            }

            if (this.selectedFirstLetter.length > 0) {
                data["getFirstLetterFilters"] = false;
                this.getFirstLetterFilters = false;
            }
            //Blocked clients
            if (this.listingFilter === "blocked") {
                data.blocked = true;
            }
            //With appointments
            if (this.listingFilter === "with_appointments") {
                data.with_appointments = true;
            }
            //With recurring
            if (this.listingFilter === "with_recurring") {
                data.with_recurring = true;
            }

            //Without appointments
            if (this.listingFilter === "without_appointments") {
                data.without_appointments = true;
            }

            let method = "get_group_clients";

            if (this.adminDetails.group_id && this.adminDetails.group_owner && this.adminDetails.group_active) {
                data.group_id = this.adminDetails.group_id;
            }

            if (this.for_stylist_id != 0) {
                data.group_id = 0;
                data.user_id = this.for_stylist_id;
            }

            if (this.page == 1) {
                this.pagedClients = [];
            }
            this.loadingClients = true;
            this.loadedClients = false;

            this.$http.post(api.actions[method], data).then(
                function (response) {
                    if (typeof response.data.clients != "undefined") {
                        if (this.page == 1) {
                            this.pagedClients = [];
                            this.clients = [];
                        }

                        this.clients = response.data.clients;
                        this.loadingClients = false;
                        this.loadedClients = true;

                        if (this.for_stylist_id != 0) {
                            this.clients = _.filter(this.clients, (el) => {
                                let ids = el.stylist_ids;
                                return ids.indexOf(this.for_stylist_id) !== -1;
                            });
                        }

                        if (this.page != 1) {
                            this.pagedClients = this.pagedClients.concat(this.clients);
                        } else {
                            //for first page, replace the results
                            this.pagedClients = this.clients;
                        }
                        // this.page++;

                        //firstLetterFilter
                        if (
                            typeof response.data.firstLetterFilters != "undefined" &&
                            response.data.firstLetterFilters.length > 0
                        ) {
                            this.firstLetterFilters = response.data.firstLetterFilters.filter((item) => {
                                return item.trim() != "" && item.trim() != "%";
                            });
                        }

                        page++;
                        if (pages.length > 0 && page <= currentPage) {
                            this.getClients(pages, page, currentPage);
                            return;
                        }

                        this.loadMore = response.data.loadMore;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },

        getFormattedDate(date) {
            return moment(date).format("MMMM Do");
        },
        resetClientObj: function () {
            this.editClientObj = {
                id: 0,
                client_id: 0,
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                phone_prefix: "",

                avatar: null,
                blocked: null,
                blocked_stylist_ids: [],

                appointments_total: 0,
                future_appointments_total: 0,
                futureAppointmentsLine: "",
                last_appointment_date: "",
                recurringApptLine: "",
                ebs: 0,
                toggleBlock: 0,
                shared: 0,
            };

            this.editClientStylists = [];
            this.cancelProfileImageUpload();
            this.clientHistoryItems = [];
            this.clientHistoryOffset = 0;
            this.clientPaymentHistoryItems = [];
            this.clientPaymentHistoryOffset = 0;
            this.search = "";
        },
        toggleOnMembers(userId = null, isChecked = true) {
            if (userId === null) {
                // If no specific userId is provided, toggle all members based on `isChecked`
                this.groupMembers.forEach((member) => {
                    if (isChecked && member.active) {
                        if (!this.editClientStylists.includes(member.user.id)) {
                            this.editClientStylists.push(member.user.id);
                        }
                    } else {
                        this.editClientStylists = this.editClientStylists.filter((id) => id !== member.user.id);
                    }
                });
            }
        },
        onNewClient: function () {
            this.resetClientObj();
            this.phoneError = "";

            if (this.adminDetails.group_id && this.adminDetails.group_owner) {
                this.toggleOnMembers();
            }
            this.setDefaultCountryPrefix();

            this.showClientsEditForm = true;
        },
        setDefaultCountryPrefix() {
            if (!this.editClientObj.phone_prefix) {
                this.countries.forEach((country) => {
                    if (country.id == this.adminDetails.profile.country_id) {
                        this.editClientObj.phone_prefix = country.dial_code;
                    }
                });
            }
        },

        closeEditClientsForm() {
            this.showClientsEditForm = !this.showClientsEditForm;
        },

        saveClient: function (avatarData = null) {
            if (this.editClientObj.phone.length > 0 && !this.validatePhoneNumber(this.editClientObj.phone)) {
                return;
            }

            if (this.editClientObj.first_name === "" || this.editClientObj.last_name === "") {
                this.validateClient();
                return;
            }

            let endpoint = this.editClientObj.id == 0 ? api.actions.add_client_user : api.actions.update_client_user;

            this.editClientObj.phone = this.editClientObj.phone ? this.editClientObj.phone : this.defaultPhone;
            let data = this.editClientObj;
            //this will allow stylist to add a new existing client
            //under them
            data.allow_adding_existing_email = true;

            //delete all the whitespaces from first_name last_name and email
            this.editClientObj.first_name = this.editClientObj.first_name.trim();
            this.editClientObj.last_name = this.editClientObj.last_name.trim();
            this.editClientObj.email = this.editClientObj.email.trim();
            this.editClientObj.phone = this.editClientObj.phone.trim();

            if (data.client_id != 0) {
                let client = _.filter(this.pagedClients, { id: data.client_id });

                //here include the group_user_id only if the logged in user is member of a group AND
                //is the admin of the group
                //and include it as the stylist id who actually owns the client
                if (client[0] && this.adminDetails.group_id && this.adminDetails.group_owner) {
                    let targetStylist = null;
                    for (let i = 0; i < client[0].stylist_ids.length; i++) {
                        targetStylist = _.filter(this.groupMembers, { user_id: client[0].stylist_ids[i] });
                        if (targetStylist[0]) {
                            break;
                        }
                    }
                    if (targetStylist) {
                        data.group_user_id = targetStylist[0]["user_id"];
                    } else {
                        //cancel the update
                        Vue.toasted.show("Something is not right. Please try again later.", {
                            type: "error",
                            position: "top-center",
                            duration: 2000,
                        });
                        return;
                    }
                }
            }

            //add avatar data
            if (avatarData !== null) {
                data.image = avatarData.image;
                data.dataImage = avatarData.dataImage;
                data.fileName = avatarData.fileName;
            }

            this.editClientObj.activeStylistIds = this.editClientStylists;

            data.web_override = true;

            this.$http.post(endpoint, data).then(
                function (response) {
                    if (response.data.error == false) {
                        if (this.editClientObj.toggleBlock != this.editClientObj.blocked) {
                            this.manageBlockClient(this.editClientObj, this.editClientObj.toggleBlock);
                        }

                        this.closeEditClientsForm();

                        if (this.adminDetails.group_active && this.adminDetails.group_owner) {
                            this.saveClientLink(response.data.user.id);
                            return;
                        }
                        this.cancelProfileImageUpload();
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                        //this.resetClientObj();
                        this.editClientObj.id = response.data.user.id;
                        //refresh the list
                        this.refreshClientList();

                    }
                }.bind(this),
                function () {
                    return false;
                }
            );

            if (!this.selectedFirstLetter) {
                this.getFirstLetterFilters = true;
            }
        },
        saveClientLink: function (client_id) {
            let data = {
                client_id: client_id,
                stylists: {},
            };

            this.groupMembers.forEach((el) => {
                data.stylists[el.user.id] = {
                    active: this.editClientObj.activeStylistIds.indexOf(el.user.id) !== -1,
                };
            });

            this.$http.post(api.actions.save_group_stylists_client_link, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                        //this.resetClientObj();

                        //refresh the list
                        this.refreshClientList();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        editClient: function (client) {
            this.validatePhoneNumber(client.phone);
            let recurringApptLine = "";
            let futureAppointmentsLine = "";
            let self = this;
            if (client.future_appointments_total > 0) {
                if (client.recurringApptData.length > 0) {
                    let recurringApptLines = [];
                    client.recurringApptData.forEach(function (item) {
                        recurringApptLines.push(
                            self.getFormattedDate(item.apptDate) + " (recurring - " + item.apptCount + " occurrences)"
                        );
                        //August 30th (recurring - 29 occurrences)
                    });
                    recurringApptLine = recurringApptLines.join(", ");
                }

                if (client.futureAppointments.length > 0) {
                    let futureAppointmentsLines = [];
                    client.futureAppointments.forEach(function (item) {
                        futureAppointmentsLines.push(self.getFormattedDate(item.date));
                        //August 30th
                    });
                    futureAppointmentsLine = futureAppointmentsLines.join(", ") + ".";
                    if (recurringApptLine != "") {
                        futureAppointmentsLine = ", " + futureAppointmentsLine;
                    }
                }
            }

            this.editClientObj = {
                id: client.id,
                client_id: client.id,
                first_name: client.first_name,
                last_name: client.last_name,
                email: client.email,
                phone: client.phone != this.defaultPhone ? client.phone : "",
                phone_prefix: client.phone != this.defaultPhone ? client.phone_prefix : "",
                avatar: client.avatar,
                blocked: client.blocked,
                blocked_stylist_ids: client.blocked_stylist_ids,
                appointments_total: client.appointments_total,
                future_appointments_total: client.future_appointments_total,
                futureAppointmentsLine: futureAppointmentsLine,
                last_appointment_date: client.last_appointment_date,
                recurringApptLine: recurringApptLine,
                ebs: 1, //client.ebs - enable the edit for all the clients
                toggleBlock: client.blocked,
                activeStylistIds: client.active_stylist_ids,
                shared: client.shared,
            };

            this.editClientStylists = this.editClientObj.activeStylistIds;
            this.setDefaultCountryPrefix();

            this.showClientsEditForm = true;

            window.scrollTo(0, 0);
        },

        blockClient: function (client) {
            let data = {
                client_id: client.id,
                stylist_id: this.for_stylist_id,
                block: client.blocked_stylist_ids.indexOf(this.for_stylist_id) === -1 ? (client.blocked ? 0 : 1) : 0,
            };

            let method = api.actions.block_client;
            if (this.adminDetails.group_id && this.adminDetails.group_owner) {
                method = api.actions.block_group_client;
            }

            this.$http.post(method, data).then(
                function (response) {
                    if (response.data.error == false) {
                        if (data.block == 1) {
                            messages.successMessage(messages.GLOBAL_MESSAGES.CLIENT_ADDED_TO_BLOCKLIST);
                        } else {
                            messages.successMessage(messages.GLOBAL_MESSAGES.CLIENT_ADDED_TO_THELIST);
                        }
                        //page reset
                        this.currentPage = 1;
                        this.page = 1;
                        this.getClients();
                        this.getClientsNumber();

                        //then update the opened client
                        if (this.showClientsEditForm && this.editClientObj.id) {
                            this.editClientObj.blocked = data.block;
                        }
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );

            // this.getClientsNumber();
        },

        manageBlockClient: function (client, block = null) {
            let data = {
                client_id: client.id,
                stylist_id: this.for_stylist_id,
            };

            if (block === null) {
                data["block"] =
                    client.blocked_stylist_ids.indexOf(this.for_stylist_id) === -1 ? (client.blocked ? 0 : 1) : 0;
            } else {
                data["block"] = block;
            }

            let method = api.actions.block_client;
            if (this.adminDetails.group_id && this.adminDetails.group_owner) {
                method = api.actions.block_group_client;
            }

            this.$http.post(method, data).then(
                function (response) {
                    if (response.data.error == false) {
                        //messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);

                        //page reset
                        this.currentPage = 1;
                        this.page = 1;
                        this.getClients();
                        this.getClientsNumber();

                        //then update the opened client
                        if (this.showClientsEditForm && this.editClientObj.id) {
                            this.editClientObj.blocked = data.block;
                        }
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },

        confirmDeleteClient: function (client) {
            Vue.swal({
                title: this.$ml.get("adminDashboard.details.cofirmQuestion"),
                type: "warning",
                showCancelButton: true,
                confirmButtonText: this.$ml.get("clients.cofirmYes"),
                cancelButtonText: this.$ml.get("clients.cofirmNo"),
            }).then((result) => {
                if (result.value) {
                    this.deleteClient(client);
                }
            });
        },

        deleteClient: function (client) {
            let data = {
                client_id: client.id,
            };
            this.$http.post(api.actions.delete_client, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_DELETE);
                        //refresh the list
                        this.refreshClientList();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );

            this.getClientsNumber();
            this.getFirstLetterFilters = true;
        },

        refreshClientList: function () {
            let currentPage = this.page - 1;
            let pages = [];
            for (let i = 1; i <= currentPage; i++) {
                pages.push(i);
            }
            this.getClients(pages, 1, currentPage);
            this.getClientsNumber();
        },

        resetSearch: function () {
            //do nothing if search is empty
            if (!this.search) {
                return;
            }

            this.search = "";
            this.page = 1;
            this.getClients();
        },

        setFirstLetter: function (firstLetter) {
            this.search = "";
            this.page = 1;
            this.selectedFirstLetter = firstLetter;
            this.getClients();
        },

        setListBlockedClients: function (blockedClients) {
            this.page = 1;
            this.listBlockedClients = blockedClients;
            this.getFirstLetterFilters = true;
            this.getClients();
        },

        exportClients: function () {
            let data = {
                search: "",
                group_id: 0,
                page: 0,
                paginate: 0,
                get_appointments_data: false,
                firstLetter: null,
                getFirstLetterFilters: false,
                blocked: this.listBlockedClients,
            };

            let method = "get_group_clients";

            if (this.adminDetails.group_id && this.adminDetails.group_owner && this.adminDetails.group_active) {
                data.group_id = this.adminDetails.group_id;
            }

            if (this.for_stylist_id != 0) {
                data.group_id = 0;
                data.user_id = this.for_stylist_id;
            }

            this.$http.post(api.actions[method], data).then(
                function (response) {
                    if (typeof response.data.clients != "undefined") {
                        var clients = response.data.clients;

                        let csv = "First Name,Last Name,Email,Phone\n";
                        clients.forEach((row) => {
                            let exportrow = [row.first_name, row.last_name, row.email, row.phone];
                            csv += exportrow.join(",");
                            csv += "\n";
                        });

                        const anchor = document.createElement("a");
                        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
                        anchor.target = "_blank";
                        anchor.download = "export.csv";
                        anchor.click();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },

        manageImportClientsDialog: function (value) {
            this.importClientFileUpload.show = value;
        },

        importClientFileChange: function (e) {
            //allowed file types:
            let allowedFileTypes = [
                "text/csv",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.ms-excel",
            ];
            let allowedExt = ["csv", "xls", "xlsx"];
            let file = e.target.files[0];
            let ext = file.name.split(".").pop();

            if (
                (file.type && allowedFileTypes.indexOf(file.type) == -1) ||
                (!file.type && allowedExt.indexOf(ext) == -1)
            ) {
                this.importClientFileUpload.error =
                    "File Type not allowed. Only csv, xlsx and xls file types are allowed.";
                return;
            }

            this.importClientFileUpload.file = file;
            this.importClientFileUpload.fileName = file.name;
            this.importClientFileUpload.error = null;
        },

        cancelImportClientFileUpload: function () {
            this.importClientFileUpload = {
                show: false,
                fileName: null,
                file: null,
                error: null,
            };
        },
        saveImportClientFileUpload: function () {
            //do not send request if no file is available
            if (!this.importClientFileUpload.file) {
                return;
            }
            //make request to match reset code and change password
            let formData = new FormData();
            formData.append("file", this.importClientFileUpload.file);
            formData.append("fileName", this.importClientFileUpload.fileName);
            this.$http.post(api.actions.save_import_client_file, formData).then(
                function (response) {
                    if (response.data.error == false) {
                        localStorage.setItem(`lastShownTime_${this.user.id}`, new Date().getTime());
                        this.showMessage = true;
                        this.checkTimeMessage();
                        messages.successMessage(messages.GLOBAL_MESSAGES.SAVE_IMPORT_CLIENT_FILE_SUCCESS);
                        this.cancelImportClientFileUpload();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },

        openFileBrowser: function () {
            this.$refs.importClientFileInput.click();
        },
        getInitial: function (name) {
            if (!name) {
                return;
            }
            return name[0].toUpperCase();
        },
        changeAvatar() {
            this.changeProfileImage = true;
        },
        profileImageChange: function (e) {
            const file = e.target.files[0];

            //check how big is the image
            if (file != "") {
                const fileSize = file.size;
                const fileMb = fileSize / 1024 ** 2;

                if (fileMb >= 2) {
                    messages.errorMessage(messages.ERROR_MESSAGES.IMAGE_TO_BIG);

                    return;
                }
            }

            if (file.type.indexOf("image/") === -1) {
                return;
            }
            this.profileImageUpload.file = file;
            this.profileImageUpload.fileName = file.name;
            this.resizeImage(file);
        },
        cancelProfileImageUpload: function () {
            this.profileImageUpload = {
                fileName: null,
                preview: null,
                file: null,
                croppedImage: null,
            };
            this.changeProfileImage = false;
            this.enableProfilePicSave = true;
        },
        openProfileImageInputFileBrowser: function () {
            this.$refs.profileImageInputFile.click();
        },
        flipX() {
            const dom = this.$refs.flipX;
            let scale = dom.getAttribute("data-scale");
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleX(scale);
            dom.setAttribute("data-scale", scale);
        },
        flipY() {
            const dom = this.$refs.flipY;
            let scale = dom.getAttribute("data-scale");
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleY(scale);
            dom.setAttribute("data-scale", scale);
        },
        reset() {
            this.$refs.cropper.reset();
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        },
        zoom(percent) {
            this.$refs.cropper.relativeZoom(percent);
        },
        resizeImage: function (file) {
            // Ensure it's an image
            if (!file.type.match(/image.*/)) {
                return;
            }

            // Load the image
            var reader = new FileReader();
            reader.onload = (readerEvent) => {
                var image = new Image();
                image.onload = () => {
                    // Resize the image
                    var canvas = document.createElement("canvas"),
                        max_size = 600, // TODO : pull max size from a site config
                        width = image.width,
                        height = image.height;
                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(image, 0, 0, width, height);
                    let dataUrl = canvas.toDataURL();
                    this.uploadResizedImage(dataUrl);
                };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        },
        uploadResizedImage: function (dataUrl) {
            if (this.profileImageUpload.preview != null) {
                // get loaded data and render thumbnail.
                this.profileImageUpload.preview = dataUrl;
                // rebuild cropperjs with the updated source
                this.$refs.cropper.replace(dataUrl);
            } else {
                // get loaded data and render thumbnail.
                this.profileImageUpload.preview = dataUrl;
            }
        },
        saveProfileImageUpload: function () {
            //do not send request if no file is available
            if (!this.profileImageUpload.file) {
                return;
            }
            this.enableProfilePicSave = false;
            this.profileImageUpload.croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
            this.editClientObj.image = this.profileImageUpload.croppedImage;
            this.editClientObj.dataImage = true;
            this.editClientObj.fileName = this.profileImageUpload.fileName;
            this.changeProfileImage = false;
            //make request to match reset code and change password
            // let data = {
            //     image: this.profileImageUpload.croppedImage,
            //     dataImage: true,
            //     fileName: this.profileImageUpload.fileName,
            // };
            // this.saveClient(data);
        },
        bookClient: function () {
            this.selectedClient = this.editClientObj;
            this.showClientsEditForm = false;
            this.$router.push("/app");
        },
        checkMergedClients: function () {
            if (this.adminDetails.group_owner == 0 || this.adminDetails.group_active == 0) {
                return;
            }

            this.$http.get(api.actions.check_linked_group_clients + this.adminDetails.group_id).then(
                function (response) {
                    if (response.data.error == false) {
                        this.hasMergedClients = response.data.has_linked_clients;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        checkUnmergedClients: function () {
            if (this.adminDetails.group_owner == 0 || this.adminDetails.group_active == 0) {
                return;
            }

            this.$http.get(api.actions.check_unlinked_group_clients + this.adminDetails.group_id).then(
                function (response) {
                    if (response.data.error == false) {
                        this.hasUnmergedClients = response.data.has_unlinked_clients;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        unmergeAllGroupClients: function () {
            if (this.adminDetails.group_owner == 0 || this.adminDetails.group_active == 0) {
                return;
            }

            let data = {
                group_id: this.adminDetails.group_id,
            };

            this.$http.post(api.actions.unlink_group_clients, data).then(
                function (response) {
                    if (response.data.error == false) {
                        this.hasUnmergedClients = false;
                        messages.successMessage(messages.GLOBAL_MESSAGES.CLIENTS_UNMERGE_SUCCESS);
                        this.checkUnmergedClients();
                        this.checkMergedClients();
                        this.getClientsNumber();
                        this.refreshClientList();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        mergeAllGroupClients: function () {
            if (this.adminDetails.group_owner == 0 || this.adminDetails.group_active == 0) {
                return;
            }

            let data = {
                group_id: this.adminDetails.group_id,
            };

            this.$http.post(api.actions.update_unlinked_group_clients, data).then(
                function (response) {
                    if (response.data.error == false) {
                        this.hasUnmergedClients = false;
                        messages.successMessage(messages.GLOBAL_MESSAGES.CLIENTS_MERGE_SUCCESS);
                        this.checkUnmergedClients();
                        this.checkMergedClients();
                        this.getClientsNumber();
                        this.refreshClientList();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        mergeClientsSwal() {
            Vue.swal({
                title: this.$ml.get("adminDashboard.details.cofirmQuestion"),
                text: "Merging all clients will make all shop clients visible to every stylist and team member.",
                confirmButtonColor: "#00cdc1",
                cancelButtonColor: "#07b2c0",
                confirmButtonText: this.$ml.get("clients.mergeYes"),
                cancelButtonText: this.$ml.get("clients.mergeNo"),
                type: "warning",

                showCancelButton: true,
            }).then((result) => {
                if (result.value) {
                    this.mergeAllGroupClients();
                }
            });
        },
        unmergeClientsSwal() {
            Vue.swal({
                title: "Are you sure you want to proceed with the merge?",
                text: "“This action will remove the shop clients from this team member.",
                confirmButtonColor: "#00cdc1",
                cancelButtonColor: "#07b2c0",
                confirmButtonText: this.$ml.get("clients.mergeYes"),
                cancelButtonText: this.$ml.get("clients.mergeNo"),
                type: "warning",

                showCancelButton: true,
            }).then((result) => {
                if (result.value) {
                    this.unmergeAllGroupClients();
                }
            });
        },
        getClientEmail: function (email) {
            //only show valid emails which means
            //which dont have @ringmystylist.com in them
            const appEmails = ["ringmystylist", "ringmystybarber", "ringmystygroomer"];
            let IsItAnAppEmail = false;
            appEmails.forEach((appEmail) => {
                if (email.indexOf(appEmail) !== -1) {
                    IsItAnAppEmail = true;
                }
            });
            if (IsItAnAppEmail) {
                return;
            }
            return email;
        },
        getBlockedClients: function () {
            this.onlyBlocked = true;
            this.search = "";
            this.page = 1;
            this.getClients();
        },
        getUnblockedClients: function () {
            this.onlyBlocked = false;
            this.search = "";
            this.page = 1;
            this.getClients();
        },
        getClientsNumber: function () {
            let data = "";

            if (this.adminDetails.group_active == 1 && this.adminDetails.group_owner == 1) {
                data = "?group_id=" + this.adminDetails.group_id;

                if (this.for_stylist_id > 0) {
                    data += "&user_id=" + this.for_stylist_id;
                }
            }

            this.$http.get(api.actions.get_clients_number + data).then(
                function (response) {
                    if (response.data.error == false) {
                        return;
                    }

                    this.totalClients = response.data.total_clients ? response.data.total_clients : 0;
                    this.activeClients = response.data.active_clients ? response.data.active_clients : 0;
                    this.blockedClients = response.data.blocked_clients ? response.data.blocked_clients : 0;
                    this.withApptClients = response.data.total_clients_with_appointments
                        ? response.data.total_clients_with_appointments
                        : 0;
                    this.withRecurringClients = response.data.total_clients_with_recurring
                        ? response.data.total_clients_with_recurring
                        : 0;
                    this.withoutApptClients = response.data.total_clients_without_appointments
                        ? response.data.total_clients_without_appointments
                        : 0;
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getCountries: function () {
            this.$http.get(api.actions.get_countries).then(
                function (response) {
                    if (typeof response.data.countries != "undefined") {
                        this.countries = response.data.countries;
                        this.prefixes = this.countries.map(function (el) {
                            return el.dial_code.replace(" ", "");
                        });
                        this.prefixes = _.sortBy(_.uniqBy(this.prefixes));
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        setUserPhonePrefix: function (phonePrefix) {
            this.editClientObj.phone_prefix = phonePrefix;
        },
        checkTimeMessage() {
            const lastShownTime = localStorage.getItem(`lastShownTime_${this.user.id}`);

            if (lastShownTime) {
                const currentTime = new Date().getTime();
                const twentyFourHours = 24 * 60 * 60 * 1000;

                if (currentTime - lastShownTime < twentyFourHours) {
                    this.showMessage = true;
                }
            }
        },
        limitPhoneNumber() {
            if (this.editClientObj.phone.length > 11) {
                this.editClientObj.phone = this.editClientObj.phone.slice(0, 11);
            }
        },
        setListingFilter(filter) {
            this.listingFilter = filter;
            this.page = 1;
            this.getClients();
        },
        closeClientModal() {
            this.showClientsEditForm = !this.showClientsEditForm;
            this.resetClientObj();
            this.resetErrors();
            this.phoneError = "";
        },
        debouncedSearch() {
            clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(() => {
                this.getClients();
            }, 500);
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },

        validatePhoneNumber(phoneNumber) {
            const phoneNumberRegex = /^[0-9]*$/;

            if (!phoneNumberRegex.test(phoneNumber)) {
                this.phoneError = "Please enter only numbers";

                return false;
            } else {
                this.phoneError = "";

                return true;
            }
        },
        validateClient() {
            this.resetErrors(); // Clear any previous error messages

            let validField = true;

            // Validate first name
            if (!this.editClientObj.first_name) {
                this.editClientObjErrors.first_name = "First name is required";
                validField = false;
            }

            // Validate last name
            if (!this.editClientObj.last_name) {
                this.editClientObjErrors.last_name = "Last name is required";
                validField = false;
            }

            if (validField) {
                // If valid, submit the form or proceed with further logic

            }
        },

        // Method to clear error for a specific field when the user starts typing
        clearError(field) {
            this.editClientObjErrors[field] = null;
        },

        // Reset all error messages
        resetErrors() {
            this.editClientObjErrors = {
                first_name: null,
                last_name: null,
                phone: null,
            };
        },
    },
    components: {
        VueCropper,
    },
};
</script>
