<template>
    <!--Subscription main container-->
    <div v-if="user && user.role == 'stylist'" class="subscription-payments">
        <!--Subscription wrapper-->
        <div class="subscription-wrapper">
            <!--Subscription heading-->
            <div class="scubscription-heading">
                <h3>{{ $ml.get("subscription.title") }}</h3>
            </div>
            <!--Subscription content-->
            <div class="subscription-content section-wrap">
                <!--Subscription details-->
                <div class="subscription-details section-wrap highlighted-box">
                    <div class="subscription-subtitle">
                        <h5>{{ $ml.get("adminMenu.subscriptionDetails") }}</h5>
                    </div>
                    <div v-if="adminDetails.user" class="text-field-wrap">
                        <div class="subscription-details-row">
                            Active:
                            <strong v-if="adminDetails.web_trial == false">{{
                                adminDetails.subscription && adminDetails.subscription.active == 1 ? "Yes" : "No"
                                }}</strong>
                            <strong v-if="adminDetails.web_trial == true">Trial</strong>
                        </div>
                    </div>
                    <div v-if="
                            adminDetails.subscription && !hasDatePast(adminDetails.subscription.subscription_expires_on)
                        " class="text-field-wrap">
                        <div class="subscription-details-row">
                            <span
                                v-if="adminDetails.subscription.active == 0 || adminDetails.web_trial == true">Available
                                Until:
                            </span>
                            <span v-if="adminDetails.subscription.active == 1 && adminDetails.web_trial == false">Next
                                Renew Date:
                            </span>
                            <strong v-if="adminDetails.subscription.active == 0 || adminDetails.web_trial == true">{{
                                getFormattedDate(adminDetails.subscription.subscription_expires_on, "MMMM D, Y")
                                }}</strong>
                            <strong v-if="adminDetails.subscription.active == 1 && adminDetails.web_trial == false">{{
                                displayRenewDate(adminDetails.subscription.subscription_expires_on, "MMMM D, Y")
                                }}</strong>
                        </div>
                    </div>
                    <!--Square-->
                    <div v-if="adminDetails.stripe_subscription && adminDetails.stripe_subscription.active == 1"
                        class="text-field-wrap">
                        <div class="subscription-details-row">
                            <span>Number of Users: </span>
                            <strong> {{ adminDetails.stripe_subscription.users_number }}</strong>
                        </div>
                        <div class="subscription-details-row">
                            <span>Total Cost: </span>
                            <strong>{{ adminDetails.stripe_subscription.users_number * subscriptionPrice
                                }}/month</strong>
                        </div>
                        <div v-if="cards_loaded && paymentMethods.length == 0" class="text-danger">
                            Add a payment method before creating a new subscription.<br />
                            We won’t charge your Credit Card unless the Subscription is due or you purchase other
                            services.
                        </div>
                        <div v-if="
                                adminDetails.user &&
                                cards_loaded &&
                                paymentMethods.length > 0 &&
                                (!adminDetails.stripe_subscription ||
                                    (adminDetails.stripe_subscription && adminDetails.stripe_subscription.active == 0))
                            ">
                            <div class="">
                                <div>Number of Users:</div>
                                <div class="input-group w-50 mx-3">
                                    <input type="number" v-model="subscriptionUsersNum" :min="usersNumber"
                                        name="number_of_users" class="form-control subscription-users-input" />
                                </div>
                            </div>
                        </div>
                        <div v-if="adminDetails.stripe_subscription && adminDetails.stripe_subscription.active == 1"
                            class="btns-wrap">
                            <div>
                                <button v-on:click="onCancelSubscription" type="button"
                                    class="primary-btn extra-small mobile">
                                    Cancel Subscription
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- Braintree Subscription -->
                    <div v-if="
                            !adminDetails.stripe_subscription ||
                            (adminDetails.stripe_subscription && adminDetails.stripe_subscription.active == 0)
                        ">
                        <div v-if="
                                adminDetails.braintree_subscription &&
                                adminDetails.braintree_subscription.active == 1 &&
                                adminDetails.group_owner == 1
                            " class="text-field-wrap">
                            <div class="subscription-details-row">
                                <span>Number of Users: </span>
                                <strong> {{ adminDetails.braintree_subscription.users_number }}</strong>
                            </div>
                        </div>
                        <div v-if="
                                adminDetails.braintree_subscription && adminDetails.braintree_subscription.active == 1
                            " class="text-field-wrap">
                            <div class="subscription-details-row">
                                <span>Total Cost: </span>
                                <strong v-if="adminDetails.braintree_subscription.billing_cycle == 'monthly'">{{
                                    (adminDetails.braintree_subscription.users_number * subscriptionPrice).toFixed(
                                    2
                                    )
                                    }}/month</strong>
                                <strong v-if="adminDetails.braintree_subscription.billing_cycle == 'yearly'">{{
                                    (
                                    adminDetails.braintree_subscription.users_number * subscriptionPriceYear
                                    ).toFixed(2)
                                    }}/year</strong>
                                <span v-if="isDiscuntActive(adminDetails.braintree_subscription)">
                                    (50% OFF First Year)</span>
                            </div>
                        </div>
                        <div v-if="
                                adminDetails.braintree_subscription && adminDetails.braintree_subscription.active == 1
                            " class="btns-wrap">
                            <div>
                                <button v-on:click="cancelBraintreeSubscription" type="button"
                                    class="primary-btn extra-small mobile">
                                    Cancel Subscription
                                </button>
                            </div>
                        </div>

                        <div v-if="
                                !adminDetails.braintree_subscription ||
                                (adminDetails.braintree_subscription && adminDetails.braintree_subscription.active == 0)
                            ">
                            <div v-if="braintree_cards.length == 0" class="text-danger">
                                Add a payment method before creating a new subscription.<br />
                                We won’t charge your Credit Card unless the Subscription is due or you purchase other
                                services.
                            </div>
                            <div v-if="braintree_cards_loaded && braintree_cards.length > 0">
                                <div class="subscription-details-row">
                                    <div>Billing Cycle:</div>
                                    <div>
                                        <select v-model="billingCycle" name="billing_cycle"
                                            class="text-field text-field-small">
                                            <option value="monthly">Month (${{ subscriptionPrice }}/user)</option>
                                            <option value="yearly">Year (${{ subscriptionPriceYear }}/user)</option>
                                        </select>
                                    </div>
                                </div>
                                <div v-if="adminDetails.group_owner == 1" class="">
                                    <div class="subscription-details-row">
                                        <div>Number of Users:</div>
                                        <div>
                                            <input type="number" v-model="subscriptionUsersNum" :min="usersNumber"
                                                name="number_of_users"
                                                class="text-field text-field-small subscription-users-input" />
                                        </div>
                                    </div>
                                </div>
                                <div class="btns-wrap">
                                    <div>
                                        <button v-on:click="createBraintreeSubscription" type="button"
                                            class="primary-btn extra-small mobile" :disabled="loading">
                                            Create Subscription
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Payment methods-->
                <div class="payment-methods section-wrap highlighted-box">
                    <div class="payment-methods-wrap">
                        <div class="payment-subtitle">
                            <h5>{{ $ml.get("adminMenu.paymentMethods") }}</h5>
                        </div>
                        <div class="content-wrap">
                            <div class="payment-methods-wrapper">
                                <div class="section-wrap" v-if="braintree_cards.length > 0">
                                    <div v-if="braintree_cards.length > 0" class="payment-methods-head">
                                        <div>Number</div>
                                        <div>Expiration</div>
                                        <div>Type</div>
                                        <div>Added at</div>
                                    </div>
                                    <div v-for="(method, i) in braintree_cards" :key="i"
                                        class="braintree-cards-wrapper">
                                        <div class="braintree-card-wrap">
                                            <div class="braintree-cards-cards">Number</div>
                                            <div>***{{ method.last4 }}</div>
                                        </div>
                                        <div class="braintree-card-wrap">
                                            <div class="braintree-cards-cards">Expiration</div>
                                            <div>{{ method.expirationMonth }}/{{ method.expirationYear }}</div>
                                        </div>
                                        <div class="braintree-card-wrap">
                                            <div class="braintree-cards-cards">Type</div>
                                            <div>{{ method.cardType }}</div>
                                        </div>
                                        <div class="braintree-card-wrap">
                                            <div class="braintree-cards-cards">Added at</div>
                                            <div>{{ dateCardAddedMonthDayYear(method.created_at) }} {{ dateCardAddedHour(method.created_at) }}</div>
                                        </div>

                                        <div class="braintree-card-wrap">
                                            <span v-if="method.default"
                                                class="primary-btn extra-small mobile default-method">Default</span>
                                            <span class="primary-btn extra-small mobile"
                                                v-if="!method.default && !loading"
                                                v-on:click="setDefaultBraintreeCard(method.payment_method_token)">Set
                                                Default</span>
                                        </div>
                                        <div class="braintree-card-wrap">
                                            <span class="primary-btn extra-small mobile delete-card"
                                                v-if="!method.default && !loading"
                                                v-on:click="deleteBraintreeCard(method.payment_method_token)">Delete</span>
                                        </div>
                                    </div>
                                </div>
                                <!--Stripe card wrap-->
                                <div v-if="displayNewBraintreeCardForm" class="stripe-card-wrap section-wrap">
                                    <div class="card-form" ref="braintree_card"></div>
                                    <div class="card-logos">
                                        <img class="verified" src="../../assets/images/verified-secured.jpg" />
                                    </div>
                                    <div class="card-errors">{{ cards_errors }}</div>

                                    <div class="terms-wrap">
                                        <div>
                                            Payments and Refund Policy:<br />
                                            Unless we state in writing otherwise, payments are due monthly in advance
                                            and are non-refundable.<br />
                                        </div>
                                        <div class="push-form-checkbox">
                                            <label for="agreeCardTerms" class="switch"><input id="agreeCardTerms"
                                                    type="checkbox" v-model="agreeCardTerms" />

                                                <span class="slider round"></span></label>
                                            <label for="agreeCardTerms" class="push-form-check-label">{{
                                                $ml.get("marketing.agreeTerms")
                                                }}</label>
                                        </div>
                                    </div>

                                    <div class="action-buttons-wrap">
                                        <div>
                                            <button v-on:click="saveNewBraintreeCard" type="button"
                                                class="primary-btn extra-small mobile"
                                                :disabled="loading || !agreeCardTerms">
                                                Add Card
                                            </button>
                                        </div>
                                        <div>
                                            <button v-on:click="
                                                    displayNewBraintreeCardForm = false;
                                                    agreeCardTerms = false;
                                                " type="button" class="secondary-btn small mobile" :disabled="loading">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="cards_errors" class="card-errors-wrap">
                                    Error adding the card: {{ cards_errors }}
                                </div>
                                <div v-if="!displayNewBraintreeCardForm" class="btns-wrap">
                                    <div>
                                        <button type="button" class="primary-btn extra-small mobile"
                                            v-on:click="addNewBraintreeCard">
                                            Add New Card
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Your team name-->
                <div v-if="adminDetails.group_owner == 1" class="your-team-name section-wrap highlighted-box">
                    <div class="your-team-name-wrapper">
                        <div class="team-name-edit-wrap">
                            <h5 v-if="!editTeamName">
                                {{ adminDetails.group_name != "" ? adminDetails.group_name : "" }}
                            </h5>
                            <span v-if="adminDetails.group_owner == 1 && !editTeamName" v-on:click="editTeamName = true"
                                class="primary-btn extra-small mobile edit-team-icon" aria-hidden="true">Edit</span>
                        </div>
                        <div v-if="editTeamName" class="edit-team-name-wrap">
                            <input v-model="adminDetails.group_name" type="text" name="group_name"
                                class="text-field text-field-small text-field-extra-small" />

                            <div class="your-name-btns">
                                <div v-on:click="saveTeamName" class="primary-btn extra-small mobile">Save</div>
                                <div v-on:click="editTeamName = false" class="secondary-btn small mobile">Cancel</div>
                            </div>
                        </div>
                        <div v-if="adminDetails.user" class="edit-members-wrap">
                            <div class="members-wrapper section-wrap">
                                <div class="members-list">
                                    <div class="member-header">
                                        <div>{{ $ml.get("general.name") }}</div>
                                        <div>{{ $ml.get("general.email") }}</div>
                                        <div>{{ $ml.get("general.phone") }}</div>
                                        <div>{{ $ml.get("general.active") }}</div>
                                    </div>
                                    <div class="member" v-for="(member, index) in groupMembers" v-bind:key="index">
                                        <div class="member-row-cell">
                                            <div class="member-row-heading">{{ $ml.get("general.name") }}</div>
                                            <a class="main-text-link" v-if="member.user" target="_blank"
                                                v-bind:href="member.profile_url">
                                                {{ member.user.first_name }} {{ member.user.last_name }}
                                                {{
                                                member.activeNumber == 2
                                                ? "(" + $ml.get("general.pending") + ")"
                                                : ""
                                                }}
                                                <span v-if="
                                                        (adminDetails.group_id != 0 &&
                                                            adminDetails.group_owner_id == member.user.id) ||
                                                        (adminDetails.group_id == 0 &&
                                                            adminDetails.user.id == member.user.id)
                                                    ">(ADMIN)</span>
                                            </a>
                                        </div>
                                        <div class="member-row-cell">
                                            <div class="member-row-heading">{{ $ml.get("general.email") }}</div>
                                            {{ member.user.email }}
                                        </div>
                                        <div class="member-row-cell">
                                            <div class="member-row-heading">{{ $ml.get("general.phone") }}</div>
                                            {{ member.user.phone }}
                                        </div>
                                        <div class="member-action-cell">
                                            <div class="member-row-heading">{{ $ml.get("general.active") }}</div>
                                            <label class="switch"
                                                v-bind:class="{ 'action-disabled': member.activeNumber == 2 }">
                                                <input type="checkbox" v-bind:value="1" v-model="member.active"
                                                    v-on:change="displaySaveButton = true"
                                                    :disabled="adminDetails.group_owner == 0" class="member-active" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                        <div v-if="adminDetails.group_owner == 1" class="member-action-wrap">
                                            <span v-on:click="editTeamMember(member.user), startScrolling()"
                                                class="pointer primary-btn extra-small mobile">
                                                Edit</span>
                                            <div class="member-invite-actions" v-if="member.activeNumber == 2">
                                                <span class="btn-link">
                                                    <img class="logos logo-pending"
                                                        src="../../assets/images/hourglass-outline.svg"
                                                        title="Pending invite" />
                                                </span>
                                                <button type="button" class="btn btn-link"
                                                    v-on:click="resendEmailInvite(member)">
                                                    <img class="logos" src="../../assets/images/sync-outline.svg"
                                                        title="Resend invite" />
                                                </button>
                                            </div>
                                            <span v-if="
                                                    adminDetails &&
                                                    (member.activeNumber == 0 || member.activeNumber == 2) &&
                                                    member.user_id != adminDetails.user.id
                                                " class="pointer">
                                                <button v-on:click="removeMemberConfirmation(member.user_id)"
                                                    type="button" class="btn btn-link">
                                                    <img class="logos" src="../../assets/images/trash-outline.svg"
                                                        title="Remove from Team" />
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="adminDetails.user && displaySaveButton" class="btns-wrap">
                                    <div>
                                        <input type="button" class="primary-btn extra-small mobile" name="save-team"
                                            :value="$ml.get('general.saveChanges')" v-on:click="saveGroupDetails" />
                                    </div>
                                </div>
                            </div>

                            <!--Edit member-->
                            <div id="edit-member"></div>
                            <div class="new-member-form section-wrap" v-if="displayNewMemberForm">
                                <div v-if="braintree_cards.length == 0" class="mt-3 text-danger">
                                    Add a payment method before creating a new subscription.
                                </div>
                                <div v-if="braintree_cards_loaded && braintree_cards.length > 0">
                                    <div class="new-member-wrap">
                                        <div>{{ $ml.get("general.name") }} *</div>
                                        <div>
                                            <input class="text-field text-field-small" v-model="editMemberObj.full_name"
                                                placeholder="Please enter name" />
                                        </div>
                                    </div>
                                    <div class="new-member-wrap">
                                        <div>{{ $ml.get("general.phone") }} *</div>
                                        <div>
                                            <input type="number" class="text-field text-field-small"
                                                v-model="editMemberObj.phone" placeholder="Please enter phone" />
                                        </div>
                                    </div>
                                    <div class="new-member-wrap">
                                        <div>{{ $ml.get("general.email") }}*</div>
                                        <div>
                                            <input class="text-field text-field-small" v-model="editMemberObj.email"
                                                placeholder="Please enter email" />
                                        </div>
                                    </div>
                                    <div>
                                        <div class="row-toggle">
                                            <label for="send-invite" class="switch">
                                                <input id="send-invite" type="checkbox"
                                                    v-model="editMemberObj.sendInvite" />
                                                <span class="slider round"></span>
                                            </label>
                                            <label for="send-invite"></label>Send invite to grant calendar privileges
                                        </div>
                                    </div>

                                    <div class="btns-wrap">
                                        <div>
                                            <button class="primary-btn extra-small mobile" v-on:click="onSaveMember"
                                                :disabled="loading">
                                                {{ $ml.get("general.save") }}
                                            </button>
                                        </div>
                                        <div>
                                            <button class="secondary-btn small mobile" v-on:click="resetMemberObj"
                                                :disabled="loading">
                                                {{ $ml.get("general.cancel") }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="invite-form-wrap section-wrap" v-if="invite">
                            <div v-if="braintree_cards.length == 0" class="text-danger">
                                Add a payment method before creating a new subscription.
                            </div>
                            <div v-if="braintree_cards_loaded && braintree_cards.length > 0">
                                <div class="invite-phone-field">
                                    <label for="invite-phone">{{ $ml.get("general.phone") }}: </label>
                                    <input class="text-field text-field-small" id="invite-phone"
                                        v-model="invitePhoneNumber" v-on:keyup="filterPhone"
                                        placeholder="Enter phone number" maxlength="10" />
                                    <span>-</span>
                                    <span>{{ $ml.get("general.or") }}</span>
                                    <span>-</span>
                                    <label for="invite-email">{{ $ml.get("general.email") }}: </label>
                                    <input class="text-field text-field-small" id="invite-email" v-model="inviteEmail"
                                        placeholder="Enter email" />
                                </div>
                                <div class="btns-wrap">
                                    <div>
                                        <button class="primary-btn extra-small mobile" v-if="invite"
                                            v-on:click="sendInvite">
                                            <i class="fa fa-plus"></i> {{ $ml.get("general.sendInvite") }}
                                        </button>
                                    </div>
                                    <div>
                                        <button class="secondary-btn small mobile" v-if="invite"
                                            v-on:click="toggleInvite">
                                            <i class="fa fa-delete"></i>{{ $ml.get("general.cancel") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="
                                adminDetails.user &&
                                displayNewMemberForm == false &&
                                invite == false &&
                                adminDetails.group_owner == 1
                            " class="btns-wrap">
                            <div>
                                <button v-on:click="displayNewMemberForm = true" class="primary-btn extra-small mobile">
                                    <i class="fa fa-plus"></i> {{ $ml.get("team.addNewStaff") }}
                                </button>
                            </div>
                            <div>
                                <button class="primary-btn extra-small mobile" v-on:click="toggleInvite">
                                    <i class="fa fa-plus"></i> {{ $ml.get("team.inviteStaff") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Payments log-->
                <div v-if="adminDetails.user && adminDetails.sorted_payments_list.length > 0"
                    class="payments-log-container section-wrap highlighted-box">
                    <div class="payments-log-heading">
                        <h5>{{ $ml.get("adminMenu.paymentsLog") }}</h5>
                    </div>
                    <div class="payments-log-content section-wrap">
                        <div class="payments-log-head">
                            <div>Payment Date</div>
                            <div>Paid Amount</div>
                            <div>Processor</div>
                            <div>Event Type</div>
                        </div>
                        <div v-for="(payment, i) in adminDetails.sorted_payments_list" :key="`${i}_${payment.id}`"
                            class="payment-log-row">
                            <div class="payment-wrap">
                                <div class="payment-log-row-heading">Payment Date</div>
                                {{ getFormattedDate(payment.created_at, "MMMM D, Y") }}
                            </div>

                            <div class="payment-wrap">
                                <div class="payment-log-row-heading">Paid Amount</div>
                                ${{ payment.amount_paid }}
                                <span v-if="
                                        payment.amount_paid == 0 &&
                                        payment.log_type == 'Stripe' &&
                                        payment.notification_type == 'Subscription created'
                                    ">(free trial)</span>
                                <span v-if="
                                        payment.amount_paid == 0 &&
                                        payment.log_type == 'iOS' &&
                                        payment.notification_type == 'Initial buy'
                                    ">(free trial)</span>
                                <span v-if="
                                        payment.amount_paid == 0 &&
                                        payment.log_type == 'Android' &&
                                        payment.notification_type == 4
                                    ">(free trial)</span>
                                <span v-if="
                                        payment.amount_paid == 0 &&
                                        payment.log_type == 'Braintree' &&
                                        payment.notification_type == 'Subscription went active'
                                    ">(free trial)</span>
                            </div>
                            <div class="payment-wrap">
                                <div class="payment-log-row-heading">Processor</div>
                                {{ payment.log_type }}
                            </div>

                            <div class="payment-wrap">
                                <div class="payment-log-row-heading">Event Type</div>
                                <div v-if="payment.log_type == 'Stripe'">
                                    {{
                                    typeof stripeStatuses[payment.event_type] != "undefined"
                                    ? stripeStatuses[payment.event_type]
                                    : ""
                                    }}
                                </div>
                                <div v-if="payment.log_type == 'Android'">
                                    {{
                                    typeof androidStatuses[payment.notification_type] != "undefined"
                                    ? androidStatuses[payment.notification_type]
                                    : ""
                                    }}
                                </div>
                                <div v-if="payment.log_type == 'iOS'">{{ payment.notification_type }}</div>
                                <div v-if="payment.log_type == 'Braintree'">
                                    {{ payment.notification_type }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "bootstrap";
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";
import messages from "./../../services/messages.js";
import moment from "moment";
import LoadScript from "vue-plugin-load-script";
import Vue from "vue";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

Vue.use(LoadScript);
Vue.loadScript("https://js.stripe.com/v3/");
Vue.loadScript("https://js.braintreegateway.com/web/3.73.1/js/client.min.js");
Vue.loadScript("https://js.braintreegateway.com/web/dropin/1.26.0/js/dropin.min.js");
Vue.loadScript("https://js.braintreegateway.com/web/3.73.1/js/three-d-secure.min.js");

export default {
    name: "AdminSubscription",
    data: function () {
        return {
            user: null,
            stripeCards: [],
            stripeDefaultCard: "",
            stripe: null,
            stripe_card: null,
            cards_loaded: false,
            cards_errors: "",
            stripe_client_secret: "",
            getting_stripe_client_secret: false,
            paymentMethods: [],
            braintree_cards: [],
            braintree_cards_loaded: false,
            loading: false,
            displayNewCardForm: false,
            displayNewBraintreeCardForm: false,
            braintree_client_token: "",
            braintree_dropin_instance: null,
            threeDSecure: null,
            usersNumber: 1,
            subscriptionUsersNum: 1,
            billingCycle: "monthly",
            subscriptionPrice: this.generalOptions.subscriptionPrice,
            subscriptionPriceYear: this.generalOptions.subscriptionPriceYear,
            groupMembers: [],
            showRemove: false,
            location: {
                address: "",
                city: "",
                state: "",
                zip: "",
                phone: "",
            },
            invite: false,
            invitePhoneNumber: "",
            inviteEmail: "",
            displaySaveButton: false,
            displayNewMemberForm: false,
            agreeCardTerms: false,
            editMemberObj: {
                app_id: this.generalOptions.app_id,
                full_name: "",
                email: "",
                phone: "",
                id: "",
                sendInvite: false,
            },
            editTeamName: false,
            stripeStatuses: {
                "invoice.created": "Subscription created",
                "invoice.payment_failed": "Payment failed",
                "invoice.payment_succeeded": "Subscription renewed",
                "customer.subscription.deleted": "Subscription canceled",
            },
            androidStatuses: {
                1: "Subscription recovered",
                2: "Subscription renewed",
                3: "Subscription canceled",
                4: "Subscription purchased ",
                5: "Subscription on hold",
                6: "Subscription in grace period",
                7: "Subscription restarted",
                8: "Subscription price change confirmed",
                9: "Subscription deferred",
                10: "Subscription paused",
                11: "Subscription pause schedule changed",
                12: "Subscription revoked",
                13: "Subscription expired",
            },
        };
    },
    mounted: function () {
        this.user = auth.user;

        eventBus.$on("user_profile_loaded", function () {
            self.user = auth.user;
        });

        this.getStylistDetails();

        if (this.user.role == "stylist") {
            this.getStylistPaymentMethods();
            this.getBraintreeCards();
        }
    },
    beforeDestroy() {
        eventBus.$off("user_profile_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
    },
    methods: {
        scrollToMember() {
            const targetElement = document.getElementById("edit-member");

            if (targetElement) {
                const scrollPosition = targetElement.offsetTop - window.innerHeight / 2;

                window.scrollTo({
                    top: scrollPosition,
                    behavior: "smooth",
                });
            }
        },

        startScrolling() {
            this.scrollInterval = setTimeout(() => {
                this.scrollToMember();
            }, 500);
        },

        getGroupsMembers: function (group_id) {
            this.$http.get(api.actions.get_stylist_group_members + group_id + "?admin_request=1").then(
                function (response) {
                    if (typeof response.data.members != "undefined") {
                        this.groupMembers = response.data.members;
                        this.groupMembers.forEach((el, index) => {
                            this.groupMembers[index]["activeNumber"] = el.active;
                            this.groupMembers[index]["active"] = el.active == 1 ? 1 : 0;
                        });
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        removeMemberConfirmation(memberId) {
            Vue.swal({
                title: this.$ml.get("adminDashboard.details.cofirmQuestion"),
                type: "warning",
                showCancelButton: true,
                confirmButtonText: this.$ml.get("adminDashboard.details.cofirmYesGeneral"),
                cancelButtonText: this.$ml.get("adminDashboard.details.cofirmNoGeneral"),
            }).then((result) => {
                if (result.value) {
                    this.removeMember(memberId);
                }
            });
        },
        removeMember: function (memberId) {
            let data = {
                member_id: memberId,
                group_id: this.adminDetails.group_id,
            };
            this.$http.post(api.actions.remove_team_member, data).then(
                function (response) {
                    if (response.data.error == false) {
                        if (this.adminDetails.group_id && this.adminDetails.group_owner) {
                            this.getGroupsMembers(this.adminDetails.group_id);
                        }
                        messages.successMessage(messages.GLOBAL_MESSAGES.MEMBER_REMOVED);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        saveGroupDetails: function () {
            let group_name = this.adminDetails.group_name ? this.adminDetails.group_name : "";
            let data = {
                name: group_name,
                active: this.adminDetails.group_active ? 1 : 0,
                address: this.location.address,
                city: this.location.city,
                state: this.location.state,
                zip: this.location.zip,
                phone: this.location.phone,
                users: {},
            };

            this.groupMembers.forEach(function (el) {
                if (typeof el.user !== "undefined") {
                    data.users[el.user.id] = el.activeNumber == 2 ? 2 : el.active;
                }
            });

            this.$http.post(api.actions.save_stylist_group, data).then(
                function (response) {
                    if (response.data.error == false) {
                        this.getStylistDetails(this.user.id);
                        let message = "";

                        if (response.data.updated_subscription == true) {
                            message =
                                ". " +
                                messages.GLOBAL_MESSAGES.SUBSCRIPTION_UPDATED.replace(
                                    ":num",
                                    response.data.active_members_number
                                );
                        }

                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE + message);
                        this.displaySaveButton = false;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getStylistDetails: function () {
            this.$http.get(api.actions.get_stylist_details + this.user.id).then(
                function (response) {
                    if (typeof response.data.details !== "undefined") {
                        if (
                            response.data.details.active == 1 &&
                            response.data.details.group_members.length > 0 &&
                            response.data.details.group_owner == 1
                        ) {
                            this.usersNumber = response.data.details.group_members.length;
                            this.subscriptionUsersNum = this.usersNumber;
                        }

                        let adminDetails = response.data.details;

                        if (adminDetails.group_id) {
                            this.getGroupsMembers(adminDetails.group_id);
                        } else {
                            this.groupMembers.push({
                                user: adminDetails.user,
                                active: 1,
                                activeNumber: 1,
                                profile_url: adminDetails.profile.website,
                            });
                        }

                        if (this.adminDetails.group_location) {
                            this.location = this.adminDetails.group_location;
                        }

                        if (response.data.details.braintree_subscription) {
                            this.billingCycle = response.data.details.braintree_subscription.billing_cycle;
                        }

                        store.commit("setAdminDetails", adminDetails);
                        eventBus.$emit("admin_details_loaded");
                    }
                }.bind(this)
            );
        },
        getFormattedDate: function (date, format) {
            return moment(date, "YYYY-MM-DD hh:mm:ss").format(format);
        },
        displayRenewDate: function (date, format) {
            let time = moment(date, "YYYY-MM-DD hh:mm:ss").unix() - 48 * 3600;
            return moment.unix(time).format(format);
        },
        hasDatePast(date) {
            let time = moment(date, "YYYY-MM-DD hh:mm:ss").unix() - 48 * 3600;
            let currentTime = moment().unix();
            return currentTime > time;
        },
        onCancelSubscription: function () {
            this.$swal({
                title: "Are you sure you want to cancel the subscription?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).then((result) => {
                if (result.value) {
                    this.$http.post(api.actions.cancel_stripe_subscription, {}).then(function (response) {
                        if (response.data.error == true) {
                            return;
                        }

                        eventBus.$emit("reload_user_details");
                        messages.successMessage(messages.GLOBAL_MESSAGES.SUBSCRIPTION_CANCELED);
                    });
                }
            });
        },
        getStylistPaymentMethods() {
            this.$http.get(api.actions.get_stripe_payment_methods).then((response) => {
                this.loading = false;

                if (response.data.error == true || !response.data.payment_methods.length) {
                    return;
                }

                this.paymentMethods = response.data.payment_methods;
                this.stripeDefaultCard = response.data.default_payment_method_id;

                if (!this.stripeDefaultCard && this.paymentMethods.length > 0) {
                    this.setDefaultCard(this.paymentMethods[0].id);
                }

                this.cards_loaded = true;
            });
        },
        getStripeSetupIntent() {
            if (this.getting_stripe_client_secret) {
                return;
            }

            this.getting_stripe_client_secret = true;

            this.$http.post(api.actions.get_stripe_setup_intent, { user_id: this.user.id }).then((response) => {
                if (response.error == true) {
                    return;
                }

                this.stripe_client_secret = response.data.client_secret;
                this.getting_stripe_client_secret = false;
            });
        },
        displayCardForm() {
            if (!this.$refs.stripe_card) {
                setTimeout(() => {
                    this.displayCardForm();
                }, 100);
                return;
            }

            this.stripe = window.Stripe(api.STRIPE_PUBLIC_KEY);

            var elements = this.stripe.elements();
            this.stripe_card = elements.create("card");
            this.stripe_card.mount(this.$refs.stripe_card);

            this.stripe_card.addEventListener("change", (event) => {
                if (event.error) {
                    this.cards_errors = event.error.message;
                } else {
                    this.cards_errors = "";
                }
            });

            this.getStripeSetupIntent();
        },
        addNewCard: function () {
            this.displayNewCardForm = true;
            this.displayCardForm();
        },
        saveNewCard: function () {
            this.stripe
                .confirmCardSetup(this.stripe_client_secret, {
                    payment_method: {
                        card: this.stripe_card,
                        billing_details: {},
                    },
                })
                .then((result) => {
                    if (result.error) {
                        this.cards_errors = result.error.message;
                        return;
                    } else {
                        this.displayNewCardForm = false;
                        var cardsNum = this.paymentMethods.length;

                        //Start subscription after adding the first card
                        if (
                            cardsNum == 0 &&
                            (!this.adminDetails.stripe_subscription ||
                                (this.adminDetails.stripe_subscription &&
                                    this.adminDetails.stripe_subscription.active == 0))
                        ) {
                            this.createSubscription();
                        }

                        this.getStylistPaymentMethods();
                    }
                });
        },
        setDefaultCard: function (card_id) {
            var data = {
                id: card_id,
            };

            this.loading = true;

            this.$http.post(api.actions.set_default_stripe_payment_method, data).then((response) => {
                this.loading = false;

                if (response.data.error == true) {
                    return;
                }
                this.getStylistPaymentMethods();
            });
        },
        deleteCard: function (card_id) {
            this.$swal({
                title: "Are you sure you want to delete the payment method?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).then((result) => {
                if (result.value) {
                    this.loading = true;

                    this.$http
                        .post(api.actions.delete_stripe_payment_method, { id: card_id })
                        .then(function (response) {
                            this.loading = false;

                            if (response.data.error == true) {
                                return;
                            }

                            this.getStylistPaymentMethods();
                        });
                }
            });
        },
        createSubscription: function () {
            if (this.subscriptionUsersNum < this.usersNumber) {
                messages.errorMessage(messages.ERROR_MESSAGES.SUBSCRIPTION_USERS_NUMBER + this.usersNumber);
                return;
            }

            this.loading = true;

            var data = {
                users_number: this.subscriptionUsersNum,
            };

            this.$http.post(api.actions.save_stripe_subscription, data).then((response) => {
                this.loading = false;

                if (response.data.error == true) {
                    return;
                }

                messages.successMessage(messages.GLOBAL_MESSAGES.SUBSCRIPTION_CREATED);
                eventBus.$emit("reload_user_details");
            });
        },

        getBraintreeCards() {
            this.$http.get(api.actions.get_braintree_customer_cards).then((response) => {
                this.loading = false;

                if (response.data.error == true || !response.data.response.length) {
                    return;
                }

                this.braintree_cards = response.data.response;
                this.braintree_cards_loaded = true;
            });
        },
        setDefaultBraintreeCard(card_id) {
            this.loading = true;

            this.$http
                .post(api.actions.set_default_braintree_customer_card, { payment_method_token: card_id })
                .then(() => {
                    this.loading = false;
                    this.getBraintreeCards();
                });
        },
        deleteBraintreeCard(card_id) {
            this.$swal({
                title: "Are you sure you want to delete the payment method?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).then((result) => {
                if (result.value) {
                    this.loading = true;

                    this.$http
                        .post(api.actions.delete_braintree_customer_cards, { payment_method_token: card_id })
                        .then(() => {
                            this.loading = false;
                            this.getBraintreeCards();
                        });
                }
            });
        },
        saveNewBraintreeCard() {
            this.braintree_dropin_instance.requestPaymentMethod((error, payload) => {
                if (error) {
                    return false;
                }

                this.displayNewBraintreeCardForm = false;
                this.loading = true;
                this.cards_errors = "";

                this.$http
                    .post(api.actions.save_braintree_customer_card, { payment_method_nonce: payload.nonce })
                    .then((res) => {
                        if (res.data.error) {
                            this.cards_errors = res.data.message;
                            return;
                        }

                        this.loading = false;
                        messages.successMessage(messages.GLOBAL_MESSAGES.CARD_ADDED);
                        this.getBraintreeCards();
                        this.agreeCardTerms = false;
                    });
            });
        },
        addNewBraintreeCard() {
            this.displayNewBraintreeCardForm = true;

            if (!this.$refs.braintree_card) {
                setTimeout(() => {
                    this.addNewBraintreeCard();
                }, 100);
                return;
            }

            this.$http.get(api.actions.get_braintree_client_token).then((response) => {
                this.braintree_client_token = response.data.client_token;

                window.braintree.dropin.create(
                    {
                        authorization: this.braintree_client_token,
                        container: this.$refs.braintree_card,
                    },
                    (error, dropinInstance) => {
                        this.braintree_dropin_instance = dropinInstance;
                    }
                );
            });
        },
        createBraintreeSubscription() {
            if (this.subscriptionUsersNum < this.usersNumber) {
                messages.errorMessage(messages.ERROR_MESSAGES.SUBSCRIPTION_USERS_NUMBER + this.usersNumber);
                return;
            }

            this.loading = true;

            var data = {
                users_number: this.subscriptionUsersNum,
                app_id: this.generalOptions.app_id,
                period: this.billingCycle,
            };

            if (!this.adminDetails.group_name) {
                this.adminDetails.group_name = "";
            }

            if (process.env.VUE_APP_BRAINTREE_3D_SECURE == 0) {
                this.$http.post(api.actions.save_braintree_subscription, data).then((response) => {
                    this.loading = false;

                    if (response.data.error == true) {
                        return;
                    }

                    this.saveTeamName();
                    messages.successMessage(messages.GLOBAL_MESSAGES.SUBSCRIPTION_CREATED);
                    eventBus.$emit("reload_user_details");
                });
                return;
            }

            this.$http.get(api.actions.get_braintree_client_token).then((response) => {
                window.braintree.client.create(
                    {
                        authorization: response.data.client_token,
                    },
                    (clientErr, clientInstance) => {
                        if (clientErr) {
                            return;
                        }

                        window.braintree.threeDSecure.create(
                            {
                                version: 2, // Will use 3DS 2 whenever possible
                                client: clientInstance,
                            },
                            (threeDSecureErr, threeDSecureInstance) => {
                                if (threeDSecureErr) {
                                    this.loading = false;
                                    return;
                                }

                                this.threeDSecure = threeDSecureInstance;
                                var amount =
                                    this.billingCycle == "monthly"
                                        ? (this.subscriptionUsersNum * this.subscriptionPrice).toFixed(2)
                                        : (this.subscriptionUsersNum * this.subscriptionPriceYear).toFixed(2);

                                this.$http.get(api.actions.get_braintree_customer_card_nonce).then((response) => {
                                    var threeDSecureParameters = {
                                        amount: amount,
                                        nonce: response.data.result.paymentMethodNonce.nonce,
                                        bin: response.data.result.paymentMethodNonce.details.bin,
                                        email: this.adminDetails.user.email,
                                        onLookupComplete: function (data, next) {
                                            next();
                                        },
                                    };

                                    this.threeDSecure.verifyCard(threeDSecureParameters, (err, response) => {
                                        if (err) {
                                            this.loading = false;
                                            return;
                                        }

                                        data.payment_method_nonce = response.nonce;
                                        this.$http
                                            .post(api.actions.save_braintree_subscription, data)
                                            .then((response) => {
                                                this.loading = false;

                                                if (response.data.error == true) {
                                                    return;
                                                }

                                                this.saveTeamName();
                                                messages.successMessage(messages.GLOBAL_MESSAGES.SUBSCRIPTION_CREATED);
                                                eventBus.$emit("reload_user_details");
                                            });
                                    });
                                });
                            }
                        );
                    }
                );
            });
        },
        cancelBraintreeSubscription() {
            this.$swal({
                title: "Are you sure you want to cancel the subscription?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).then((result) => {
                if (result.value) {
                    this.$http.post(api.actions.cancel_braintree_subscription, {}).then(function (response) {
                        if (response.data.error == true) {
                            return;
                        }

                        eventBus.$emit("reload_user_details");
                        messages.successMessage(messages.GLOBAL_MESSAGES.SUBSCRIPTION_CANCELED);
                    });
                }
            });
        },

        resetMemberObj: function () {
            this.editMemberObj = {
                app_id: this.generalOptions.app_id,
                full_name: "",
                email: "",
                phone: "",
                id: 0,
            };
            this.displayNewMemberForm = false;
        },
        onSaveMember: function () {
            if (this.editMemberObj.id == 0) {
                this.checkExistingTeamMemberUser().then((response) => {
                    if (response.data.exists == true) {
                        this.inviteEmail = this.editMemberObj.email;
                        this.invitePhoneNumber = this.editMemberObj.phone;
                        this.sendInvite();
                        return;
                    }

                    this.saveNewTeamMember();
                });
            } else {
                this.updateTeamMember();
            }
        },
        checkExistingTeamMemberUser: function () {
            var data = {
                phone: this.editMemberObj.phone,
                email: this.editMemberObj.email,
            };

            return this.$http.post(api.actions.verify_existing_user, data);
        },
        saveNewTeamMember: function () {
            this.loading = true;

            this.$http.post(api.actions.add_new_group_member, this.editMemberObj).then((response) => {
                this.loading = false;

                if (response.data.error == true) {
                    return;
                }

                if (this.editMemberObj.sendInvite) {
                    this.inviteEmail = this.editMemberObj.email;
                    this.sendInvite();
                }

                this.displayNewMemberForm = false;
                this.getStylistDetails();
                this.getGroupsMembers(this.adminDetails.group_id);
                this.resetMemberObj();

                let message = "";
                if (response.data.updated_subscription == true) {
                    message =
                        ". " +
                        messages.GLOBAL_MESSAGES.SUBSCRIPTION_UPDATED.replace(
                            ":num",
                            response.data.active_members_number
                        );
                }

                messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE + message);
            });
        },
        updateTeamMember: function () {
            this.loading = true;

            this.$http.post(api.actions.update_group_member, this.editMemberObj).then((response) => {
                this.loading = false;

                if (response.data.error == true) {
                    return;
                }

                if (this.editMemberObj.sendInvite) {
                    this.inviteEmail = this.editMemberObj.email;
                    this.sendInvite();
                }

                this.displayNewMemberForm = false;
                this.getStylistDetails();
                this.getGroupsMembers(this.adminDetails.group_id);
                this.resetMemberObj();

                messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
            });
        },
        editTeamMember: function (member) {
            this.editMemberObj["full_name"] = member.first_name + " " + member.last_name;
            this.editMemberObj["email"] = member.email;
            this.editMemberObj["phone"] = member.phone;
            this.editMemberObj["id"] = member.id;
            this.displayNewMemberForm = true;
        },
        resendEmailInvite: function (member) {
            if (
                typeof member == "undefined" ||
                typeof member.user == "undefined" ||
                typeof member.user.email == "undefined" ||
                member.user.email == ""
            ) {
                return;
            }
            let email = member.user.email;
            this.inviteEmail = email;
            this.sendInvite();
        },
        resendPhoneInvite: function (member) {
            if (
                typeof member == "undefined" ||
                typeof member.user == "undefined" ||
                typeof member.user.phone == "undefined" ||
                member.user.phone == ""
            ) {
                return;
            }
            let phone = member.user.phone;
            this.invitePhoneNumber = phone;
            let app_id = this.generalOptions.app_id;
            let message = this.user.first_name + " " + messages.INVITE_TEXTS[app_id];
            this.sendSMS(this.invitePhoneNumber, message);
        },
        sendInvite: function () {
            let data = {
                phone: this.invitePhoneNumber,
                group_id: this.adminDetails.group_id,
                email: this.inviteEmail,
                app_id: this.generalOptions.app_id,
            };

            this.$http.post(api.actions.send_invite, data).then(
                function (response) {
                    if (response.data.error != true) {
                        this.inviteEmail = "";
                        this.invite = false;
                        this.getGroupsMembers(this.adminDetails.group_id);
                        messages.successMessage(messages.GLOBAL_MESSAGES.INVITATION_SENT);

                        if (this.invitePhoneNumber) {
                            let app_id = this.generalOptions.app_id;
                            let message = this.user.first_name + " " + messages.INVITE_TEXTS[app_id];
                            this.sendSMS(this.invitePhoneNumber, message);
                        }
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        sendSMS: function (phone, text) {
            let data = {
                phone: phone,
                text: text,
                email: "",
            };

            this.$http.post(api.actions.send_sms, data).then(function () {
                this.invitePhoneNumber = "";
            });
        },
        toggleInvite: function () {
            this.invite = !this.invite;
            if (!this.invite) {
                this.invitePhoneNumber = "";
                this.inviteEmail = "";
            }
        },
        filterPhone: function () {
            this.invitePhoneNumber = this.invitePhoneNumber.replace(/[^\d]/, "");
        },
        saveTeamName: function () {
            let data = {
                name: this.adminDetails.group_name,
            };

            this.$http.post(api.actions.save_stylist_group_name, data).then(
                function (response) {
                    if (response.data.error != true) {
                        this.editTeamName = false;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        isDiscuntActive(subscription) {
            if (!subscription.discount_id) {
                return false;
            }

            let time = moment(subscription.created_at, "YYYY-MM-DD hh:mm:ss").unix() + 3600 * 24 * 365;
            let currentTime = moment().unix();

            if (currentTime > time) {
                return false;
            }

            return true;
        },
        dateCardAddedMonthDayYear(cardDate) {
            // Convert the created_at time to local and format it
            const formattedDay = moment.utc(cardDate).local().format("MMMM D YYYY");

            return formattedDay;
        },
        dateCardAddedHour(cardDate) {
            // Convert the created_at time to local and format it
            const formattedHour = moment.utc(cardDate).local().format("hh:mm A");

            return formattedHour;
        },


    },
};
</script>
