<template>
    <div class="client-book-component">
        <div class="book-sections">
            <h3 class="section-title">Select Services</h3>
            <div class="book-section-services section-wrap" v-if="currentSection == 1">
                <div class="service-categories">
                    <div v-for="(category, i) in serviceCategories" v-bind:key="i">
                        <div v-if="services[category].length" class="section-wrap no-shadow">
                            <span class="category-name">{{ category }}</span>
                            <div class="services">
                                <div
                                    class="services-inner section-wrap no-shadow"
                                    v-for="(service, i) in services[category]"
                                    v-bind:key="i"
                                >
                                    <label v-bind:for="service.id" class="day-label">{{ service.name }}</label>
                                    <div class="service-main-wrapper">
                                        <div class="service-item-wrap">
                                            <div class="service-meta-wrap">
                                                <div v-if="service.image" class="service-image">
                                                    <img
                                                        width="50"
                                                        :src="getProductImage(service.image, service.user_id)"
                                                    />
                                                </div>

                                                <div class="service-price-duration">
                                                    <span>
                                                        {{
                                                            currency == "USD" || currency == "usd" ? "$" : currency
                                                        }}</span
                                                    >
                                                    <span
                                                        >{{ service.price }}
                                                        {{ service.price_and_up == 1 ? "and up" : "" }}</span
                                                    >
                                                    <br />
                                                    <span v-if="service.is_product === 0">
                                                        {{ service.duration | hourMinutesFormat }}</span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="qty-and-switch-wrap">
                                            <div
                                                class="modal-appt-service-select-wrap"
                                                v-if="bookData.services.indexOf(service.id) !== -1"
                                            >
                                                <select
                                                    v-model="service.qty"
                                                    class="selectInput selectInputExtraSmall"
                                                    @change="getTotalServicesCount()"
                                                >
                                                    <option :value="num" v-for="(num, i) in 10" :key="`toggle-${i}`">
                                                        {{ num }}
                                                    </option>
                                                </select>
                                            </div>
                                            <label class="switch">
                                                <input
                                                    type="checkbox"
                                                    class="book-service"
                                                    v-model="bookData.services"
                                                    :id="service.id"
                                                    :value="service.id"
                                                    v-on:change="onSelectService(service)"
                                                />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="service-description">
                                        <text-toggle :text="service.description" stringLimit="50"></text-toggle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section-subtitle">
                    <span>{{ total_service_count }} selected services </span>
                    <span v-if="total_service_count > 0"
                        >({{ currency == "USD" ? "$" : currency }}{{ selectedServicesPrice }})
                        <span>{{ selectedServicesDuration | hourMinutesFormat }}</span>
                    </span>
                </div>
                <div class="next-button-wrap">
                    <div class="next-section-detail">
                        <div class="section-label">Select date</div>
                        <div class="section-label-2">3 steps to go</div>
                    </div>
                </div>
                <div class="back-button-wrap">
                    <div>
                        <span
                            v-on:click="
                                $router.push('/app/stylists');
                                scrollToTop();
                            "
                            class="primary-btn extra-small mobile change-display"
                            ><i class="fa fa-angle-left" aria-hidden="true"></i>&nbsp;Back</span
                        >
                    </div>
                    <div>
                        <div
                            type="button"
                            name="next"
                            class="primary-btn extra-small mobile"
                            :class="!bookData.services.length ? 'action-disabled' : ''"
                            v-on:click="
                                chooseSection(2, $event);
                                getAvailableDays();
                                scrollToTop();
                            "
                        >
                            Continue
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="book-section-services section-wrap" v-if="currentSection == 2">
                <div class="select-date-text">
                    <div class="section-subtitle">Select Date</div>
                    <div class="section-subtitle">{{ available_days_num }} available days</div>
                </div>
                <div class="date-picker-wrapper">
                    <div class="select-date">
                        <div class="datepicker-wrapper section-wrap no-shadow">
                            <datepicker
                                v-on:selected="onSelectDate"
                                v-on:changedMonth="getAvailableDays"
                                :value="bookData.date"
                                :inline="true"
                                :disabledDates="disabledDates"
                            ></datepicker>
                            <div class="loader-wrap" v-if="loadingUnavailabeDates">
                                <div class="loading-icon-wrap">
                                    <i class="loading-icon"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="next-button-wrap section-wrap no-shadow">
                        <div class="appointment-detail">
                            <span class="selected-services">{{ previewData.services.join(", ") }}</span>
                            <span v-if="previewData.date && isUsCountry() === false" class="selected-date">{{
                                previewData.date | dateFormat
                            }}</span>

                            <span v-if="previewData.date && isUsCountry() === true" class="selected-date">{{
                                previewData.date | dateFormatUS
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="next-back-container">
                    <div class="next-section-detail">
                        <div class="section-label">Select time</div>
                        <div class="section-label-2">2 steps to go</div>
                    </div>
                    <div class="back-button-wrap">
                        <div>
                            <span
                                v-on:click="
                                    chooseSection(1, $event);
                                    scrollToTop();
                                "
                                class="primary-btn extra-small mobile change-display"
                                ><i class="fa fa-angle-left" aria-hidden="true"></i>&nbsp;Back</span
                            >
                        </div>
                        <div>
                            <button
                                type="button"
                                name="next"
                                class="primary-btn extra-small mobile"
                                :disabled="!bookData.date"
                                v-on:click="
                                    chooseSection(3, $event);
                                    scrollToTop();
                                "
                            >
                                Continue
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="book-section-services section-wrap" v-if="currentSection == 3">
                <div class="select-date-text">
                    <div class="section-subtitle">Select Time</div>
                    <div class="section-subtitle">{{ freeHours.length }} available hours</div>
                </div>
                <div class="date-picker-wrapper">
                    <div class="select-time section-wrap no-shadow">
                        <div class="book-time-slots">
                            <span
                                class="time-slot"
                                v-for="(slot, index) in freeHours"
                                v-bind:key="index"
                                v-bind:class="{ selected: bookData.hour == slot }"
                                v-on:click="onSelectTime(slot)"
                                >{{ slot }}</span
                            >
                        </div>
                        <div class="fully-booked" v-if="freeHoursLoaded && freeHours.length == 0">
                            {{ $ml.get("book.fullyBooked") }}
                        </div>
                    </div>
                    <div class="next-button-wrap section-wrap no-shadow">
                        <div class="appointment-detail">
                            <span class="selected-services">{{ previewData.services.join(", ") }}</span>
                            <span v-if="previewData.date && isUsCountry() === false" class="selected-date">{{
                                previewData.date | dateFormat
                            }}</span>

                            <span v-if="previewData.date && isUsCountry() === true" class="selected-date">{{
                                previewData.date | dateFormatUS
                            }}</span>
                            <span v-if="bookData.hour" class="selected-hour">, {{ bookData.hour }}</span>
                        </div>
                    </div>
                </div>
                <div class="next-section-detail">
                    <div class="section-label">Confirmation</div>
                    <div class="section-label-2">1 step to go</div>
                </div>
                <div class="back-button-wrap">
                    <div>
                        <span
                            v-on:click="
                                chooseSection(2, $event);
                                scrollToTop();
                            "
                            class="primary-btn extra-small mobile change-display"
                            ><i class="fa fa-angle-left" aria-hidden="true"></i>&nbsp;Back</span
                        >
                    </div>
                    <div>
                        <button
                            type="button"
                            name="next"
                            class="primary-btn extra-small mobile"
                            :disabled="!bookData.hour || !policy_checked"
                            v-on:click="
                                chooseSection(4, $event);
                                scrollToTop();
                                checkUserCard();
                            "
                        >
                            Continue
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div
                class="book-section-services section-wrap"
                v-if="
                    currentSection == 4 &&
                    profile.policy &&
                    !profile.policy.agree &&
                    (profile.policy.deposit == 1 || profile.policy.cancel == 1 || profile.policy.reschedule == 1)
                "
            >
                <div class="select-date-text">
                    <div class="section-subtitle">Policy</div>
                    <div class="section-subtitle">Confirm stylist policy</div>
                </div>
                <div class="policy section-wrap">
                    <div class="policy-note">
                        <div class="policy-summary-container section-wrap">
                            <div class="policy-heading">This is a summary of what you have enabled</div>
                            <div class="policy-items-container section-wrap">
                                <div v-if="profile.policy.deposit == 1" class="policy-item">Deposit</div>
                                <div v-if="profile.policy.cancel == 1" class="policy-item">Cancel Fee</div>
                                <div v-if="profile.policy.reschedule == 1" class="policy-item">Reschedule Fee</div>
                            </div>
                        </div>
                        <div
                            v-if="profile.policy.deposit == 1 && profile.policy.deposit_fee != 0"
                            class="policy-summary-container section-wrap"
                        >
                            <div class="policy-heading">Deposit is mandatory and is Non-Refundable.</div>
                            <div class="policy-item section-wrap">
                                <b>{{ currency == "USD" || currency == "usd" ? "$" : currency }} {{ deposit_fee }}</b>
                                deposit is required.
                            </div>
                        </div>
                    </div>
                    <div class="next-button-wrap section-wrap">
                        <div class="appointment-detail">
                            <span class="selected-services">{{ previewData.services.join(", ") }}</span>
                            <span v-if="previewData.date && isUsCountry() === false" class="selected-date">{{
                                previewData.date | dateFormat
                            }}</span>

                            <span v-if="previewData.date && isUsCountry() === true" class="selected-date">{{
                                previewData.date | dateFormatUS
                            }}</span>
                            <span v-if="bookData.hour" class="selected-hour">, {{ bookData.hour }}</span>
                        </div>
                    </div>
                </div>

                <div class="next-section-detail">
                    <div class="section-label">Confirmation</div>
                    <div class="section-label-2">1 step to go</div>
                </div>
                <div class="back-button-wrap">
                    <div>
                        <span
                            v-on:click="
                                chooseSection(3, $event);
                                scrollToTop();
                            "
                            class="primary-btn extra-small mobile change-display"
                            ><i class="fa fa-angle-left" aria-hidden="true"></i>&nbsp;Back</span
                        >
                    </div>

                    <div>
                        <button
                            type="button"
                            name="next"
                            class="primary-btn extra-small mobile"
                            :disabled="loading"
                            v-on:click="agreeStylistPolicy"
                        >
                            I Agree
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div
                class="book-section-services section-wrap"
                v-if="
                    currentSection == 4 &&
                    profile.policy &&
                    (profile.policy.deposit == 0 || (profile.policy.deposit == 1 && profile.policy.agree)) &&
                    (profile.policy.cancel == 0 || (profile.policy.cancel == 1 && profile.policy.agree)) &&
                    (profile.policy.reschedule == 0 || (profile.policy.reschedule == 1 && profile.policy.agree))
                "
            >
                <div class="select-date-text">
                    <div class="section-subtitle">Confirmation</div>
                    <div class="section-subtitle">Confirm your booking</div>
                </div>
                <div class="confirmation section-wrap no-shadow">
                    <h5>Policy</h5>
                    <div class="price-wrap section-wrap no-shadow">
                        <div class="total-price-label">Total estimated price for this appointment is</div>
                        <div class="total-price">
                            {{ currency == "USD" || currency == "usd" ? "$" : currency }}{{ selectedServicesPrice }}
                        </div>
                    </div>
                    <div
                        v-if="hasPolicyEnabled && ((profile.logged_in && !has_card) || profile.policy) && !has_card"
                        class="policy-note section-wrap no-shadow"
                    >
                        <div v-if="profile.logged_in">
                            <h4>
                                {{ profile.first_name }}
                                {{ profile.last_name }} requires a credit card to hold this reservation
                            </h4>
                            <p v-if="profile.policy.deposit == 0 || profile.policy.deposit_fee == 0">
                                Your card will not be charged, services payment will be collected after rendering the
                                services.
                            </p>
                            <p v-if="profile.policy.deposit != 0 && profile.policy.deposit_fee != 0">
                                Your card will be charged with the deposit fee:
                                <strong
                                    >{{ currency == "USD" || currency == "usd" ? "$" : currency
                                    }}{{ deposit_fee }}</strong
                                >
                            </p>

                            <div v-if="profile.accept_stripe" class="card-form" ref="card_form"></div>
                            <div
                                v-if="profile.accept_square && !profile.accept_stripe"
                                class="card-form card-form-square"
                                ref="card_form_square"
                            >
                                <div id="card-container"></div>
                            </div>
                            <div class="card-logos">
                                <img class="logos" src="../../assets/images/card-logos.png" />
                                <img class="verified" src="../../assets/images/verified-secured.jpg" />
                            </div>
                            <div class="card-errors">{{ cards_errors }}</div>
                        </div>
                        <div v-if="profile.policy && profile.policy.cancel == 1 && profile.policy.cancel_fee > 0">
                            <h4>Cancellation Policy</h4>
                            <p>
                                You will not be charged if you cancel at least
                                {{ profile.policy.cancel_advance_hours }} hours before your appointment starts.
                                Otherwise, you will be charged up to {{ profile.policy.cancel_fee }}% of the services
                                booked.
                            </p>
                        </div>
                    </div>
                    <div class="policy-wrapper section-wrap no-shadow">
                        <!--Deposits fees-->
                        <div class="policy-secound-wrapper" v-if="profile.policy.deposit === 1">
                            <h6>Deposits</h6>
                            <div class="policy-wrappper-inner">
                                <div class="section-wrap no-shadow">
                                    <span>
                                        <span v-if="profile.policy.deposit_type === 'fixed'">$</span
                                        >{{ profile.policy.deposit_fee
                                        }}<span v-if="profile.policy.deposit_type !== 'fixed'">%</span> non refundable
                                        deposit</span
                                    >

                                    <div class="policy-inner-text" v-if="profile.policy.deposit_policy.length > 0">
                                        <p>Deposit Policy</p>
                                        <div class="section-wrap no-shadow">
                                            <span>
                                                <text-toggle
                                                    :text="profile.policy.deposit_policy"
                                                    stringLimit="100"
                                                ></text-toggle>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Cancellation / No Show fees-->
                        <div
                            class="policy-secound-wrapper"
                            v-if="
                                (profile.policy.cancel === 1 && profile.policy.cancel_fee > 0) ||
                                (profile.policy.cancel === 1 && profile.policy.noshow_fee > 0) ||
                                (profile.policy.cancel === 1 && profile.policy.cancel_policy.length > 0)
                            "
                        >
                            <h6>Cancellation / No Show</h6>
                            <div class="policy-wrappper-inner">
                                <div
                                    class="section-wrap no-shadow"
                                    v-if="profile.policy.cancel_fee > 0 || profile.policy.noshow_fee > 0"
                                >
                                    <p v-if="profile.policy.cancel_fee > 0">
                                        {{ profile.policy.cancel_fee }}% fees within
                                        {{ profile.policy.cancel_advance_hours }} hours
                                    </p>
                                    <p v-if="profile.policy.noshow_fee > 0">
                                        {{ profile.policy.noshow_fee }}% fees for No Show
                                    </p>
                                    <div class="policy-inner-text" v-if="profile.policy.cancel_policy.length > 0">
                                        <p>Cancellation Policy</p>
                                        <div class="section-wrap no-shadow">
                                            <span>
                                                <text-toggle
                                                    :text="profile.policy.cancel_policy"
                                                    stringLimit="100"
                                                ></text-toggle>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Reschedule fees-->
                        <div class="policy-secound-wrapper" v-if="profile.policy.reschedule === 1">
                            <h6>Reschedule</h6>
                            <div class="policy-wrappper-inner">
                                <div class="section-wrap no-shadow">
                                    <p>
                                        {{ profile.policy.reschedule_fee }}% fees within
                                        {{ profile.policy.reschedule_advance_hours }} hours
                                    </p>
                                    <div class="policy-inner-text">
                                        <p>Reschedule Policy</p>
                                        <div class="section-wrap no-shadow">
                                            <span
                                                ><text-toggle
                                                    :text="profile.policy.reschedule_policy"
                                                    stringLimit="100"
                                                ></text-toggle>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Being late fees-->
                        <div
                            class="policy-secound-wrapper"
                            v-if="
                                (profile.policy.cancel === 1 &&
                                    profile.policy.being_late === 1 &&
                                    profile.policy.late_minutes > 0) ||
                                (profile.policy.cancel === 1 &&
                                    profile.policy.being_late === 1 &&
                                    profile.policy.late_fee > 0) ||
                                (profile.policy.cancel === 1 &&
                                    profile.policy.being_late === 1 &&
                                    profile.policy.late_minutes_cancel > 0)
                            "
                        >
                            <h6>Being late</h6>
                            <div class="policy-wrappper-inner">
                                <div class="section-wrap no-shadow">
                                    <p v-if="profile.policy.late_minutes > 0 && profile.policy.late_fee > 0">
                                        Late {{ profile.policy.late_minutes }} min ${{ profile.policy.late_fee }} late
                                        fee
                                    </p>
                                    <p v-if="profile.policy.late_minutes_cancel > 0">
                                        After {{ profile.policy.late_minutes_cancel }} min CANCELLED NO EXCEPTIONS
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!--Age limit-->
                        <div class="policy-secound-wrapper">
                            <h6>Age</h6>
                            <div class="policy-wrappper-inner">
                                <div class="section-wrap no-shadow">
                                    <p>Only ages {{ profile.policy.age_limit }} and up only are allowed.</p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="section-wrap">
                            <div>Deposit</div>
                            <div class="section-wrap">{{ profile.policy }} deposit is required</div>
                        </div> -->
                    </div>
                    <div class="reminders-wrap section-wrap no-shadow">
                        <div class="reminders-title">Reminders (days before appointment)</div>
                        <div class="reminders-options section-wrap no-shadow">
                            <div class="reminder-option">
                                <div class="label">1 day</div>
                                <label class="switch"
                                    ><input
                                        type="checkbox"
                                        v-model="bookData.reminders"
                                        v-bind:value="1"
                                        class="appt-reminder" /><span class="slider round"></span
                                ></label>
                            </div>
                            <div class="reminder-option">
                                <div class="label">3 days</div>
                                <label class="switch"
                                    ><input
                                        type="checkbox"
                                        v-model="bookData.reminders"
                                        v-bind:value="3"
                                        class="appt-reminder" /><span class="slider round"></span
                                ></label>
                            </div>
                            <div class="reminder-option">
                                <div class="label">5 days</div>
                                <label class="switch"
                                    ><input
                                        type="checkbox"
                                        v-model="bookData.reminders"
                                        v-bind:value="4"
                                        class="appt-reminder" /><span class="slider round"></span
                                ></label>
                            </div>
                            <div class="reminder-option">
                                <div class="label">10 days</div>
                                <label class="switch"
                                    ><input
                                        type="checkbox"
                                        v-model="bookData.reminders"
                                        v-bind:value="10"
                                        class="appt-reminder" /><span class="slider round"></span
                                ></label>
                            </div>
                        </div>
                    </div>
                    <div class="next-button-wrap section-wrap no-shadow">
                        <div class="appointment-detail">
                            <span class="selected-services">{{ previewData.services.join(", ") }}</span>
                            <span v-if="previewData.date && isUsCountry() === false" class="selected-date">{{
                                previewData.date | dateFormat
                            }}</span>

                            <span v-if="previewData.date && isUsCountry() === true" class="selected-date">{{
                                previewData.date | dateFormatUS
                            }}</span>
                            <span v-if="bookData.hour" class="selected-hour">, {{ bookData.hour }}</span>
                            <div v-if="!bookData.id && deposit_fee > 0 && deposit_fee > 0" class="deposit-fee">
                                Deposit:
                                <b>{{ currency == "USD" || currency == "usd" ? "$" : currency }} {{ deposit_fee }}</b>
                            </div>
                            <div
                                v-if="bookData.id && bookData.reschedule > 0 && reschedule_fee > 0"
                                class="deposit-fee"
                            >
                                Reschedule:
                                <b
                                    >{{ currency == "USD" || currency == "usd" ? "$" : currency }}
                                    {{ reschedule_fee }}</b
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        class="client-signature-wrap"
                        v-if="hasPolicyEnabled && ((profile.logged_in && !has_card) || profile.policy) && !has_card"
                    >
                        <div class="signature-wrapper">
                            <h6>
                                Sign to authorize
                                <span v-if="!bookData.id && deposit_fee > 0 && deposit_fee > 0" class="deposit-fee">
                                    <b
                                        >{{ currency == "USD" || currency == "usd" ? "$" : currency }}
                                        {{ deposit_fee }}</b
                                    >
                                </span>
                                <span
                                    v-if="bookData.id && bookData.reschedule > 0 && reschedule_fee > 0"
                                    class="deposit-fee"
                                >
                                    <b
                                        >{{ currency == "USD" || currency == "usd" ? "$" : currency }}
                                        {{ reschedule_fee }}</b
                                    >
                                </span>
                                payment.
                            </h6>
                            <div class="client-signature">
                                <div class="signature-container">
                                    <vueSignature
                                        class="signature"
                                        ref="signature"
                                        :sigOption="signatureOption"
                                        :w="'100%'"
                                        :h="'150px'"
                                        @endStroke="signatureIsEmpty = false"
                                    ></vueSignature>
                                </div>
                                <div class="signature-btns-container">
                                    <button class="secondary-btn small mobile" @click="clearSignature">Clear</button>
                                    <button class="secondary-btn small mobile" @click="undoSignature">Undo</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="back-button-wrap">
                        <div>
                            <span
                                v-if="!loading"
                                v-on:click="goBack(3, $event)"
                                class="primary-btn extra-small mobile change-display"
                                ><i class="fa fa-angle-left" aria-hidden="true"></i>&nbsp;Back</span
                            >
                        </div>
                        <div>
                            <button
                                v-if="!bookData.id"
                                type="button"
                                name="next"
                                class="primary-btn extra-small mobile"
                                :disabled="loading || (signatureIsEmpty && !has_card)"
                                v-on:click="handleSaveAppointment"
                                :class="loading || (signatureIsEmpty && !has_card) ? 'transaction-disabled' : ''"
                            >
                                Book Now
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </button>
                            <button
                                v-if="bookData.id && bookData.reschedule"
                                type="button"
                                name="next"
                                class="primary-btn extra-small mobile"
                                :disabled="loading"
                                v-on:click="handleRescheduleAppointment"
                            >
                                Book Now
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="modal fade"
                id="payment-methods-modal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="event-modal"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title m-auto">Select Payment Method</h5>
                        </div>
                        <div class="modal-body cards-signature-wrapper">
                            <div class="cards-wrap">
                                <div v-for="(card, i) in stripe_cards" :key="`stripe-card${i}`" class="card-item">
                                    <input
                                        type="radio"
                                        name="payment-method"
                                        :id="`card-stripe-${i}`"
                                        v-model="selected_card"
                                        :value="card.id"
                                    />
                                    <label :for="`card-stripe-${i}`">
                                        <div class="card-last4">**** **** **** {{ card.card.last4 }}</div>
                                        <div class="card-brand">
                                            {{ card.card.brand }}
                                        </div>
                                    </label>
                                </div>
                                <div v-for="(card, i) in square_cards" :key="`square-card${i}`" class="card-item">
                                    <input
                                        type="radio"
                                        class="square-radio-checked"
                                        name="payment-method"
                                        :id="`card-square-${i}`"
                                        v-model="selected_card"
                                        :value="card.id"
                                    />
                                    <label :for="`card-square-${i}`">
                                        <div class="card-last4">**** **** **** {{ card.last_4 }}</div>
                                        <div class="card-brand">
                                            {{ card.card_brand }}
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div class="client-signature-wrap">
                                <div class="signature-wrapper">
                                    <h6>
                                        Sign to authorize
                                        <span
                                            v-if="!bookData.id && deposit_fee > 0 && deposit_fee > 0"
                                            class="deposit-fee"
                                        >
                                            <b
                                                >{{ currency == "USD" || currency == "usd" ? "$" : currency }}
                                                {{ deposit_fee }}</b
                                            >
                                        </span>
                                        <span
                                            v-if="bookData.id && bookData.reschedule > 0 && reschedule_fee > 0"
                                            class="deposit-fee"
                                        >
                                            <b
                                                >{{ currency == "USD" || currency == "usd" ? "$" : currency }}
                                                {{ reschedule_fee }}</b
                                            >
                                        </span>
                                        payment.
                                    </h6>
                                    <div class="client-signature">
                                        <div class="signature-container">
                                            <vueSignature
                                                class="signature"
                                                ref="signature"
                                                :sigOption="signatureOption"
                                                :w="'465px'"
                                                :h="'150px'"
                                            ></vueSignature>
                                        </div>
                                        <div class="signature-btns-container">
                                            <button class="secondary-btn small mobile" @click="clearSignature">
                                                Clear
                                            </button>
                                            <button class="secondary-btn small mobile" @click="undoSignature">
                                                Undo
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                                v-on:click="saveAppointment()"
                                type="button"
                                class="primary-btn extra-small mobile transaction-disabled"
                                :disabled="loading || ($refs.signature && $refs.signature.isEmpty())"
                            >
                                <span v-if="!loading">Choose</span>
                                <span v-if="loading">Saving...</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import "bootstrap";
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import messages from "./../../services/messages.js";
import eventBus from "./../../services/event_bus.js";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import $ from "jquery";
import TextToggle from "./text_toggle.vue";
import vueSignature from "vue-signature";

export default {
    name: "ClientBook",
    data: function () {
        return {
            user: null,
            additionalServices: [],
            servicesMap: {},
            groupName: "",
            groupLocation: "",
            sections: [1, 2, 3, 4, 5],
            weekDays: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
            disabledDates: {
                to: new Date(Date.now() - 8640000),
                dates: [],
            },
            exceptionDays: [],
            freeHoursLoaded: false,
            freeStylists: [],
            showTimeSelection: false,
            serviceEnds: null,
            selectedServicesPrice: 0,
            depositPriceLine: "",
            unavailableDates: [],
            currency: "USD",
            unavailable_service_days: [],
            default_disabled_days: [],
            has_card: true,
            stripe: null,
            stripe_card: null,
            square_card: null,
            cards_errors: "",
            stripe_client_secret: "",
            getting_stripe_client_secret: false,
            square_application_id: "",
            suqare_location_id: "",
            square_card_obj: null,
            paypal_client_token: "",
            paypal_account: null,
            paypal_environment: "",
            deposit_fee: 0,
            deposit_fee_order_id: 0,
            last_appointment_id: 0,
            loading: false,
            profile: {},
            client_id: 0,
            stylist_alias: "",
            available_days_num: 0,
            policy_checked: true,
            stripe_cards: [],
            square_cards: [],
            card_payment_processor: "",
            selected_card: "",
            loadingUnavailabeDates: false,
            total_service_count: 0,
            country_id: 0,
            reschedule_fee: 0,
            signatureOption: {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "rgb(255,255,255)",
            },
            signatureIsEmpty: true,
            cards_square_errors: false,
            signature_img: "",
            square_form_displayed: false,
        };
    },
    mounted: function () {
        this.user = auth.user;
        this.client_id = this.user.id;

        if (auth.user && auth.user.role == "stylist") {
            this.$router.push("/app");
        }

        this.stylist_alias = this.$route.params.alias;

        if (this.bookData.stylist_id) {
            this.getStylistDetails(this.bookData.stylist_id);
        } else {
            this.checkStylistAlias(this.stylist_alias);
        }
    },
    beforeDestroy() {
        eventBus.$off("user_profile_loaded", this.setUserDetails);
        eventBus.$off("user_logged_out", this.setUserDetails);
    },
    computed: {
        currentSection: {
            get: function () {
                return store.state.currentBookSection;
            },
            set: function (value) {
                store.commit("setBookSection", value);
            },
        },
        bookData: {
            get: function () {
                return store.state.bookData;
            },
            set: function (value) {
                store.commit("setBookData", value);
            },
        },
        serviceCategories: {
            get: function () {
                return store.state.serviceCategories;
            },
            set: function (value) {
                store.commit("setServiceCategories", value);
            },
        },
        services: {
            get: function () {
                return store.state.services;
            },
            set: function (value) {
                store.commit("setServices", value);
            },
        },
        freeHours: {
            get: function () {
                return store.state.freeHours;
            },
            set: function (value) {
                store.commit("setFreeHours", value);
            },
        },
        previewData: {
            get: function () {
                return store.state.previewData;
            },
            set: function (value) {
                store.commit("setPreviewData", value);
            },
        },
        stylists: {
            get: function () {
                return store.state.stylists;
            },
            set: function (value) {
                store.commit("setStylists", value);
            },
        },
        selectedStylist: {
            get: function () {
                return store.state.selectedStylist;
            },
            set: function (value) {
                store.commit("setSelectedStylist", value);
            },
        },
        isUserGroup: {
            get: function () {
                return store.state.isUserGroup;
            },
            set: function (value) {
                store.commit("setIsUserGroup", value);
            },
        },
        selectedServicesDuration: {
            get: function () {
                return store.state.selectedServicesDuration;
            },
            set: function (value) {
                store.commit("setSelectedServicesDuration", value);
            },
        },
    },
    methods: {
        setUserDetails: function () {
            this.user = auth.user;
        },
        checkStylistAlias: function (alias) {
            let data = {
                alias: alias,
            };

            this.$http.post(api.actions.get_stylist_by_alias, data).then(
                function (response) {
                    if (typeof response.data.user_id != "undefined") {
                        this.getStylistDetails(response.data.user_id);
                    } else {
                        this.$router.push("/");
                    }
                }.bind(this)
            );
        },
        getStylistDetails: function (id) {
            this.$http.get(api.actions.get_stylist_details + id).then(
                function (response) {
                    if (typeof response.data.details != "undefined") {
                        //verify if the stylist has disabled the client booking
                        if (response.data.details.work_settings.prevent_clients_book == 1) {
                            this.$router.push("/app/stylists");
                            return;
                        }

                        //get the currency
                        if (response.data.details.profile.currency) {
                            this.currency = response.data.details.profile.currency;
                        }

                        this.country_id = response.data.details.profile.country_id;

                        this.exceptionDays = response.data.details.exception_days;
                        let self = this;
                        let user = response.data.details;
                        let services_array = [];
                        this.members = [user];
                        this.stylists = [user];
                        this.selectedStylist = user;
                        this.bookData.stylist_id = this.selectedStylist.user.id;
                        if (this.bookData.id === 0 && !this.bookData.book_again) {
                            this.bookData.services = [];
                        }
                        this.bookData.date = "";
                        this.bookData.hour = "";
                        this.previewData.stylistName = user.user.first_name + " " + user.user.last_name;

                        user.service_categories.map(function (categ) {
                            if (self.serviceCategories.indexOf(categ.name) === -1) {
                                self.serviceCategories.push(categ.name);
                            }

                            self.services[categ.name] =
                                typeof self.services[categ.name] != "undefined" ? self.services[categ.name] : [];

                            if (typeof user.services[categ.id] != "undefined") {
                                self.services[categ.name] = _.uniqBy(
                                    _.concat(self.services[categ.name], user.services[categ.id]),
                                    "id"
                                );
                                services_array = _.uniqBy(_.concat(services_array, user.services[categ.id]), "id");
                            }
                            self.services[categ.name].forEach((service) => {
                                service.qty =
                                    typeof self.bookData.selected_service_qty !== "undefined" &&
                                    typeof self.bookData.selected_service_qty[service.id] !== "undefined"
                                        ? self.bookData.selected_service_qty[service.id]
                                        : 1;
                            });
                        });

                        services_array.forEach(function (el) {
                            self.servicesMap[el.id] = el;
                        });

                        self.disabledDates.dates = [];
                        user.free_days.forEach(function (date) {
                            self.disabledDates.dates.push(new Date(date));
                        });

                        var disabledWeekDays = [];
                        self.disabledDates.days = [];
                        this.weekDays.forEach(function (day, i) {
                            if (user.work_settings && user.work_settings[day] == 0) {
                                disabledWeekDays.push(i);
                            }
                        });

                        if (this.bookData.id !== 0) {
                            this.bookData.services.forEach(function (el, i) {
                                if (typeof self.servicesMap[el] !== "undefined") {
                                    self.previewData.services.push(self.servicesMap[el].name);
                                    self.selectedServicesDuration += self.servicesMap[el].duration;
                                    self.selectedServicesPrice += self.servicesMap[el].price;
                                } else {
                                    self.bookData.services.splice(i, 1);
                                }
                            });
                        }

                        this.default_disabled_days = self.disabledDates.days;

                        self.serviceCategories = _.sortBy(self.serviceCategories);

                        var available_future_days =
                            this.profile.work_settings && this.profile.work_settings.available_future_days > 0
                                ? this.profile.work_settings.available_future_days
                                : 365;
                        this.disabledDates.from = moment().add(available_future_days, "days").toDate();

                        this.disabledDates.customPredictor = function (date) {
                            let date_formatted = new Date(date).toISOString().split("T")[0];
                            if (
                                disabledWeekDays.indexOf(date.getDay()) != -1 &&
                                response.data.details.exception_days.indexOf(date_formatted) == -1
                            ) {
                                return true;
                            }
                        };

                        this.getStylistProfile(this.stylist_alias);
                    }
                }.bind(this)
            );
        },
        getStylistProfile(profile_alias) {
            this.$http.get(api.actions.get_stylist_profile + profile_alias).then((response) => {
                if (response.data.error == true) {
                    return;
                }

                this.profile = response.data.profile;
                this.getTotalServicesCount();
                this.reschedule_fee = this.clientReschedulePolicyFee();
            });
        },
        isServiceDisabled: function (service) {
            return (
                (service.restricted == 1 && service[this.bookData.week_day] != 1) ||
                (service.group_id == 0 && this.freeStylists.indexOf(service.user_id) !== -1)
            );
        },
        hasEnabledServices: function (services) {
            let enabled = false;

            services.forEach((service) => {
                if (!this.isServiceDisabled(service)) {
                    enabled = true;
                }
            });

            return enabled;
        },
        chooseSection: function (section) {
            if (this.sections.indexOf(section) === -1) {
                return;
            }

            this.currentSection = section;
        },
        onSelectDate: function (date) {
            this.bookData.date = date;
            this.previewData.date = date;
            this.bookData.week_day = this.weekDays[moment(date).day()];
            this.freeStylists = [];

            this.members.forEach((el) => {
                if (el.free_days.indexOf(this.bookData.date) !== -1) {
                    this.freeStylists.push(el.user.id);
                }
            });

            this.getStylistFreeHours(this.selectedStylist.user.id);
        },
        onSelectService: function (service) {
            if (service.restricted) {
                this.disabledDates.days = _.clone(this.default_disabled_days);
                this.weekDays.forEach((day, i) => {
                    if (service[day] == 0 && this.unavailable_service_days.indexOf(i) == -1) {
                        this.disabledDates.days.push(i);
                    }
                });
            }

            var disabledWeekDays = [];
            this.disabledDates.days.forEach((i) => {
                disabledWeekDays.push(i);
            });

            var self = this;

            this.selectedServicesDuration = 0;
            this.selectedServicesPrice = 0;
            this.previewData.services = [];
            this.bookData.services.forEach(function (el) {
                self.previewData.services.push(self.servicesMap[el].name);
                self.selectedServicesDuration += self.servicesMap[el].duration;
                self.selectedServicesPrice += self.servicesMap[el].price;
            });

            service.qty = 1;
            this.getUnavailableDates();
            this.getTotalServicesCount();
        },
        getUnavailableDates: function () {
            let data = {
                stylist_id: this.selectedStylist.user.id,
                services_duration: this.selectedServicesDuration,
                start_date: moment().subtract(1, "day").format("YYYY-MM-DD"),
            };
            this.loadingUnavailabeDates = true;
            this.$http.post(api.actions.get_unavailable_dates, data).then(
                function (response) {
                    this.loadingUnavailabeDates = false;
                    if (typeof response != "undefined") {
                        this.unavailableDates = response.body;
                    }
                    this.disabledDates.dates = [];
                    this.selectedStylist.free_days.forEach((date) => {
                        this.disabledDates.dates.push(new Date(date));
                    });
                    this.unavailableDates.forEach((unavailableDate) => {
                        this.disabledDates.dates.push(new Date(unavailableDate));
                    });
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        onSelectTime: function (slot) {
            this.bookData.hour = slot;

            let start = new Date(moment(this.previewData.date).format("YYYY-MM-DD") + " " + this.bookData.hour);
            let endDate = moment(start).add(this.selectedServicesDuration, "minutes");
            this.serviceEnds = endDate.format("hh:mm A");

            //get deposit data of the stylist
            this.getDeposit();
            this.getClientPolicyAgreement();
            this.checkUserLink();

            this.$forceUpdate();
        },
        getStylistFreeHours: function (id) {
            let current_datetime = moment().format("YYYY-MM-DD h:mm a");
            let formattedDate = moment(this.bookData.date).format("YYYY-MM-DD");
            let query =
                "?include_taken=1&stylist_id=" +
                id +
                "&date=" +
                formattedDate +
                "&services_duration=" +
                this.selectedServicesDuration +
                "&current_datetime=" +
                current_datetime +
                "&appointment_id=" +
                this.bookData.id;
            this.freeHoursLoaded = false;
            this.freeHours = [];

            this.$http.get(api.actions.get_stylist_free_hours + query).then(
                function (response) {
                    if (typeof response.data.free_hours != "undefined") {
                        this.freeHours = response.data.free_hours;
                        this.freeHoursLoaded = true;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getAvailableDays(firstOfMonth) {
            setTimeout(() => {
                this.available_days_num = $(".vdp-datepicker__calendar .cell.day:not(.disabled):not(.blank)").length;
            }, 200);
            let startDate = moment(firstOfMonth).format("YYYY-MM-DD");
            this.getUnavailableDates(startDate);
        },

        getDeposit: function () {
            let data = {
                user_id: this.selectedStylist.user_id ? this.selectedStylist.user_id : this.selectedStylist.user.id,
                client_id: this.user.id,
                price: this.selectedServicesPrice,
                appointment_id: this.bookData.id,
            };
            var self = this;
            this.$http.post(api.actions.get_deposit, data).then(
                function (response) {
                    if (response.data.error == false) {
                        if (response.data.zeroDeposit) {
                            this.depositPriceLine = "$0.00";
                            return;
                        }
                        self.depositPriceLine =
                            "$" + response.data.amount + " on " + moment(response.data.date).format("MMMM Do");
                        self.deposit_fee = response.data.amount;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        hasPolicyEnabled() {
            return (
                this.profile.policy &&
                ((this.profile.policy.cancel == 1 && this.profile.policy.cancel_fee > 0) ||
                    (this.profile.policy.deposit == 1 && this.profile.policy.deposit_fee > 0)) &&
                this.profile.accept_payments == 1 &&
                (this.profile.accept_stripe ||
                    this.profile.stripe_authorized ||
                    this.profile.accept_square ||
                    this.profile.square_authorized ||
                    this.profile.accept_paypal ||
                    this.profile.paypal_authorized)
            );
        },
        agreeStylistPolicy() {
            let data = {
                stylist_id: this.profile.id,
                client_id: this.client_id,
                agree: 1,
            };
            this.$http.post(api.actions.save_stylist_policy_agreement, data).then(
                function (response) {
                    if (response.data.error == false) {
                        this.getClientPolicyAgreement();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getClientPolicyAgreement() {
            this.policy_checked = false;
            let user_id = this.profile.id ? this.profile.id : this.selectedStylist.user.id;
            this.$http.get(api.actions.get_client_policy_agreement + user_id).then((response) => {
                this.policy_checked = true;
                if (response.error == true) {
                    return;
                }

                if (response.data.error == true) {
                    return;
                }

                this.profile.policy = response.data.policy;
                this.checkUserCard();
            });
        },
        checkUserCard() {
            if (!this.user || !this.hasPolicyEnabled()) {
                return;
            }

            var data = {};

            if (this.profile.accept_stripe || this.profile.stripe_authorized) {
                this.$http
                    .get(
                        api.actions.get_stripe_payment_methods +
                            "?user_id=" +
                            this.user.id +
                            "&stylist_id=" +
                            this.bookData.stylist_id
                    )
                    .then((response) => {
                        if (response.data.error) {
                            this.has_card = false;
                            this.displayCardForm();
                            return;
                        }

                        this.stripe_cards = response.data.payment_methods;
                        this.selected_card = "";
                        this.card_payment_processor = "stripe";

                        if (response.data.payment_methods.length > 0) {
                            this.selected_card = response.data.payment_methods[0].id;
                        }

                        if (!response.data.payment_methods.length > 0) {
                            this.has_card = false;
                            this.displayCardForm();
                        }
                    });
            } else if (this.profile.accept_square || this.profile.square_authorized) {
                data = {
                    stylist_id: this.profile.id,
                    client_id: this.client_id,
                };

                this.$http.post(api.actions.get_square_customer_cards, data).then((response) => {
                    if (response.data.error) {
                        return;
                    }
                    this.square_cards = response.data.response;
                    this.selected_card = "";
                    this.card_payment_processor = "square";

                    if (response.data.response.length) {
                        this.selected_card = response.data.response[0].id;
                    }

                    if (!response.data.response.length) {
                        this.has_card = false;

                        this.$http
                            .get(api.actions.get_square_api_credentials + "?stylist_id=" + this.profile.id)
                            .then((res) => {
                                this.square_application_id = res.data.response.application_id;
                                this.displayCardForm();
                            });
                    }
                });
            }
        },
        getStripeSetupIntent() {
            if (this.getting_stripe_client_secret) {
                return;
            }

            this.getting_stripe_client_secret = true;

            this.$http
                .post(api.actions.get_stripe_setup_intent, {
                    user_id: this.client_id,
                    stylist_id: this.profile.id,
                })
                .then((response) => {
                    if (response.error == true) {
                        return;
                    }

                    this.stripe_client_secret = response.data.client_secret;
                    this.getting_stripe_client_secret = false;
                });
        },
        displayCardForm() {
            if (this.profile.accept_payments == 0) {
                return;
            }

            let self = this;

            async function initializeCard(payments) {
                const card = await payments.card();
                await card.attach("#card-container");

                return card;
            }

            function loadSquareForm() {
                const payments = window.Square.payments(self.square_application_id, self.suqare_location_id);

                try {
                    self.square_card_obj = initializeCard(payments);
                } catch (e) {
                    self.cards_errors = "Initializing Card failed";
                    return;
                }
            }

            //Generate the Stripe card form
            if (this.profile.accept_stripe) {
                if (!this.$refs.card_form) {
                    setTimeout(() => {
                        this.displayCardForm();
                    }, 100);
                    return;
                }

                if (!this.profile.stripe_public_key) {
                    return;
                }

                this.stripe = window.Stripe(this.profile.stripe_public_key);
                var elements = this.stripe.elements();
                this.stripe_card = elements.create("card");
                this.stripe_card.mount(this.$refs.card_form);

                this.stripe_card.addEventListener("change", (event) => {
                    if (event.error) {
                        this.cards_errors = event.error.message;
                    } else {
                        this.cards_errors = "";
                    }
                });

                this.getStripeSetupIntent();

                //Generate the Square card Form
            } else if (this.profile.accept_square) {
                if (!this.$refs.card_form_square) {
                    setTimeout(() => {
                        this.displayCardForm();
                    }, 100);
                    return;
                }

                if (this.square_form_displayed) {
                    return;
                }

                this.square_form_displayed = true;

                this.$http
                    .post(api.actions.get_square_locations, {
                        stylist_id: this.profile.id,
                    })
                    .then((response) => {
                        if (response.data.error == true || !response.data.locations) {
                            this.cards_errors = "Error receiving the Square location";
                            return;
                        }

                        this.suqare_location_id = response.data.locations[0].id;
                        loadSquareForm();
                    });
            }
        },
        saveCard() {
            if (this.loading) {
                return;
            }

            async function tokenize(paymentMethod) {
                const tokenResult = await paymentMethod.tokenize();
                if (tokenResult.status === "OK") {
                    return tokenResult.token;
                }
            }

            //Save the card in Stripe
            if (this.profile.accept_payments && this.profile.accept_stripe) {
                this.stripe
                    .confirmCardSetup(this.stripe_client_secret, {
                        payment_method: {
                            card: this.stripe_card,
                            billing_details: {},
                        },
                    })
                    .then((result) => {
                        if (result.error) {
                            this.cards_errors = result.error.message;
                            return;
                        } else {
                            this.loading = false;
                            this.has_card = true;
                            this.$http.post(api.actions.save_stylist_policy_agreement, {
                                stylist_id: this.profile.id,
                                client_id: this.client_id,
                                agree: 1,
                            });

                            this.saveAppointment();
                        }
                    });

                //Save the card in Square
            } else if (this.profile.accept_payments && this.profile.accept_square) {
                let self = this;

                this.square_card_obj.then((card) => {
                    try {
                        tokenize(card).then((nonce) => {
                            this.cards_square_errors = true;
                            if (!nonce) {
                                return;
                            }

                            this.cards_square_errors = false;

                            var data = {
                                card_nonce: nonce,
                                stylist_id: this.profile.id,
                                client_id: this.client_id,
                            };

                            this.loading = true;

                            this.$http.post(api.actions.save_square_customer_card, data).then((response) => {
                                this.loading = false;

                                if (response.error == true) {
                                    return;
                                }

                                if (response.data.error == true) {
                                    return;
                                }

                                this.has_card = true;
                                this.$http.post(api.actions.save_stylist_policy_agreement, {
                                    stylist_id: this.profile.id,
                                    client_id: this.user.id,
                                    agree: 1,
                                });
                                this.saveAppointment();
                            });
                        });
                    } catch (e) {
                        self.cards_errors = e.message;
                    }
                });
            }
        },
        savePaymentDetails() {
            var data = {
                appointment_id: this.last_appointment_id,
                amount: this.deposit_fee,
                type: "deposit_fee",
                order_id: this.deposit_fee_order_id,
            };
            this.loading = true;

            this.$http.post(api.actions.save_payment_details, data).then(() => {
                this.loading = false;
                this.appointment_saved = true;
            });
        },
        handleSaveAppointment() {
            this.deposit_fee = parseFloat(this.deposit_fee);

            if (this.cards_square_errors) {
                this.scrollToTop();
            }

            if (this.has_card == false) {
                this.saveCard();
                return;
            }

            if (!this.deposit_fee) {
                this.saveAppointment();
                return;
            }

            let text =
                "Your Card with be charged " +
                (this.currency == "USD" || this.currency == "usd" ? "$" : this.currency) +
                this.deposit_fee +
                ". Continue?";

            let customClassApp = "rms-app";
            document.querySelector("body").classList.add("rms-app");

            if (this.generalOptions.app_id === 2) {
                customClassApp = "rmb-app";
                document.querySelector("body").classList.add("rmb-app");
            } else if (this.generalOptions.app_id === 5) {
                customClassApp = "rml-app";
                document.querySelector("body").classList.add("rml-app");
            }
            this.$swal({
                title: "Deposit Required",
                text: text,
                showCancelButton: true,
                customClass: customClassApp,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).then((result) => {
                if (result.value) {
                    $("#payment-methods-modal").modal("show");
                }
            });
        },
        ///------------------------
        handleRescheduleAppointment() {
            this.reschedule_fee = parseFloat(this.clientReschedulePolicyFee());

            if (this.has_card == false) {
                this.saveCard();
                return;
            }

            if (!this.reschedule_fee) {
                this.saveAppointment();
                return;
            }

            let text =
                "Your Card with be charged " +
                (this.currency == "USD" || this.currency == "usd" ? "$" : this.currency) +
                this.reschedule_fee +
                ". Continue?";

            let customClassApp = "rms-app";
            document.querySelector("body").classList.add("rms-app");

            if (this.generalOptions.app_id === 2) {
                customClassApp = "rmb-app";
                document.querySelector("body").classList.add("rmb-app");
            } else if (this.generalOptions.app_id === 5) {
                customClassApp = "rml-app";
                document.querySelector("body").classList.add("rml-app");
            }
            this.$swal({
                title: "Reschedule Required",
                text: text,
                showCancelButton: true,
                customClass: customClassApp,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).then((result) => {
                if (result.value) {
                    $("#payment-methods-modal").modal("show");
                }
            });
        },
        //*-------------------------------
        saveAppointment: function () {
            let formattedDate = moment(this.bookData.date).format("YYYY-MM-DD");
            let data = {
                id: this.bookData.id,
                user_id: this.bookData.stylist_id,
                client_id: this.user.id,
                date_time: formattedDate,
                hour: this.bookData.hour,
                services: this.bookData.services,
                notes: this.bookData.notes,
                special_event: this.bookData.special_event,
                reminders: this.bookData.reminders.join(","),
                status: "unconfirmed",
                check_time_limit: 1,
                card_payment_processor: this.card_payment_processor,
                card_id: this.selected_card,
                new_services: this.getNewServices(),
            };

            if (this.deposit_fee > 0 && !this.bookData.id) {
                data["deposit_fee"] = this.deposit_fee;
            }

            if (this.reschedule_fee > 0 && this.bookData.id > 0) {
                data["reschedule_fee"] = this.reschedule_fee;
            }
            if (this.$refs.signature) {
                this.signature_img = this.$refs.signature.save();
            }

            this.loading = true;

            this.$http.post(api.actions.save_appointment, data).then(
                function (response) {
                    if (response.data.error == false) {
                        this.last_appointment_id = response.data.appointment_id;
                        this.appointment_saved = true;
                        this.loading = false;
                        this.saveSignature();
                        this.resetData();

                        messages.successMessage(messages.GLOBAL_MESSAGES.APPOINTMENT_SAVED);

                        $("#payment-methods-modal").modal("hide");
                        this.$router.push("/app");
                    } else {
                        $("#payment-methods-modal").modal("hide");
                        this.loading = false;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        checkUserLink: function () {
            if (!auth.user || !this.selectedStylist) {
                return;
            }
            this.$http.get(api.actions.check_user_link + "/" + this.selectedStylist.user.id).then(
                function (response) {
                    if (response.data.linked !== true) {
                        this.$http.post(api.actions.send_invite, {
                            phone: this.selectedStylist.user.phone_prefix + this.selectedStylist.user.phone,
                        });
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getProductImage(image_url, user_id) {
            return api.API_ENDPOINT + "/uploads/images/services/" + user_id + "/" + image_url;
        },
        getTotalServicesCount() {
            // if (this.bookData.services.length == 0) {
            //     return 0;
            // }

            this.total_service_count = 0;
            let allServices = [];

            for (let category in this.services) {
                allServices = _.concat(allServices, this.services[category]);
            }

            this.selectedServicesDuration = 0;
            this.selectedServicesPrice = 0;

            allServices.forEach((service) => {
                if (this.bookData.services.indexOf(parseInt(service.id)) !== -1) {
                    this.total_service_count += service.qty ? service.qty : 1;
                    this.selectedServicesDuration += service.duration * (service.qty ? service.qty : 1);
                    this.selectedServicesPrice += service.price * (service.qty ? service.qty : 1);
                }
            });
        },
        getNewServices() {
            let response = {};
            let allServices = [];

            for (let category in this.services) {
                allServices = _.concat(allServices, this.services[category]);
            }

            allServices.forEach((service) => {
                if (this.bookData.services.indexOf(parseInt(service.id)) !== -1) {
                    response[service.id] = {
                        quantity: service.qty,
                        duration: service.duration * service.qty,
                        price: service.price * service.qty,
                    };
                }
            });

            return response;
        },
        resetData: function () {
            this.bookData = {
                id: 0,
                stylist_id: 0,
                services: [],
                date: "",
                hour: "",
                special_event: false,
                notes: "",
                reminders: [],
            };
            this.previewData = {
                stylistName: "",
                services: [],
            };
            this.currentSection = 1;
            this.freeHours = [];
        },
        scrollToTop() {
            const duration = 800; // Scroll duration in milliseconds
            const start = window.scrollY;
            const startTime = "now" in window.performance ? performance.now() : new Date().getTime();

            const scrollToTop = (timestamp) => {
                const elapsed = timestamp - startTime;
                const progress = Math.min(elapsed / duration, 1);
                const easeInOutCubic = (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);
                window.scrollTo(0, start + (0 - start) * easeInOutCubic(progress));

                if (elapsed < duration) {
                    requestAnimationFrame(scrollToTop);
                }
            };

            requestAnimationFrame(scrollToTop);
        },
        isUsCountry() {
            if (this.country_id == process.env.VUE_APP_US_COUNTRY_ID) {
                return true;
            }
            return false;
        },
        clientReschedulePolicyFee() {
            let policy = this.profile.policy;
            let policy_fee = 0;

            if (this.bookData.id != 0 && policy.reschedule == 1 && policy.reschedule_fee > 0) {
                let appt_date_time = moment(this.bookData.original_date).unix();

                let current_date_time = moment().unix();
                let hours_diff = (appt_date_time - current_date_time) / 3600;

                if (hours_diff <= policy.reschedule_advance_hours && this.selectedServicesPrice > 0) {
                    this.bookData.reschedule_fee = this.selectedServicesPrice * (policy.reschedule_fee / 100);
                    policy_fee = this.bookData.reschedule_fee;
                }
                policy_fee = policy_fee.toFixed(2);
            }

            return policy_fee;
        },
        saveSignature() {
            let data = {
                appointment_id: this.last_appointment_id,
                user_id: this.user.id,
                signature: this.signature_img,
                dataImage: 1,
            };

            if (this.deposit_fee > 0 && !this.bookData.id) {
                data["type"] = "deposit_fee";
            }

            if (this.reschedule_fee > 0 && this.bookData.id > 0) {
                data["type"] = "reschedule_fee";
            }

            this.loading = false;

            this.$http.post(api.actions.save_signature, data).then((response) => {
                if (response.body.error == true) {
                    return;
                }
            });
        },
        clearSignature() {
            var _this = this;
            _this.$refs.signature.clear();
            this.signatureIsEmpty = true;
        },
        undoSignature() {
            var _this = this;
            _this.$refs.signature.undo();
        },
        goBack(section, event) {
            this.chooseSection(section, event);
            this.scrollToTop();
            this.square_form_displayed = false;
        },
    },
    components: {
        Datepicker,
        TextToggle,
        vueSignature,
    },
};
</script>
